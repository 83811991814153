import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// Mui
import Container from '@mui/material/Container';
//
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import Page from '@/components/Page';
import HeaderTitle from '@/components/Custom/HeaderTitle';
import WidgetIndex from '@/components/Custom/widgets';
import { Grid } from '@mui/material';
import Termscontent from './termscontent';

const leftSideWidgets = [{comp: 'Contact', desc: `Kindly review these terms of service carefully before utilizing our website, which is operated by us,`}, {comp: 'Support'},];
const Terms = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <Page title={`Terms & Conditions`} favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      <Container maxWidth={'lg'} style={{paddingLeft:'0px', paddingRight:'0px'}}>
      <HeaderTitle title="Terms and Conditions" />
        <Grid container spacing={3} style={{}}>
          <Grid item style={{ width: "366px" }}>
            <WidgetIndex componentsList={leftSideWidgets} />
          </Grid>
          <Grid item xs style={{position:'relative'}}>
           <Termscontent/>
          </Grid>
        </Grid>
        
      </Container>
    </Page>
  );
};

export default Terms;
