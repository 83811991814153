import { FormGroup, Button } from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useState } from 'react';
import { StyledWrap, StyledButtonContainer, StyledSteps, StyledKycverification } from './style';
import { Grid } from '@mui/material';

interface ChildProps {
  parentModalHandlerFunction: any;
  message: string;
  closePopup(arg0: string): void;
}

const KYCProgress = (props: ChildProps) => {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<AccountCForm>();

  const onSubmit: SubmitHandler<AccountCForm> = data => {
    console.log(loading, data, errors);
    setLoading(true);
  };

  return (
    <StyledWrap>
      <form action='#' onSubmit={handleSubmit(onSubmit)} noValidate>
        <StyledSteps>
          <img
            src={process.env.REACT_APP_THEME_LOGO_MOBILE}
            alt=''
          />
          <h4>KYC Verification in Progress!</h4>
        </StyledSteps>
        <Grid sx={{ mb: 2 }}>
            <StyledKycverification>
            
              <h5>{props.message}</h5>
              {props.message === '' && (<h5>
                Thank you for submitting. KYC verification typically takes 24-48
                hours. we&apos;ll notify you once it&apos;s complete.
              </h5>)}
              {props.message !== '' && (<h5>
               Note: KYC verification typically takes 24-48
                hours, we&apos;ll notify you once it&apos;s complete.
              </h5>)}
      
            </StyledKycverification>
          
        </Grid>
        <Grid sx={{ m: 0 }}>
          <StyledButtonContainer>
            <Button
              type='button'
              color='brand2'
              className='signupbtn'
              onClick={() => props.closePopup('close')}
            >
              Continue Gaming
            </Button>
          </StyledButtonContainer>
        </Grid>
      </form>
    </StyledWrap>
  );
};

export default KYCProgress;
