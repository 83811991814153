/* eslint-disable no-useless-escape */
import {
  FormGroup,
  Label,
  Input,
  Button,
  Select,
  InputGroup,
  InputGroupAddon,
  Checkbox,
  ButtonSpinner,
  InputGroupText,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { hasKey } from '@/shared/methods';
import { Link } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
// import {
//   StyledWrap,
//   StyledTitle,
//   StyledDesc,
//   StyledBottomText,
//   StyledPhoneText,
//   // StyledSteps,
//   StyledPersonalContainer,
//   StyledCheckedText,
//   StyledContryCode,
//   StyledMobileCode,
//   StyledMobileView,
//   StyledInfoCircle,
//   // StyledGcashText,
//   StyledAlertMessage,
// } from '@/pages/auth/style';

import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledBottomText,
  StyledPhoneText,
  // StyledSteps,
  StyledPersonalContainer,
  StyledCheckedText,
  StyledContryCode,
  StyledMobileCode,
  StyledMobileView,
  StyledInfoCircle,
  // StyledGcashText,
  StyledAlertMessage,
} from './style';
import {
  EyeIconSvg,
  EyeHideIconSvg,
  StarIconSvg,
  InfocircleIconSvg,
  AlertWarningiconSvg,
  ErrorWarningiconSvg,
  MobileSvg,
  PasswordSvg,
} from '@/shared/svg';
import { useDispatch } from 'react-redux';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import type { Value } from 'react-multi-date-picker';
import moment from 'moment/moment';
import { updateSignupData } from '@/redux/slices/register';
import { useAppSelector } from '@/hooks/useAppSelector';
import AuthService from '@/services/AuthService';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { backgroundColor } from 'styled-system';
import TextImage from '@/pages/contact/TextImage';
import ReCAPTCHA from 'react-google-recaptcha';
import Stack from '@mui/material/Stack';
interface ChildProps {
  changeSteps: any;
}

interface IDatePickerProps {
  onFocus: any;
  value: any;
  onChange: any;
}

function CustomInput(props: IDatePickerProps) {
  return (
    <input
      onFocus={props.onFocus}
      value={props.value}
      onChange={props.onChange}
      readOnly
      className={`dob_btn`}
      onKeyDown={e => e.preventDefault}
    />
  );
}
const generateRandomCaptcha = () => {
  return Math.floor(1000 + Math.random() * 9000).toString(); // Generates a number between 1000 and 9999
};
const AccountForm = (props: ChildProps) => {
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState('password');
  const [cpasswordType, setCpasswordType] = useState('password');
  const [passwordHint, setPasswordHint] = useState(false);
  const [dobError, setDOBError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [maxDate, setMaxDate] = useState('');
  const [yearVal, setYearVal] = useState(0);
  const [monthVal, setMonthVal] = useState(0);
  const [dayVal, setDayVal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dob, setDob] = useState<Value>('');
  const { regData } = useAppSelector(state => state.register);
  const datePickerRef = useRef<any>();
  const [startDate, setStartDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [isSignUpError, setIsSignUpError] = useState(false);

  const [captchaMessage, setCaptchaMessage] = useState('');
  const [isCaptchaDone, setIsCaptchaDone] = useState(false);
  // Set the initial captchaText state to a random 4-digit number

  const [userCaptcha, setUserCaptcha] = useState('');
  const recaptchaRef = useRef(null);

  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors, submitCount },
  } = useForm<IUser>();

  const getMaxDate = () => {
    setValue('countryCode', '+63');
    const start = moment().add(-21, 'y').add(-1, 'd');
    const maxDate1 = moment(start).format('YYYY/MM/DD');
    const maxDate2 = new Date(maxDate1);

    const syear = moment(start).format('YYYY');
    const smonth = moment(start).format('MM');
    const sday = moment(start).format('DD');
    setYearVal(parseInt(syear));
    setMonthVal(parseInt(smonth));
    setDayVal(parseInt(sday));
    setMaxDate(maxDate1);

    setStartDate(maxDate2);
  };

  const onSubmit: SubmitHandler<IUser> = async data => {
    // debugger;
    setIsSignUpError(false);
    setDOBError(false);
    setAgeError(false);
    const dobValue: any = dob ? dob : startDate;

    if (dob == null || dob == '') setDob(startDate);

    const dateFormat = 'YYYY/MM/DD';
    const toDateFormat = moment(new Date(dobValue)).format(dateFormat);
    const isValid = moment(toDateFormat, dateFormat, true).isValid();
    if (!isValid) {
      return false;
    }

    //age check
    const startDate1: any = new Date();
    const age = Math.abs(moment.duration(dobValue - startDate1).years());
    if (age < 21) {
      setAgeError(true);
      return false;
    }
    // setCaptchaMessage('');
    // if (!isCaptchaDone) {
    //   setCaptchaMessage('Please verify captcha');
    //   return;
    // }
    setLoading(true);
    const otpObj = { phone: `${data.countryCode}${data.phone}` };
    const userInfo = { ...otpObj };
    const otpInfo = await AuthService.getOTP(userInfo);
    // recaptchaRef.current.reset();
    // setCaptchaMessage('');
    // setUserCaptcha('');
    // setIsCaptchaDone(false);

    if (otpInfo.status) {
      setLoading(false);

      data.token = otpInfo.data.token;
      const currentDate = new Date(dobValue.toString());
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getUTCHours()).padStart(2, '0');
      const minutes = String(currentDate.getUTCMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getUTCSeconds()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      data.dob = new Date(formattedDate);
      console.log(data, 'dataa');
      dispatch(updateSignupData(data));
      props.changeSteps('signupotp');
    } else {
      setLoading(false);
      setIsSignUpError(true);
    }
  };

  const showPassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };

  const showCPassword = () => {
    if (cpasswordType === 'password') {
      setCpasswordType('text');
      return;
    }
    setCpasswordType('password');
  };

  const handleChange = (value: any) => {
    setAgeError(false);
    setDOBError(false);
    setDob(value);
    //setStartDate(value);
    setSelectedStartDate(value);
    const dobValue: any = value;

    if (dob == null || dob == '') setDob(value);

    const dateFormat = 'YYYY/MM/DD';
    const toDateFormat = moment(new Date(dobValue)).format(dateFormat);
    const isValid = moment(toDateFormat, dateFormat, true).isValid();
    if (!isValid) {
      return false;
    }

    //age check
    const startDate1: any = new Date();
    const age = Math.abs(moment.duration(dobValue - startDate1).years());
    if (age < 21) {
      setAgeError(true);
      return false;
    } else {
      setAgeError(false);
      return;
    }
  };

  const openInfoPopup = async () => {
    props.changeSteps('login');
  };

  useEffect(() => {
    getMaxDate();
    //handleChange(startDate);
    console.log(regData);
    if (regData) {
      setValue('phone', regData.phone);
      if (regData.countryCode === undefined) setValue('countryCode', '+63');
      else setValue('countryCode', regData.countryCode);

      //setValue("countryCode", regData.countryCode);
      setValue('ageConsent', regData.ageConsent);
      setValue('govtOfficialConsent', regData.govtOfficialConsent);
      setValue('armedForceConsent', regData.armedForceConsent);
    }
  }, [regData]);

  // Function to handle the generated CAPTCHA image
  const handleCaptcha = (token: string) => {
    setIsCaptchaDone(true);
    setUserCaptcha(token);
    setCaptchaMessage('');
  };
  return (
    <StyledWrap className='signinview'>
      <StyledTitle>Sign Up</StyledTitle>
      {isSignUpError ? (
        <StyledAlertMessage>
          <ErrorWarningiconSvg /> The Phone Number has been already registered.
          Please login or reset password.
        </StyledAlertMessage>
      ) : null}

      {/* <StyledSteps>
        <p>
          <StarIconSvg />
          Fields are mandatory
        </p>
      </StyledSteps>
      <StyledGcashText>
        <p>
          - Please use your Gcash registered phone number to register to
          {process.env.REACT_APP_SITE_TITLE}
        </p>
      </StyledGcashText> */}
      <form
        action='#'
        className='loginform regesterform'
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }}>
            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
              <Label display='block' mb='5px' htmlFor='email'>
                Phone Number<span>*</span>
              </Label>
              <StyledMobileView>
                <StyledContryCode>
                  <InputGroup>
                    <Select
                      id='countryCode'
                      name='countryCode'
                      className='select'
                      {...register('countryCode')}
                    >
                      <option value='+63'>PH(+63)</option>
                      <option value='+91'>IN(+91)</option>
                      <option value='+44'>UK(+44)</option>
                    </Select>
                  </InputGroup>
                </StyledContryCode>
                <StyledMobileCode>
                  <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <MobileSvg />
                    </InputGroupText>
                  </InputGroupAddon>
                  <StyledPhoneText>(0)</StyledPhoneText>
                  <Input
                    className='mobilenumber'
                    type='tel'
                    id='phone'
                    name='phone'
                    placeholder='Phone number'
                    feedbackText={errors?.phone?.message}
                    state={hasKey(errors, 'phone') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'phone')}
                    {...register('phone', {
                      required: 'Phone is required',
                      pattern: {
                        value: /^(?!(0))[0-9]{10}$/,
                        message: 'invalid phone format',
                      },
                    })}
                  />
                </StyledMobileCode>
              </StyledMobileView>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sx={{ marginBottom: '25px' }}>
              <Label display='block' mb='5px' htmlFor='email'>
                Password<span>*</span>{' '}
                <p className='infopassword'>
                  <Link
                    to={''}
                    onMouseOver={() => setPasswordHint(true)}
                    onMouseLeave={() => setPasswordHint(false)}
                  >
                    <InfocircleIconSvg />
                    {passwordHint && (
                      <StyledInfoCircle>
                        Password should be at least 8 characters long and must
                        <br />
                        contain atleast 1 uppercase letter, 1 lowercase letter,
                        <br />1 special character character (%$@!*&#) and 1
                        number
                      </StyledInfoCircle>
                    )}
                  </Link>
                </p>
              </Label>
              <InputGroup>
                <InputGroupAddon dir='prepend'>
                  <InputGroupText>
                    <PasswordSvg />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id='password'
                  type={passwordType}
                  name='password'
                  placeholder='Enter your password'
                  feedbackText={errors?.password?.message}
                  state={hasKey(errors, 'password') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'password')}
                  {...register('password', {
                    required: 'Password is required',
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                      message:
                        'Use 8 or more characters-atleast 1 uppercase and 1 lowercase letters, 1 number & 1 symbol(@$!%*?&#)',
                    },
                  })}
                  onFocus={() => setPasswordHint(true)}
                  onBlur={() => setPasswordHint(false)}
                />
                <InputGroupAddon className='posswordicon'>
                  <div
                    className='passwodicon'
                    color='light'
                    onClick={() => showPassword()}
                  >
                    {passwordType === 'password' && <EyeHideIconSvg />}
                    {passwordType === 'text' && <EyeIconSvg />}
                  </div>
                </InputGroupAddon>
              </InputGroup>
            </Grid>
          </Grid>
          <Grid item>
            <Label display='block' mb='5px' htmlFor='email'>
              Confirm Password<span>*</span>
            </Label>
            <InputGroup>
              <InputGroupAddon dir='prepend'>
                <InputGroupText>
                  <PasswordSvg />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                id='confirm_pass'
                type={cpasswordType}
                name='confirm_pass'
                placeholder='Re-enter password'
                feedbackText={errors?.confirm_pass?.message}
                state={hasKey(errors, 'confirm_pass') ? 'error' : 'success'}
                showState={!!hasKey(errors, 'confirm_pass')}
                {...register('confirm_pass', {
                  required: 'Confirm Password is required',
                  validate: {
                    matchesPreviousPassword: value => {
                      const { password } = getValues();
                      return password === value || 'Passwords should match!';
                    },
                  },
                })}
              />
              <InputGroupAddon className='posswordicon'>
                <div
                  className='passwodicon'
                  color='light'
                  onClick={() => showCPassword()}
                >
                  {cpasswordType === 'password' && <EyeHideIconSvg />}
                  {cpasswordType === 'text' && <EyeIconSvg />}
                </div>
              </InputGroupAddon>
            </InputGroup>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: '20px', marginBottom: '20px' }}>
            {/* <ReCAPTCHA
              sitekey='6Lc_CWQqAAAAAHbsSmSBA9j-8p09voH1rLhXaxDl'
              ref={recaptchaRef}
              onChange={handleCaptcha}
            />
            <Stack
              sx={{
                // position: 'absolute',
                bottom: '-5px',
                color: '#ff7c54',
                fontSize: '11px',
              }}
            >
              {captchaMessage}
            </Stack> */}
          </Grid>
        </Grid>

        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='flex-start'
          // className=' mar_top'
        >
          <Grid item>
            <StyledCheckedText>
              <Checkbox
                id='ageConsent'
                name='ageConsent'
                label='I declare that I am at least 21 years old  '
                {...register('ageConsent', {
                  required: 'Required',
                })}
                feedbackText={errors?.ageConsent?.message}
                state={hasKey(errors, 'ageConsent') ? 'error' : 'success'}
                showState={!!hasKey(errors, 'ageConsent')}
              />
            </StyledCheckedText>
          </Grid>
        </Grid>
        {/* <Row className='margin_bottom '>
          <Col md={12}>
            <StyledCheckedText>
              <Checkbox
                id='govtOfficialConsent'
                name='govtOfficialConsent'
                label=' I am not a government official connected directly with the operations of the Government or any of its agencies'
                {...register('govtOfficialConsent', {
                  required: 'Required',
                })}
                feedbackText={errors?.govtOfficialConsent?.message}
                state={
                  hasKey(errors, 'govtOfficialConsent') ? 'error' : 'success'
                }
                showState={!!hasKey(errors, 'govtOfficialConsent')}
              />
            </StyledCheckedText>
          </Col>
        </Row> */}
        {/* <Row className='margin_bottom'>
          <Col md={12}>
            <StyledCheckedText>
              <Checkbox
                id='armedForceConsent'
                name='armedForceConsent'
                label=' I am not a member of the Armed Forces of the Philippines, including the Army, Navy, Air Force or the Philippine National Police  '
                {...register('armedForceConsent', {
                  required: 'Required',
                })}
                feedbackText={errors?.armedForceConsent?.message}
                state={
                  hasKey(errors, 'armedForceConsent') ? 'error' : 'success'
                }
                showState={!!hasKey(errors, 'armedForceConsent')}
              />
            </StyledCheckedText>
          </Col>

          {(errors.ageConsent ||
            errors.govtOfficialConsent ||
            errors.armedForceConsent) && (
            <Col md={12} className='labeltext'>
              <div className='labelerror'>
                <p>Please complete all three declarations to proceed.</p>
              </div>
            </Col>
          )}
        </Row> */}
        <StyledPersonalContainer>
          <Button type='submit' color='brand2' className='signin_btn'>
            {loading ? <ButtonSpinner /> : null}Create Account
          </Button>
        </StyledPersonalContainer>

        <StyledBottomText>
          Already have an account? &nbsp;
          <Link className='flex' to={''} onClick={() => openInfoPopup()}>
            Log In
          </Link>
        </StyledBottomText>
      </form>
    </StyledWrap>
  );
};

export default AccountForm;
