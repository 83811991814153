import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import GridBox from "./components/GridBox";
import RacingService from '@/services/RacingService';
import Pricing from "./components/Pricing";
import Loading from "@/components/Loading";
import RaceHeader from "./components/RaceHeader";
import RaceVideo from "../betting/video";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Alert, AlertTitle, Snackbar, Button } from "@mui/material";
import Sockets from '@/services/SocketService';
import { updateModelAttributes } from '@/services/SocketModelUpdate';

const Index = () => {
    //const [originalCard, setOriginalCard] = useState(null);
    const [raceEventList, SetRaceEventList] = useState(null);
    const [dividentsList, setDividentsList] = useState(null);
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState(100);
    const [response, setResponse] = useState(null);
    const [betType, setBetType] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(null);
    const [race, setRace] = useState(null);

    const onChangeRace = async (selectedItem: any) => {
        setSelectedVenue({ id: selectedItem.id, name: selectedItem.name, code: selectedItem.code });
        await getVenueDividendList(selectedItem.id);
        setRace(selectedItem.event);
    };

    const onChangePrice = (val: number) => {
        setSelectedPrice(val);
    };

    //Displaying list of lables
    const listChanges = (type: string, betValue: string) => {
        const val = [];
        let betVal = selectedPrice * parseFloat(betValue);
        betVal = Math.round(betVal);
        if (betVal <= 1) {
            betVal = selectedPrice;
        }
        if (type === 'odd') {
            val.push({ "id": 1, "label": "Bet", value: selectedPrice });
            val.push({ "id": 2, "label": "Payment", value: betVal });
        } else {
            val.push({ "id": 3, "label": "Bet", value: selectedPrice });
            val.push({ "id": 4, "label": "Payment", value: betVal });
        }
        return val;
    };

    //fetching Race events list 
    const getRaceEventList = async () => {
        const response = await RacingService.getRaceEventList();
        let racesOrder: any = [];
        response.data.forEach((items: any) => {
            items.events.forEach((item: any) => {
                racesOrder.push({ id: items.id, name: items.venueName, code: items.venueCode, event: item })
            });
        });
        const sortEvents = racesOrder.sort((x: any, y: any) => {
            return x.event.mtp - y.event.mtp;
        });
        SetRaceEventList(sortEvents);
        //setOriginalCard(response);
    };

    //fetching dividends list
    const getVenueDividendList = async (venueId: string) => {
        const response = await RacingService.getVenueDividendList(venueId);
        const obj: any = {};
        if (response?.data?.body?.details) {
            response?.data?.body?.details.forEach((items: any) => {
                items.runners.forEach((item: any) => {
                    if (item.position === "1" || item.position === "2") {
                        if (!obj[items.id]) {
                            obj[items.id] = {};
                        }
                        obj[items.id][item.position] = item.odds.WIN;
                    }
                });
            });
        }
        let copyDividentsList = dividentsList;
        if (!dividentsList) {
            copyDividentsList = {};
        }
        copyDividentsList[venueId] = obj;
        setDividentsList(copyDividentsList);
    };

    //BetTypeSelect
    const onBetTypeSelect = (type: string) => {
        setBetType(type === "odd" ? "1" : "2");
        if (race.status !== "BST") {
            setResponse({ status: "FAIL", msg: "Betting closed for selected event" });
            return;
        }
        if (selectedPrice <= 0) {
            setResponse({ status: "FAIL", msg: "Amount should not be negative" });
            return;
        }
        if (selectedPrice % 100 != 0) {
            setResponse({ status: "FAIL", msg: "Amount should be multiples of 100" });
            return;
        }
        openDialog();
    };

    //Submitting Bet
    const onSubmitBet = async () => {
        let order = {
            entryType: "sale",
            betAmount: selectedPrice,
            unitBet: 100,
            totalAmount: selectedPrice,
            action: "submit",
            runners: betType,
            runnersDisplay: betType,
            betQuantity: 1,
            programId: selectedVenue.id,
            poolName: "win",
            eventId: race.raceNumber,
            venueCode: selectedVenue.code,
            groupCode: "",
            betType: "tote"
        };
        let result = await RacingService.submitOrder(order);
        setResponse({ status: result.data.status, msg: (result.data.msg ? result.data.msg : result.data.message) });
    };

    const openDialog = () => {
        setDialogOpen(true);
    };

    const confirmBetting = () => {
        onSubmitBet();
        closeDialog();
    }

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const handleClose = () => {
        setResponse(null);
    }

    useEffect(() => {
        getRaceEventList();
    }, []);

    useEffect(() => {
        if (selectedVenue) {
            Sockets.setSocketForUser(selectedVenue.id, updateModelAttributes);
        }
    }, [selectedVenue])

    return (
        <>
            <div className="racemenulist">
                {raceEventList &&
                    (
                        <Grid item xs={12}>
                            <RaceHeader raceEventList={raceEventList} onChangeRace={onChangeRace} />
                        </Grid>
                    )
                }
            </div>
            <div className="racevideos">
                {selectedVenue && <RaceVideo venueId={selectedVenue.id} />}
            </div>

            <div className="gamegrideview">
                {!raceEventList && <Loading />}

                <Grid container spacing={2}>

                    {/* <Grid item xs={12}>
                        {selectedVenue && <RaceVideo venueId={selectedVenue.id} />}
                    </Grid> */}
                    <Grid style={{ margin: '0px', paddingLeft: '0px' }} className="paicingview" container item xs={12}>
                        <Pricing onChangePrice={onChangePrice} />
                    </Grid>
                    {race && (<Grid style={{ margin: '0px', marginLeft: '8px' }} container spacing={1}>
                        <Grid item xs={4}>
                            {dividentsList && dividentsList[selectedVenue.id] !== undefined && dividentsList[selectedVenue.id][race.id] !== undefined && <GridBox item="odd" values={{ value: dividentsList[selectedVenue.id][race.id][1], list: listChanges('odd', dividentsList[selectedVenue.id][race.id][1]) }} submitBet={onBetTypeSelect} />}
                        </Grid>
                        <Grid item xs={4}>
                            <GridBox item="open" venue={selectedVenue} values={race} />
                        </Grid>
                        <Grid item xs={4}>
                            {dividentsList && dividentsList[selectedVenue.id] !== undefined && dividentsList[selectedVenue.id][race.id] !== undefined && <GridBox item="even" values={{ value: dividentsList[selectedVenue.id][race.id][2], list: listChanges('even', dividentsList[selectedVenue.id][race.id][2]) }} submitBet={onBetTypeSelect} />}
                        </Grid>
                    </Grid>)}
                </Grid>


                <Dialog
                    open={dialogOpen}
                    onClose={closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="confirmbetview"
                >
                    <DialogTitle id="alert-dialog-title">
                        Confirm Bet
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {selectedVenue?.name}<br />
                            Event-{race?.raceNumber} - {race?.raceTime}<br />
                            {betType === "1" ? "ODD - X" : "EVEN - O"}<br />
                            Bet Amount : {selectedPrice} PHP
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="confirmbutton">
                        <Button className="confirmcanclebtn" onClick={closeDialog}>Cancel</Button>
                        <Button className="confirmbtn" onClick={confirmBetting} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Snackbar open={!!response} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={handleClose}>
                {response && <Alert onClose={handleClose} variant="filled" severity={response?.status === "SUCCESS" ? "success" : "error"} sx={{ width: '100%' }}>
                    {response?.msg}
                </Alert>}
            </Snackbar>
        </>
    )
}

export default Index;