import { QuestionSvg, ArrowRightSvg, HeadPhoneSvg, MessageSvg } from "@/shared/svg";
import React from "react";
import Contact from "./Contact";
import Support from "./Support";
import Chat from "./Chat";
import LearnMore from "./Learnmore";

const WidgetIndex: React.FC<any> = ({componentsList}: {componentsList: any}) => {
    return (
        <>
            {componentsList.map((item: any, ind: number) => (
                <div key={ind}>
                {item.comp === 'Contact' && <Contact message={item.desc}/> }
                {item.comp === 'Support' && <Support /> }
                {item.comp === 'Chat' && <Chat /> }
                {item.comp === 'Learnmore' && <LearnMore /> }
                
                </div>
            ))}
        </>
    );
};

export default WidgetIndex;
