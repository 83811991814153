import { Theme } from '@mui/material/styles';
// Override Base CSS
import CssBaseline from './CssBaseline';
// Override MUI
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import Divider from './Divider';
import Input from './Input';
import Accordion from './Accordion';
// import Link from './Link';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    CssBaseline(theme),
    //
    Accordion(theme),
    Button(theme),
    ButtonGroup(theme),
    Divider(theme),
    Input(theme),
    // Link(theme),
  );
}
