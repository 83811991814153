import React from 'react';
import { Link } from 'react-router-dom';
// Mui
import { StyledPageRight, StyledPageTextContainer, StyledPageTextView, StyledSupportAvailable, StyledSupportContent, StyledSupportInfo, } from "../../components/Custom/mainstyle"
import PageBanner from '@/components/Custom/PageBanner';
import { HeadPhoneSvg } from '@/shared/svg';
//



const Aboutcontent = () => {
  return (

    <StyledPageRight>
      <StyledPageTextContainer>
        <PageBanner />
        <StyledPageTextView>
          <h5>Who We Are</h5>
          <h5 className='weare_passio'>
            We are a passionate team of computer nerds, sports
            enthusiasts, statisticians, bookies and digital wizards who
            have combined forces to create applications that treat punters
            like the legends they are.
          </h5>
          <p>
            Immerse yourself in the new world of personalised play with a
            host of entertaining services in the palm of your hand. Our
            team have created a robust platform that s fast, safe and
            secure. The slick interface means more game-play and less
            horsing around. It all starts with a superior online betting
            experience. {process.env.REACT_APP_SITE_TITLE} features exciting sports action, the
            best horse racing from around the globe, innovative products
            and thrilling promotions.
          </p>
          <p>
            The key to {process.env.REACT_APP_SITE_TITLE} success is the importance placed on
            quality customer service - Players are never more than a click
            or call away. {process.env.REACT_APP_SITE_TITLE} makes it easy to request information,
            services and support 24/7
          </p>

          <StyledSupportContent>
            <StyledSupportInfo>
              <span>
                <HeadPhoneSvg />
                <small>Customer Support</small>
              </span>
              <strong><Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link></strong>
            </StyledSupportInfo>
            <StyledSupportAvailable>

              <span>Available: 24x7</span>
            </StyledSupportAvailable>
          </StyledSupportContent>
        </StyledPageTextView>
      </StyledPageTextContainer>
    </StyledPageRight>

  );

};

export default Aboutcontent;

