import React from "react";
import styled, { themeGet } from '@/shared/styled';
import { FlagIconSvg, AccountEnquiriesSvg, FaqDepositIconSvg, SportsBetsEnquiriesSvg, PunterAssistSvg, TrustSafetySvg } from "@/shared/svg";
import { Grid } from "@mui/material";
import SearchBar from "../../../components/Custom/SearchBar";
import TopicItem from "../components/TopicItem";
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from "../../../components/Custom/mainstyle"

export const H3 = styled.h3`
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 20px;
`;

const listOfTopics = [
    { id: 100, title: "Getting Started", desc: "Get to know ArionPlay", icon: <FlagIconSvg />, url: '/faqs/gettingstarted' },
    { id: 101, title: "Account Enquiries", desc: "Learn about account creation, KYC verification, and password reset", icon: <AccountEnquiriesSvg />, url: '/faqs/details' },
    { id: 102, title: "Deposits, Withdrawals", desc: "Get information about deposit and withdrawal processes and parameters", icon: <FaqDepositIconSvg />, url: '/faqs/depositswithdrawals' },
    { id: 103, title: "Betting Enquiries", desc: "Learn about XO and how to place bets", icon: <SportsBetsEnquiriesSvg />, url: '/faqs/sportsbetsenquiries' },
    { id: 104, title: "Account Assistance &  Controls", desc: "Read about other account-related concerns", icon: <PunterAssistSvg />, url: '/faqs/accountassistance' },
    { id: 105, title: "Trust & Safety", desc: "Check out other security-related queries", icon: <TrustSafetySvg />, url: '/faqs/trustsafety' },
];

const TopicsIndex: React.FC<any> = ({ title }: { title: string }) => {
    return (
        <StyledInfoBoxRight>

            <SearchBar />
            <h6>TOPICS</h6>
            <Grid container spacing={{ xs: 2, md: 3, lg: 3 }}>
                {listOfTopics.map((item: any) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}  key={item.id}>
                        <TopicItem item={item} />
                    </Grid>
                ))}
            </Grid>
        </StyledInfoBoxRight>
    );
};

export default TopicsIndex;
