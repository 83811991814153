import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// Mui
import Container from '@mui/material/Container';
//
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import Page from '@/components/Page';
import HeaderTitle from '@/components/Custom/HeaderTitle';
import WidgetIndex from '@/components/Custom/widgets';
import { Grid } from '@mui/material';

import Locationcontent from './locationscontent';

const leftSideWidgets = [{comp: 'Contact', desc: `Kindly review these terms of service carefully before utilizing our website, which is operated by us,`}, {comp: 'Support'},];
const Locations = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <Page title={`Locations`} favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      <Container maxWidth={'lg'} >
      <HeaderTitle title="Locations" />
        <Grid container spacing={0} style={{}}>
        <Locationcontent/>
          {/* <Grid item >
            <WidgetIndex componentsList={leftSideWidgets} />
          </Grid> */}
          {/* <Grid item xs style={{position:'relative'}}>
           <Locationcontent/>
          </Grid> */}
        </Grid>
        
      </Container>
    </Page>
  );
};

export default Locations;
