/* eslint-disable no-useless-escape */
import {
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  ButtonSpinner,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { hasKey } from '@/shared/methods';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledSteps,
  StyledPersonalContainer,
} from './style';
import { LeftArrowIconSvg, StarIconSvg } from '@/shared/svg';
//import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/hooks/useAppSelector';
import AuthService from '@/services/AuthService';
import { backgroundColor } from 'styled-system';
interface ChildProps {
  changeSteps: any;
}

const AddressForm = (props: ChildProps) => {
  //const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { regData } = useAppSelector(state => state.register);
  const [userInfo, setUserInfo] = useState<IUser>(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IUser>();

  const onSubmit: SubmitHandler<IUser> = async data => {
    setLoading(true);
    const response = await AuthService.updateUserProfile(data);
    console.log(response);
    props.changeSteps('uploaddocuments');
  };

  useEffect(() => {
    console.log(regData);
  }, [regData]);

  const getUserInfo = async () => {
    const userData = await AuthService.getUserData();
    if (userData.status) {
      const { address1, address2, postalCode, city } = userData.data;
      setValue('city', city);
      setValue('address1', address1);
      setValue('address2', address2);
      setValue('postalCode', postalCode);
    }
  };

  const gotoAccount = () => {
    props.changeSteps('personal');
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <StyledWrap className='addressview'>
      <Link to={''} className='backarrow' onClick={() => gotoAccount()}>
        <LeftArrowIconSvg />
      </Link>
      <StyledTitle>KYC Verification</StyledTitle>
      <StyledDesc>
        Please share the following details to verify your identity
      </StyledDesc>
      <StyledSteps>
        <h4>
          <span>Step 2/3</span>&nbsp;- Add your address
        </h4>
        <p>
          <StarIconSvg />
          Fields are mandatory
        </p>
        <h6>
          Please ensure below details match those listed on your valid govt ID
        </h6>
      </StyledSteps>
      <form
        action='#'
        className='loginform'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className='scrollheight'>
          {/* <Row>
            <div className='sameaddress'>
               <div className='sameaddressvieew'>
               <h5>Current Address</h5>
                </div>
            </div>
          </Row> */}
          <Grid container spacing={{ xs: 0, md: 3 }} direction='row'>
            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Current Address<span>*</span>
                </Label>
                <InputGroup>
                  {/* <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <CitySvg />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    id='address1'
                    type='text'
                    name='address1'
                    value={userInfo?.address1}
                    placeholder='Building, House Number, Street Name'
                    feedbackText={errors?.address1?.message}
                    state={hasKey(errors, 'address1') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'address1')}
                    {...register('address1', {
                      required: 'Current Address is required',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Permanent Address<span>*</span>
                </Label>
                <InputGroup>
                  {/* <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <CitySvg />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    id='address2'
                    type='text'
                    name='address2'
                    value={userInfo?.address2}
                    placeholder='District, Barangay, Muncipality'
                    feedbackText={errors?.address2?.message}
                    state={hasKey(errors, 'address2') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'address2')}
                    {...register('address2', {
                      required: 'Permanent Address is required',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 0, md: 3 }} direction='row'>
            <Grid item xs={12} md={4} sx={{ mb: 2 }}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Postal Code<span>*</span>
                </Label>
                <InputGroup>
                  {/* <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <CitySvg />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    id='postalCode'
                    type='text'
                    name='postalCode'
                    value={userInfo?.postalCode}
                    placeholder='Enter your Postal Code'
                    feedbackText={errors?.postalCode?.message}
                    state={hasKey(errors, 'postalCode') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'postalCode')}
                    {...register('postalCode', {
                      required: 'Postal Code is required',
                      pattern: {
                        value: /^\d+$/,
                        message: 'Invalid Postal Code. Only numbers allowed',
                      },
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4} sx={{ mb: 2 }}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Province / City<span>*</span>
                </Label>
                <InputGroup>
                  {/* <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <CitySvg />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    id='city'
                    type='text'
                    name='city'
                    value={userInfo?.city}
                    placeholder='Enter your city'
                    feedbackText={errors?.city?.message}
                    state={hasKey(errors, 'city') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'city')}
                    {...register('city', {
                      required: 'City is required',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
          </Grid>
          {/* <Row>
            <div className='sameaddress'>
               <div className='sameaddressvieew'>
               <h5>Permanent Address</h5>
                <StyledCheckedText>
                    <Checkbox
                      id='ageConsent'
                      name='acceptTerms'
                      label='Same as current address  '
                    />
                  </StyledCheckedText>
                </div>
            </div>
          </Row> */}
          {/* <Row>
            <Col md={6}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Address Line 1
                </Label>
                <InputGroup>
                  <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <CitySvg />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id='address1'
                    type='text'
                    name='permanentAddress?.address1'
                    placeholder='Enter your Address Name 1'
                    feedbackText={errors?.permanentAddress?.address1?.message}
                    state={
                      hasKey(errors, 'permanentAddress.address1')
                        ? 'error'
                        : 'success'
                    }
                    showState={!!hasKey(errors, 'permanentAddress.address1')}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Address Line 1
                </Label>
                <InputGroup>
                  <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <CitySvg />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id='address2'
                    type='text'
                    name='permanentAddress?.address2'
                    placeholder='Enter your Address Name 2'
                    feedbackText={errors?.permanentAddress?.address2?.message}
                    state={
                      hasKey(errors, 'permanentAddress.address2')
                        ? 'error'
                        : 'success'
                    }
                    showState={!!hasKey(errors, 'permanentAddress.address2')}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row mb='20px'>
            <Col md={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Postal Code
                </Label>
                <InputGroup>
                  <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <CitySvg />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id='postalCode'
                    type='text'
                    name='permanentAddress?.postalCode'
                    placeholder='Enter your Postal Code'
                    feedbackText={errors?.permanentAddress?.postalCode?.message}
                    state={
                      hasKey(errors, 'permanentAddress.postalCode')
                        ? 'error'
                        : 'success'
                    }
                    showState={!!hasKey(errors, 'permanentAddress.postalCode')}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  City
                </Label>
                <InputGroup>
                  <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <CitySvg />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id='city'
                    type='text'
                    name='permanentAddress?.city'
                    placeholder='Enter your city'
                    feedbackText={errors?.permanentAddress?.city?.message}
                    state={
                      hasKey(errors, 'permanentAddress.city')
                        ? 'error'
                        : 'success'
                    }
                    showState={!!hasKey(errors, 'permanentAddress.city')}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>                    */}
        </div>
        <StyledPersonalContainer>
          <Button type='submit' color='brand2' className='signin_btn'>
            {loading ? <ButtonSpinner /> : null} Continue
          </Button>
        </StyledPersonalContainer>
      </form>
    </StyledWrap>
  );
};

export default AddressForm;
