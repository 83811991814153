import { FormGroup, Label, Input, Button, InputGroup } from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { hasKey } from '@/shared/methods';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Grid } from '@mui/material/';
import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledBottomText,
  StyledSteps,
  StyledPersonalContainer,
  StyledValidId,
} from './style';
import { InfoIconSvg } from '@/shared/svg';
import { useDispatch } from 'react-redux';
import { updateSignupData } from '@/redux/slices/register';
import { useAppSelector } from '@/hooks/useAppSelector';

interface ChildProps {
  changeSteps: any;
}

const PersonalForm = (props: ChildProps) => {
  const dispatch = useDispatch();
  const { regData } = useAppSelector(state => state.register);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUser>();

  const onSubmit: SubmitHandler<IUser> = data => {
    dispatch(updateSignupData(data));
    props.changeSteps('account');
  };

  useEffect(() => {
    console.log(regData);
  }, [regData]);

  const openInfoPopup = async () => {
    //dispatch(changeModule('loginterms'));
    props.changeSteps('login');
  };

  //useEffect(() => {}, []);

  return (
    <StyledWrap>
      <StyledTitle>Sign Up</StyledTitle>
      <StyledDesc>Create an account with us</StyledDesc>
      <StyledSteps>
        <h4>
          <span>Step 1/2</span>&nbsp;-&nbsp;Add your Personal Details
        </h4>
        <StyledValidId>
          <InfoIconSvg />
          Please ensure below details match those listed on your valid govt ID
        </StyledValidId>
      </StyledSteps>

      <form
        action='#'
        className='loginform'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className='scrollheight'>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{ paddingBottom: '5px' }}
          >
            <Grid item xs={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  First Name
                </Label>
                <InputGroup>
                  {/* <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <UserSvg />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    id='firstName'
                    type='text'
                    name='firstName'
                    placeholder='Enter your First Name'
                    feedbackText={errors?.firstName?.message}
                    state={hasKey(errors, 'firstName') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'firstName')}
                    {...register('firstName', {
                      required: 'First Name is required',
                      pattern: {
                        value: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g,
                        message: 'Invalid name format',
                      },
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
            <Grid item xs={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Middle Name
                </Label>
                <InputGroup>
                  {/* <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <UserSvg />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    id='middleName'
                    type='text'
                    name='middleName'
                    placeholder='Enter your Middle Name'
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
            <Grid item xs={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Last Name
                </Label>
                <InputGroup>
                  {/* <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <UserSvg />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    id='lastName'
                    type='text'
                    name='lastName'
                    placeholder='Enter your Last Name'
                    feedbackText={errors?.lastName?.message}
                    state={hasKey(errors, 'lastName') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'lastName')}
                    {...register('lastName', {
                      required: 'Last Name is required',
                      pattern: {
                        value: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g,
                        message: 'Invalid name format',
                      },
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{ paddingBottom: '5px' }}
          >
            <Grid item xs={6}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Current Address
                </Label>
                <InputGroup>
                  {/* <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <CitySvg />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    id='address1'
                    type='text'
                    name='currentAddress?.address1'
                    placeholder='Building, House Number, Street Name'
                    feedbackText={errors?.currentAddress?.address1?.message}
                    state={
                      hasKey(errors, 'currentAddress.address1')
                        ? 'error'
                        : 'success'
                    }
                    showState={!!hasKey(errors, 'currentAddress.address1')}
                    {...register('currentAddress.address1', {
                      required: 'Current Address is required',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Permanent Address
                </Label>
                <InputGroup>
                  {/* <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <CitySvg />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    id='address2'
                    type='text'
                    name='currentAddress?.address2'
                    placeholder='District, Barangay, Muncipality'
                    feedbackText={errors?.currentAddress?.address2?.message}
                    state={
                      hasKey(errors, 'currentAddress.address2')
                        ? 'error'
                        : 'success'
                    }
                    showState={!!hasKey(errors, 'currentAddress.address2')}
                    {...register('currentAddress.address2', {
                      required: 'Permanent Address is required',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{ paddingBottom: '5px' }}
          >
            <Grid item xs={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Postal Code
                </Label>
                <InputGroup>
                  {/* <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <CitySvg />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    id='postalCode'
                    type='text'
                    name='currentAddress?.postalCode'
                    placeholder='Enter your Postal Code'
                    feedbackText={errors?.currentAddress?.postalCode?.message}
                    state={
                      hasKey(errors, 'currentAddress.postalCode')
                        ? 'error'
                        : 'success'
                    }
                    showState={!!hasKey(errors, 'currentAddress.postalCode')}
                    {...register('currentAddress.postalCode', {
                      required: 'Postal Code is required',
                      pattern: {
                        value: /^\d+$/,
                        message: 'invalid Postal Code',
                      },
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
            <Grid item xs={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Province / City
                </Label>
                <InputGroup>
                  {/* <InputGroupAddon dir='prepend'>
                  <InputGroupText>
                    <CitySvg />
                  </InputGroupText>
                </InputGroupAddon> */}
                  <Input
                    id='city'
                    type='text'
                    name='currentAddress?.city'
                    placeholder='Enter your city'
                    feedbackText={errors?.currentAddress?.city?.message}
                    state={
                      hasKey(errors, 'currentAddress.city')
                        ? 'error'
                        : 'success'
                    }
                    showState={!!hasKey(errors, 'currentAddress.city')}
                    {...register('currentAddress.city', {
                      required: 'City is required',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
          </Grid>
        </div>
        <StyledPersonalContainer>
          <Button type='submit' color='brand2' className='signin_btn'>
            Continue
          </Button>
        </StyledPersonalContainer>

        <StyledBottomText>
          Already have an account? &nbsp;
          <Link className='flex' to={''} onClick={() => openInfoPopup()}>
            Log In
          </Link>
        </StyledBottomText>
      </form>
    </StyledWrap>
  );
};

export default PersonalForm;
