import React from 'react';
// Mui
import { StyledPageRight, StyledPageTextContainer, StyledPageTextView } from "../../components/Custom/mainstyle"

import PageBanner from '@/components/Custom/PageBanner';
//



const Privacycontent = () => {  
return (

  <StyledPageRight>
  <StyledPageTextContainer>
  <PageBanner/>
    <StyledPageTextView>
      <h5>Privacy and Cookies</h5>
      <h5 className='weare_passio'>
        {process.env.REACT_APP_SITE_TITLE} is sensitive to privacy issues on the Internet. We
        believe it is important you know how we treat the information
        we receive from you, on the Internet.
      </h5>
      <p>
        In general, you can visit {process.env.REACT_APP_SITE_TITLE} on the World Wide Web
        without telling us who you are or revealing any information
        about yourself. Our Web servers collect the domain names, not
        the e-mail addresses, of visitors. This information is
        aggregated to measure the number of visits, average time spent
        on the site, pages viewed, etc. {process.env.REACT_APP_SITE_TITLE} uses this
        information to measure the use of our site and to improve the
        content of its site. Your accessing our site signifies your
        unconditional consent to allow the collection of your domain
        names.
      </p>
      <p>
        There are times, however, when we may need information from
        you, such as your name and address. When information is
        needed, we will try (but are not obligated) to let you know at
        the time of collection, how we will use the personal
        information. Usually, the personal information we collect is
        used only by us to respond to your inquiry, process an order
        or allow you to access specific account information.
        Occasionally, we may make the e-mail addresses, of those who
        provide information, available to other reputable
        organizations whose products or services we think you may find
        interesting. In these cases, you will be offered an
        opportunity to limit the access of your information. In case
        we do not receive any response from you to limit the access of
        your information, it shall be deemed that you consent to such
        distribution of your information as mentioned above.
      </p>
      <p>
        If you register with {process.env.REACT_APP_SITE_TITLE} online, we may use this
        information to provide you with custom information about
        {process.env.REACT_APP_SITE_TITLE} s offering in support of your business needs. A
        technology called cookies may be used to provide you with
        tailored information. A cookie is a tiny element of data that
        a web site can send to your browser, which may then be stored
        on your hard drive so we can recognize you when you return.
        You may set your browser to notify you when you receive a
        cookie. Registering with any {process.env.REACT_APP_SITE_TITLE} business unit online
        signifies your unconditional consent to receive such cookies.
      </p>
      <p>
        At times we conduct online surveys to better understand the
        needs and profiles of our visitors. When we conduct a survey,
        we will try (but are not obligated) to let you know how we
        will use the information at the time we collect information
        from you on the Internet.
      </p>
      <p>
        You recognize and understand that there is no compulsion on
        you to provide us with your personal information and any and
        all personal information provided by you to us is with your
        full consent, own volition and desire to provide such personal
        information. You also understand that we are under no
        obligation to verify the source from which the personal
        information about you is provided to us, and they are deemed
        to be provided by you, unless you demonstrate to us within a
        period of fifteen days from the date of providing of such
        information to us, to our satisfaction, that the information
        was provided to us without your free consent.
      </p>
      <p>
        {process.env.REACT_APP_SITE_TITLE} s Website may contain links to other sites such as
        {process.env.REACT_APP_SITE_TITLE} partners and affiliates. While we try to link only
        to sites that share our high standards and respect for
        privacy, we are not responsible for the content or the privacy
        practices employed by other sites.
      </p>
    </StyledPageTextView>
  </StyledPageTextContainer>
</StyledPageRight>
          
);

};

export default Privacycontent;

