import { IModal } from './types';
import React from 'react';
import Box from '@mui/material/Box';
import {StyledModelView} from './style'; 
import {Modal as MaterialModal} from '@mui/material/';

const isMobile = window.screen.width < 1200;

const style = isMobile ? {
  position: 'absolute' as 'absolute',
  bottom: '0',
}:{
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const Modal = ({
  className ,
  show = false,
  size,
  centered,
  children,
  onClose,
  ...restProps
}: IModal) => {
  return (
<>
<StyledModelView>
    <MaterialModal
    style={{display: 'flex'}}
    open={show}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className='mobilemodalviw'
  >
    <>
      <Box sx={style}>
      {children}</Box></>
    </MaterialModal>
  </StyledModelView>
{/*
    <Portal>
      <>
        <StyledBackdrop $show={show} />
        <StyledModal
          $show={show}
          $size={size}
          $centered={centered}
          //className={classnames(className)}
          className='popupmobile'
          onClick={onClose}
          {...restProps}
        >
          <CSSTransition
            in={show}
            timeout={400}
            unmountOnExit
            classNames='modal'
          >
            {() => (
              <div className='modal-dialog'>
                <div
                  className='modal-content'
                  onClick={e => e.stopPropagation()}
                  role='button'
                  tabIndex={0}
                >
                  {children}
                </div>
              </div>
            )}
          </CSSTransition>
        </StyledModal>
      </>
    </Portal> */}
    </>
  );
};

Modal.defaultProps = {
  size: 'md',
  centered: true,
};

export default Modal;
