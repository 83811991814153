import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SvgIcon from '@mui/material/SvgIcon';

export type GameCategoryTabType = {
  label: string,
  icon: any,
  types?: Array<string>,
}

type Props = {
  tabs: Array<GameCategoryTabType>,
  showLabels: boolean,
  tabIndex: number,
  onClick: (tabIndex: number) => void,
};

export default function GameCategoryNavigation({ tabs, showLabels, tabIndex, onClick }: Props) {
  return (
    <BottomNavigation
      sx={{ paddingLeft: 1, paddingRight: 1 }}
      showLabels={showLabels}
      value={tabIndex}
      onChange={(event, newValue) => {
        console.log('newValue', newValue);
        onClick(newValue);
      }}
    >
      {tabs.map((tab: GameCategoryTabType) => (
        <BottomNavigationAction
          key={tab.label}
          label={tab.label}
          icon={
            tab.icon && <SvgIcon>
              <tab.icon />
            </SvgIcon>
          }
        />
      ))}
    </BottomNavigation>
  );
}
