import { FormGroup, Button, ButtonSpinner } from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useState } from 'react';
import { Grid } from '@mui/material/';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import { StyledWrap, StyledButtonContainer, StyledSteps } from './style';

interface ChildProps {
  parentModalHandlerFunction: any;
  closePopup(arg0: string): void;
}

const Welcome = (props: ChildProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<AccountCForm>();

  const onSubmit: SubmitHandler<AccountCForm> = data => {
    console.log(data, errors);
    setLoading(true);
  };

  const showKYCForm = () => {
    props.closePopup('close');
    dispatch(changeModule('kyc'));
  };

  return (
    <StyledWrap>
      <form action='#' onSubmit={handleSubmit(onSubmit)} noValidate>
        <StyledSteps>
          <img
            height='40px'
            src={process.env.REACT_APP_THEME_LOGO_MOBILE}
            alt=''
          />
          <h4>Welcome to {process.env.REACT_APP_SITE_TITLE}!</h4>
        </StyledSteps>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <FormGroup className='loginform'>
            <div className='termsandcondition'>
              <h5>
                Submit your KYC to avoid any interruption to your gaming
                experience!
              </h5>
              <p>Note: KYC verification takes 24 - 48 hours to complete.</p>
            </div>
          </FormGroup>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          className='nomargin'
        >
          <StyledButtonContainer>
            <Button
              type='button'
              color='brand2'
              className='signupbtn'
              onClick={() => props.closePopup('close')}
            >
              Skip for now
            </Button>
            <Button
              type='submit'
              color='brand2'
              className='loginbtn'
              onClick={() => showKYCForm()}
            >
              {loading ? <ButtonSpinner /> : null}Complete KYC
            </Button>
          </StyledButtonContainer>
        </Grid>
      </form>
    </StyledWrap>
  );
};

export default Welcome;
