import React, { ElementType, Suspense, lazy } from 'react';
import Container from '@mui/material/Container';
import Page from '@/components/Page';

import WidgetIndex from '../../components/Custom/widgets';
import { Grid } from '@mui/material';
import HeaderTitle from '../../components/Custom/HeaderTitle';

import { useRoutes, Outlet, useLocation } from 'react-router-dom';
import LoadingScreen from '@/components/LoadingScreen';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

const TopicsIndex = Loadable(lazy(() => import('./pages/TopicsIndex')));
const FaqsDetails = Loadable(lazy(() => import('./pages/FaqsDetails')));
const FaqAccountAssistance = Loadable(lazy(() => import('./pages/FaqAccountAssistance')));
const FaqDepositsWithdrawals = Loadable(lazy(() => import('./pages/FaqDepositsWithdrawals')));
const FaqEgamesInquiries = Loadable(lazy(() => import('./pages/FaqEgamesInquiries')));
const FaqGettingStarted = Loadable(lazy(() => import('./pages/FaqGettingStarted')));
const FaqRtggamesInquiries = Loadable(lazy(() => import('./pages/FaqRtggamesInquiries')));
const FaqSportsbetsEnquiries = Loadable(lazy(() => import('./pages/FaqSportsbetsEnquiries')));
const FaqTrustSafety = Loadable(lazy(() => import('./pages/FaqTrustSafety')));

const leftSideWidgets = [{comp: 'Contact', desc: ''}, {comp: 'Support'}, {comp: 'Chat'}];
let isMobile = window.screen.width < 1200;

const Faqs: React.FC = () => {
  return (
    <Page title="FAQ" favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      <Container maxWidth={'lg'} style={{paddingLeft:'0px', paddingRight:'0px'}}>
        <div style={ isMobile ?{paddingLeft:'5vw'}:{}}>
          <HeaderTitle title="FAQs &amp; Help" />
        </div>
        <Grid container spacing={3} style={{}}>
          <Grid item style={ isMobile ?{ width: "100%" }:{ width: "366px" }}>
            <WidgetIndex componentsList={leftSideWidgets} />
          </Grid>
          <Grid item xs style={{position:'relative'}}>
          { useRoutes([{
          path: '*',
          element: (
            <>
            <Outlet />
            </>
          ),
          children: [
                { element: <TopicsIndex />, index: true },
                { path: 'details', element: <FaqsDetails /> },
                { path: 'gettingstarted', element: <FaqGettingStarted /> },
                { path: 'depositswithdrawals', element: <FaqDepositsWithdrawals /> },
                { path: 'sportsbetsenquiries', element: <FaqSportsbetsEnquiries /> },
                { path: 'accountassistance', element: <FaqAccountAssistance /> },
                { path: 'trustsafety', element: <FaqTrustSafety /> },
                { path: 'egamesinquiries', element: <FaqEgamesInquiries /> },
                { path: 'rtggamesinquiries', element: <FaqRtggamesInquiries /> },
              ],
            }])}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Faqs;
