import { QuestionSvg, ArrowRightSvg, HeadPhoneSvg, MessageSvg } from "@/shared/svg";
import React from "react";
import { Link } from "react-router-dom";
// import { StyledInfoBox, StyledTextArea } from "../../../pages/faqs/style";
import { StyledInfoBox, StyledTextArea } from "../mainstyle"
import { Card } from "@mui/material";

const Support: React.FC<any> = ({ message = "" }: { message?: string }) => {
    return (
        
            <StyledInfoBox>
                <span>
                    <HeadPhoneSvg />
                </span>
                <StyledTextArea>
                    <p className='chat_with_text'>Customer support</p>
                    <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
                    {/* <strong>(+63) 995 834 9274</strong>
                    <small>Available: 24x7</small> */}
                </StyledTextArea>
            </StyledInfoBox>
       
    );
};

export default Support;
