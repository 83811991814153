import React, { useEffect } from 'react';
// Mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
// SVG
import { AttentionIconSvgInheritColor } from '@/shared/svg';
import { Link } from 'react-router-dom';
import { changeModule } from '@/redux/slices/module';
import { useAppDispatch } from '@/hooks/useAppDispatch';

const Unauthenticated = () => {
  const dispatch = useAppDispatch();
  const showLogin = () => dispatch(changeModule('login'));

  useEffect(()=>{
    showLogin();
  },[]);

  return (
    <ButtonBase
      component={Link}
      to={''}
      onClick={showLogin }
      sx = {{ width: '100%', p: 6, minHeight: 300, color: 'white', }}
    >
      <Stack direction="column" spacing={1} alignItems="center">
        <AttentionIconSvgInheritColor />
        <Typography variant="h4">
          Please login
        </Typography>
        <Typography variant="body2">
          to access this page.
        </Typography>
      </Stack>
    </ButtonBase>
  );
};

export default Unauthenticated;
