import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemText } from '@mui/material';
import { ButtonBase } from '@mui/material';

const LabelView = ({ label, value }: { label: string; value: string }) => {
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem secondaryAction={<span>{value}</span>}>
        <ListItemText primary={label} />
      </ListItem>
    </List>
  );
};

const BetBlock = ({ type, odds }: { type: string; odds: any }) => {
  return (
    <div className='gamebox'>
      <Typography variant='h5' component='div'>
        {type}
      </Typography>
      <Typography
        className='oddtext'
        sx={{ fontSize: 14 }}
        color='text.secondary'
        gutterBottom
      >
        {type === 'X' ? '(ODD)' : '(EVEN)'}
      </Typography>
      <Typography
        className='oddvalue'
        sx={{ mb: 1.5 }}
        color='text.secondary'
        component='div'
      >
        <div>{odds.value}</div>
      </Typography>
      <>
        {odds.list.map((item: any) => (
          <LabelView label={item.label} value={item.value} key={item.id} />
        ))}
      </>
    </div>
  );
};

const OpenBlock = ({ venue, race }: { venue: any; race: any }) => {
  return (
    <>
      <Typography
        className='betstatusopen'
        variant='h5'
        style={{ fontSize: '20px', color: '#fff', paddingBottom: '10px' }}
        component='div'
      >
        <span>
          <svg width='12' height='12' viewBox='0 0 12 12' fill='none'>
            <circle cx='6' cy='6' r='4' fill='currentColor'></circle>
            <circle cx='6' cy='6' r='5.5' stroke='currentColor'></circle>
          </svg>
        </span>
        <span style={{ marginLeft: '8px' }}>Open</span>
      </Typography>
      <div className='divider'></div>
      <Typography
        style={{ fontSize: '12px', color: '#fff', paddingTop: '6px' }}
        sx={{ mb: 1.5 }}
        color='text.secondary'
      >
        {race.name}
      </Typography>
      <div className='divider'></div>
      <Typography
        style={{ fontSize: '12px', color: '#fff', paddingTop: '6px' }}
        sx={{ fontSize: 14 }}
        color='text.secondary'
        gutterBottom
      >
        Start at {race.raceTime}
      </Typography>
    </>
  );
};

const ClosedBlock = () => {
  return (
    <>
      <Typography
        className='betstatusclose'
        variant='h5'
        style={{ fontSize: '20px', color: '#fff', paddingBottom: '10px' }}
        component='div'
      >
        <span>
          <svg width='12' height='12' viewBox='0 0 12 12' fill='none'>
            <circle cx='6' cy='6' r='4' fill='currentColor'></circle>
            <circle cx='6' cy='6' r='5.5' stroke='currentColor'></circle>
          </svg>
        </span>
        <span style={{ marginLeft: '8px' }}>Closed</span>
      </Typography>
    </>
  );
};

const GridBox: React.FC<any> = ({
  item,
  venue = {},
  values,
  submitBet,
}: {
  item: string;
  venue: any;
  values: any;
  submitBet: Function;
}) => {
  return (
    <Card>
      <CardContent sx={{ padding: '0px !important' }}>
        {item === 'odd' && (
          <div className='gamex'>
            <ButtonBase
              onClick={() => submitBet('odd')}
              sx={{ height: '100%', width: '100%' }}
            >
              <BetBlock type='X' odds={values} />
            </ButtonBase>
          </div>
        )}
        {item === 'open' && (
          <div className='gameopen'>
            {values?.status === 'BST' ? (
              <OpenBlock venue={venue} race={values} />
            ) : (
              <ClosedBlock />
            )}
          </div>
        )}
        {item === 'even' && (
          <div className='gameo'>
            <ButtonBase
              onClick={() => submitBet('even')}
              sx={{ height: '100%', width: '100%' }}
            >
              <BetBlock type='O' odds={values} />
            </ButtonBase>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default GridBox;
