import React, { useState } from 'react';
import ImageSlider from './imageSlider';
import sportsIcon from './../../../public/icons/arionplay/sports-betting.svg';
import diceIcon from './../../../public/icons/arionplay/dice.svg';
import casinoBannerM from './../../../public/images/Casino Banner-mobile.jpg';
import casinoBanner from './../../../public/images/Casino Banner.jpg';
import xoBannerM from './../../../public/images/XO-Banner-mobile.jpg';
import xoBanner from './../../../public/images/XO Banner.jpg';
import classicM from './../../../public/images/Classic Games Banner-mobile.jpg';
import classic from './../../../public/images/Classic Games Banner.jpg';

import { StyledContainer, StyledFooter, TitleContainer, SportsBettingContainer, CasinoContainer, GameContainer, Game} from './style';



const Landing = () => {
  const [activeDisplayedGame, setactiveDisplayedGame] = useState(1);
  let isMobile = window.screen.width < 1200;
  let isPointerDown = false;


  
  const onPointerDownHandler = ()=>{
    isPointerDown = true;
  } 
  const onPointerMoveHandler = (e:any)=>{
    // console.log(e);
    console.log(e.movementX);
    
    if (e.movementX > 2) {
      setactiveDisplayedGame(1);
    }else if (e.movementX < -2) {
      setactiveDisplayedGame(2);
    }

  }
  const onPointerUpHandler = ()=>{
    isPointerDown = false; 
  }

  return (
      <StyledContainer>
        <ImageSlider/>
        <SportsBettingContainer>
          <TitleContainer>
            <img className='icon' src={sportsIcon}/>
            Sports Betting Games
          </TitleContainer> 
          <GameContainer
            onPointerDown={isMobile? onPointerDownHandler : null}
            onPointerUp={isMobile ? onPointerUpHandler : null}
            onPointerMove={(event)=>{isMobile ? onPointerMoveHandler(event) : null}}
          >
            <Game className={`xo-games-container ${activeDisplayedGame == 1 ? '--active-display':''}`}>
              <img className={`xo-games`} src={isMobile? xoBannerM : xoBanner}/>
              <h1> XO </h1>
            </Game>
            <Game className={`classic-games-container ${activeDisplayedGame == 2 ? '--active-display':''}`}>
              <img className={`classic-games`} src={isMobile? classicM : classic}/>
              <h1 className="h1-classic"> Classic Games </h1>
            </Game>
          </GameContainer>
        </SportsBettingContainer>
        <CasinoContainer>
          <TitleContainer>
            <img className='icon' src={diceIcon}/>
            Casino Games
          </TitleContainer>
          <GameContainer>
            <Game>
              <img className='casino' src={isMobile? casinoBannerM : casinoBanner}/>
              <h1> Casino Games </h1>
            </Game>
          </GameContainer>
        </CasinoContainer>
        
      </StyledContainer>
  );
};

export default Landing;
