import * as React from 'react';
import { SnackbarProvider } from 'notistack';
// theme
import ThemeProvider from './theme';
// Router
import Router from './Router';
// Components
import ScrollToTop from './components/ScrollToTop';
// import MotionLazyContainer from './components/animate/MotionLazyContainer';

// TODO:
// Here shall be UI-Material Theme etc.

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <SnackbarProvider autoHideDuration={5500} maxSnack={3}>
        <ScrollToTop />
        <Router />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
