import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Divider(theme: Theme) {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.text.primary,
          width: '100%',
        },
      },
    },
  };
}
