import {
  FormGroup,
  Label,
  Input,
  Button,
  ButtonSpinner,
  InputGroup,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {Grid} from '@mui/material/';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';
import { updateForgotData } from '@/redux/slices/forgotpassword';

import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledBottomText,
  StyledForgotText,
  StyledPhoneText,
  StyledAlertMessage,
  StyledPersonalContainer,
} from './style';
import { AlertWarningiconSvg } from '@/shared/svg';

interface ChildProps {
  changeSteps: any;
}

const OTPForm = (props: ChildProps) => {
  const counterTime = 60;
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const { forgotFormData } = useAppSelector(state => state.forgototp);
  const [counter, setCounter] = useState(counterTime);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IOTPForm>();

  const onSubmit: SubmitHandler<IOTPForm> = async data => {
    setLoading(true);
    const userObj = {
      ...forgotFormData,
      ...data,
    };
    const forgotInfo = await AuthService.resendForgotPassword(userObj);
    console.log(forgotInfo, '===info');
    if (forgotInfo.status) {
      props.changeSteps('forgotsuccess');
      setLoading(true);
    } else {
      setLoading(false);
      setIsNotValid(true);
      setMessage(forgotInfo.data);
    }
  };

  const resendForgotOtp = async () => {
    setLoading(true);
    setCounter(counterTime);
    const otpObj = forgotFormData;
    const response = await AuthService.resendForgotPasswordAgain(otpObj);
    if (response.status) {
      setLoading(false);
      setCounter(counterTime);
      dispatch(updateForgotData(response.data));
    } else {
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data);
    }
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <StyledWrap>
      <StyledTitle>OTP Verification</StyledTitle>
      <StyledDesc>Enter the OTP sent to .</StyledDesc>
      <form
        className='loginform'
        action='#'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {isNotValid ? (
          <StyledAlertMessage>
            <AlertWarningiconSvg /> {message}
          </StyledAlertMessage>
        ) : null}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {/* <Grid item xs={2} className='no_padding'>
            <Label display='block' mb='5px' htmlFor='email'>
              OTP
            </Label>
            <InputGroup>
              <StyledPhoneText>
                <strong>A-</strong>
              </StyledPhoneText>
              <Input className='otpinput' type='text' id='otp' name='otp' />
            </InputGroup>
          </Grid> */}
          <Grid item xs={12}>
            <FormGroup>
              <Label display='block' mb='5px' htmlFor='email'>
                OTP
              </Label>
              <InputGroup>
                {/* <InputGroupAddon dir='prepend'>
                  <InputGroupText>
                    <MobileSvg />
                  </InputGroupText>
                </InputGroupAddon> */}
                <StyledPhoneText>A-</StyledPhoneText>
                <Input
                  className='mobilenumber'
                  type='text'
                  id='otp'
                  name='otp'
                  placeholder='Enter OTP'
                  feedbackText={errors?.otp?.message}
                  state={hasKey(errors, 'otp') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'otp')}
                  {...register('otp', {
                    required: 'OTP is required',
                    pattern: {
                      value: /^[0-9]{5}$/,
                      message: 'invalid OTP format',
                    },
                    maxLength: {
                      value: 6,
                      message: 'Minimum length is 6',
                    },
                  })}
                />
              </InputGroup>
            </FormGroup>
          </Grid>
        </Grid>
    
        <div className='receivetimer'>
          {counter ? (
            <div className='timerview'>{counter}</div>
          ) : (
            <StyledForgotText>
              <span>Haven&apos;t received the verification code?</span>
              <button className='resendbtn' onClick={() => resendForgotOtp()}>
                Resend OTP
              </button>
            </StyledForgotText>
          )}
        </div>
        <StyledPersonalContainer>
          <Button type='submit' color='brand2' className='signin_btn'>
            {loading ? <ButtonSpinner /> : null}
            Verify Now
          </Button>
        </StyledPersonalContainer>

        <StyledBottomText>
          Already have an account ?<Link to='/'> Log In</Link>{' '}
        </StyledBottomText>
      </form>
    </StyledWrap>
  );
};

export default OTPForm;
