import React from "react";
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from "../../../components/Custom/mainstyle"
import AccordionSection from "../../../components/Custom/AccordionSection";
import PageHead from "../components/PageHead";

const listDetails = [
    { title: "What are the reasons for limiting my account?", desc: `<h4>Account limitations can indeed be imposed for various reasons, and some of the common factors include:</h4>
    <ul>
    <li>1. Consistent High Winnings: Accounts that consistently win large amounts may undergo additional scrutiny to ensure fair play and prevent potential exploitation of the platform.</li>
    <li>2. Suspected Arbitrage Betting: Arbitrage betting involves taking advantage of price discrepancies in the betting market to guarantee profits. Some platforms may limit accounts engaged in such practices.</li>
    <li>3. Bonus Abuse: If a user is found exploiting bonuses or promotions in a way that violates the terms and conditions, their account may be limited.</li>
    <li>4. Breaches of Terms and Conditions: Any violation of the platform's terms and conditions, such as creating multiple accounts or engaging in fraudulent activities, can lead to account limitations.</li>
    <li>5. Excessive Price Sensitivity: Constantly placing bets right before an event's start time to capitalize on last-minute odds changes might raise concerns and lead to limitations.</li>
    </ul>` },
    
];

const FaqAccountAssistance: React.FC = () => {
    return (
        <StyledInfoBoxRight>
            <PageHead title="Account Assistance & Controls" />
          <AccordionSection list={listDetails} />
        </StyledInfoBoxRight>
    );
};

export default FaqAccountAssistance;
