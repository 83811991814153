import * as React from 'react';
// Mui
import { useTheme, styled } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
// utils
import { fNumber } from '../utils/formatNumber';
// SVG
// import { GreenDotSVG } from '@/shared/svg';
// Api
import { RaceBetType } from '../api/RaceCardApi';

type Props = {
  raceBet: null | RaceBetType,
  handleConfirm: (raceBet: null | RaceBetType) => void,
  handleCancel: () => void,
};

export default function SportEventsMenu({ raceBet, handleConfirm, handleCancel }: Props) {
  const open = Boolean(raceBet);
  const theme = useTheme();

  return (
    <Popover
      aria-labelledby="racebet-confirm-popover"
      open={open}
      onClose={handleCancel}
      marginThreshold={48}
      // BackdropProps={{ invisible: false }}
      anchorOrigin={{vertical: 'center', horizontal: 'center'}}
      transformOrigin={{vertical: 'center', horizontal: 'center'}}
      PaperProps={{
        elevation: 2,
        sx: {
          padding:'24px',
          minWidth: 300,
          background:' linear-gradient(141.73deg, rgba(65, 69, 80, 1) 2.64%, rgba(32, 35, 43, 1) 96.27%)',
          border:'1.5px solid rgba(97, 97, 97, 0.35)',
          boxShadow:'none',
          borderRadius:'20px'
        },
      }}
    >
      <Typography variant="subtitle1" textAlign="center" sx={{fontSize:'1.275rem',  fontFamily:'"Roboto", sans-serif, Helvetica, Arial', fontWeight:'500'  }}>
        Confirm Bet
      </Typography>
      {raceBet &&
        <Card elevation={1} sx={{ border:'1.5px solid #414853', margin: '1rem 0', padding: 2, background:'rgba(21, 31, 37, 1)' }}>
          <Typography variant="subtitle1" sx={{fontSize:'.875rem', paddingBottom:'4px', fontFamily:'"Roboto", sans-serif, Helvetica, Arial', fontWeight:'600'  }}>
            {raceBet.venueName}
          </Typography>
          <Typography variant="subtitle1" sx={{fontSize:'.875rem', paddingBottom:'8px', fontFamily:'"Roboto", sans-serif, Helvetica, Arial', fontWeight:'400'}}>
            {raceBet.eventName} - {raceBet.startTime}
          </Typography>
          <Typography variant="subtitle1" sx={{fontSize:'1.2rem', paddingBottom:'8px', fontFamily:'"Roboto", sans-serif, Helvetica, Arial', fontWeight:'500'}}>
            {raceBet.type} - {raceBet.type === 'ODD' ? 'X' : 'O'}
          </Typography>
          <Typography variant="subtitle1" sx={{ borderTop: '1px solid #414853', paddingTop:'8px', fontSize:'.875rem', fontFamily:'"Roboto", sans-serif, Helvetica, Arial',  fontWeight:'400', display:'flex',  alignItems:'center', gap:'4px' }}>
            Bet amount: <span style={{fontSize:'1rem', fontWeight:'600', fontFamily:'"Roboto", sans-serif, Helvetica, Arial',}}>{raceBet.amount} {raceBet.currency}</span>
          </Typography>
        </Card>
      }
      <Stack direction="row" justifyContent="space-between" spacing={2.5} sx={{ marginTop:'20px'}}>
        <Button variant="outlined" fullWidth={true} onClick={handleCancel} size="large" sx={{fontFamily:'"Roboto", sans-serif, Helvetica, Arial', color:'#fff', fontWeight:'600', border:'1.5px solid rgba(255, 255, 255, 0.3)', borderRadius:'16px'}}>
          Cancel
        </Button>
        <Button  variant="contained" fullWidth={true} onClick={() => handleConfirm(raceBet)} size="large" sx={{fontFamily:'"Roboto", sans-serif, Helvetica, Arial', fontWeight:'600',  borderRadius:'16px'}}>
          Confirm
        </Button>
      </Stack>
    </Popover>
  );
}