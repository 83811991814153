import styled, { themeGet } from '@/shared/styled';

export const StyledContainer = styled.div`
    width: 100vw;
    color: black !important; 
    background-color: white;
`;
  
export const StyledFooter = styled.div`
    width: 100vw;
    height: fit-content;
    padding: 15px 0px;
    background-color: ${themeGet('colors.homebannertxtcolor')};
    bottom: 0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:15px;
`;

export const GameContainer = styled.div`
    display: flex;
    justify-content: space-between;  
    align-items: space-between;
    widht: 100vw;
    height: fit-content;


    @media (max-width: 1200px) {
        position: relative;
        height: 60vw;
        .xo-games-container, .classic-games-container{
            position: absolute;
            top: 0px;
        } 
        .xo-games-container{
            left: 0px;
            border-radius: 10px;
            img{
                max-width: 73vw;
            }
        } 
        .classic-games-container{
            right: 0px;
        }
        
        .--active-display{
            img{
                width: 100vw;
            }
            h1, .h1-classic{
                opacity: 1;
            }
        }
    }
`;

export const Game = styled.div`
    margin: 2vw 0vw 0vw 0vw;
    position: relative;

    img:hover{
        box-shadow: -4px 5px 23px -3px rgba(0,0,0,0.75);
    }

    h1{
        position: absolute;
        color: white;
        bottom: 1vw;
        left: 2vw;
        font-weight: bold;
        font-size: 1.9vw;
    }
    .h1-classic{
        position: absolute;
        color: white;
        bottom: 1vw;
        left: 3.5vw;
        font-size: 1.9vw;
        font-weight: bold;
    }

    .xo-games{
        border-radius: 50px;
        width: 95%;
    }

    .classic-games{
        border-radius: 50px;
        width: 95%;
        float: right;
    }

    .casino{
        border-radius: 50px;
    }

    @media (max-width: 1200px) {
        .casino, .classic-games, .xo-games{
            border-radius: 30px;
        }
        .classic-games, .xo-games{
            width: 15vw;
            height: 50vw;
            transition: width 1s;
            object-fit: cover;
        }
        img:hover{
            box-shadow: unset;
        }
        h1, .h1-classic{
            bottom: 3vw;
            left: 5vw;
            font-size: 6vw;
            opacity: 0;
            transition: opacity .5s;
        }
        
    }
`;


export const TitleContainer = styled.div`
    display: flex;
    font-size: 1.5vw;
    align-items: center;
    font-weight: bold;

    .icon{
        margin-right: 1vw;
        width: 2vw;
    }
    @media (max-width: 1200px) {
        font-size: 5vw;
        .icon{
            scale: 3.5;
            margin: 0px 20px 0px 10px;
        }
    }
`;

export const SportsBettingContainer = styled.div`
    background-color: white !important;
    padding: 5vw 9vw 5vw 9vw;
    width: 100vw;
    
    @media (max-width: 1200px) {
        padding: 5vw 5vw 5vw 5vw;
    }
`;
export const CasinoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5vw 9vw 5vw 9vw;
    background-color: #F2F2F2;
    width: 100vw;

    @media (max-width: 1200px) {
        padding: 5vw 5vw 5vw 5vw;
    }
`;
