import styled, { themeGet, device } from '@/shared/styled';
import { ButtonBase } from '@mui/material';

export const StyledButtonBase = styled.button`

  '${device.MobToSL}': {
    height: '10rem',
  }

`
export const StyledBetButton = styled.div`
.tnt.X{

  .betamounttext{
    h4{
      // font-family: 'Pragati Narrow', sans-serif;
      // font-weight: 700;
      // font-size:18px;
      text-shadow: none;
      color:${themeGet('colors.white')};
      line-height:24px;
      ${device.MobToMd}{
        // font-size:12px;
      }
    }
    h6{
      // font-family: 'Pragati Narrow', sans-serif;
      // font-weight: 700;
      // font-size:20px;
      text-shadow: none;
      color:${themeGet('colors.white')};
      line-height:24px;
      ${device.MobToMd}{
        // font-size:14px;
      }
    }
  }
}
.tnt.O{

  .betamounttext{
    h4{
      // font-family: 'Pragati Narrow', sans-serif;
      // font-weight: 700;
      // font-size:18px;
      text-shadow: none;
      color:${themeGet('colors.white')};
      line-height:24px;
      ${device.MobToMd}{
        // font-size:12px;
      }
    }
    h6{
      // font-family: 'Pragati Narrow', sans-serif;
      // font-weight: 700;
      // font-size:20px;
      text-shadow: none;
      color:${themeGet('colors.white')};
      line-height:24px;
      ${device.MobToMd}{
        // font-size:14px;
      }
    }
  }
}

`
export const OddWrapperDiv = styled.div`



`