import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Select,
  InputGroup,
  ButtonSpinner,
  InputGroupAddon,
} from '@/components';
import { SubmitHandler, useForm } from 'react-hook-form';


import {
  StyledWrap,
  StyledTitle,
  StyledPhoneText,
  StyledPersonalContainer,
  StyledContryCode,
  StyledMobileCode,
  StyledMobileView,
  StyledInfoCircle,
  StyledAlertMessage,
  StyledReferalView,
} from './style';

import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';
import { ErrorWarningiconSvg, EyeHideIconSvg, EyeIconSvg, InfocircleIconSvg } from '@/shared/svg';
import { Alert, Grid } from '@mui/material';



const DepositPlayer = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [depositLoading, setDepositLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [message, setMessage] = useState<any>('');
  const [isNotValid, setIsNotValid] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<AmountPlayer>();  

  const handleUser = (e: any) => {
    setMessage('');
  };

  const onSubmit: SubmitHandler<AmountPlayer> = async data => {
   // setLoading(true);
  };

  const depositAmount = async function(val:any){
    setMessage('');
    setDepositLoading(true);
    let amount = getValues('amount');
    let userId = getValues('userId');
    if(userId == ''){
    //alert('Please select player to deposit');
    setMessage('Please select player to deposit');
    setDepositLoading(false);
    return;
    }
    if(amount == ''){
    //alert('Please enter amount to deposit');
    setMessage('Please enter amount to deposit');
    setDepositLoading(false);
    return;
    }
    const temp = {"userId":userId,amount:amount}
    const response = await AuthService.playerDeposit(temp);  
    if (response.status) {
      setDepositLoading(false);
      setMessage('Amount Deposited Successfully');
      reset();
      } 
      else {
      setDepositLoading(false);
      setMessage('Amount Deposited Failed');
    }

  }

  const withdrawAmount = async function(val:any){
    setMessage('');
    setWithdrawLoading(true);
    let amount = getValues('amount');
    let userId = getValues('userId');
    if(userId == ''){
      //alert('Please select player to withdraw');
      setMessage('Please select player to withdraw');
      setWithdrawLoading(false);
      return;
    }
    if(amount == ''){
      //alert('Please enter amount to withdraw');
      setMessage('Please enter amount to withdraw');
      setWithdrawLoading(false);
      return
    }
    const temp = {"userId":userId,amount:amount}
    const response = await AuthService.playerWithdraw(temp);  
    if (response.status) {
      setWithdrawLoading(false);
      setMessage('Amount Withdraw Successfully');
      reset();
      } 
      else {
        setWithdrawLoading(false);
        setMessage('Amount Withdraw Failed');
      }
  }

  return (
    <StyledWrap>
      
    <StyledTitle>Cashier Deposit/WithDraw Form</StyledTitle>
    <StyledReferalView>
    {message != '' && <Alert severity="error">{message}</Alert>}
    <form
      action='#'
      className='loginform regesterform'
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >

                {isNotValid ? (
                    <StyledAlertMessage>
                      <ErrorWarningiconSvg /> {message}
                    </StyledAlertMessage>
                  ) : null}

<Grid container direction='column' md={12} >
          <FormGroup>
            <Label display='block' mb='5px' htmlFor='email'>
              Player<span>*</span>{' '}
            </Label>
            <StyledMobileView>
              <StyledContryCode>
                <InputGroup>
                  <Select
                    id='userId'
                    name='userId'
                    className='select'
                    {...register('userId', {
                      required: 'Please Select User'                  
                    })}
                    onChange={e => handleUser(e)}
                  >                    
                    <option value=''>Select Player</option>
                    <option value='46876bff-eb60-4d70-a29e-b2eaaae59a0e'>+639946111146</option>
                    <option value='3e413927-e7a5-4f85-ba8f-cc195136944e'>+639946222246</option>
                    <option value='f3378a9c-b51e-456f-9ca8-c102a0a13329'>+639946333346</option>                    
                    <option value='e83ea2ec-1ce0-40bd-9a1f-f47c1903774c'>+639946444446</option>
                    <option value='3ca6f5ba-9e71-4a58-8cf1-57e7e25d9a91'>+639946555546</option>
                    <option value='f0ef9ff4-6ae5-4198-a7eb-37e2506f5290'>+639946666646</option>
                    <option value='fc42eb9c-aefe-46d0-a90c-9c0d69d13951'>+639946777746</option>
                    <option value='0908d78f-fae0-4925-8eaa-11cb9a94b5b2'>+639946888846</option>                    
                    <option value='7e0be731-dfc0-4204-ac93-acec4267d587'>+639946999946</option>
                    <option value='9b2532bf-c1bc-4a77-a42b-6546f04f2e23'>+631111111111</option>
                    <option value='4dd88216-b3fb-42cf-b443-bfe96af7611b'>+635134567890</option>
                    <option value='fa860d87-b755-4d4a-bc0b-06f1c41c84fe'>+919052932167</option>
                  </Select>
                </InputGroup>
              </StyledContryCode>              
            </StyledMobileView>
          </FormGroup>
        </Grid>
        <Grid container direction='column' md={12} >
          <FormGroup>
            <Label display='block' mb='5px' htmlFor='email'>Amount<span>*</span>{' '}</Label>
            <InputGroup>
              <Input
                id='amount'
                type='text'
                name='amount'
                placeholder='Enter Amount'  
                feedbackText={errors?.amount?.message}
                state={hasKey(errors, 'amount') ? 'error' : 'success'}
                showState={!!hasKey(errors, 'amount')}
                {...register('amount')}
              />             
            </InputGroup>
          </FormGroup>
        </Grid>                           
      <StyledPersonalContainer>
        <Button type='submit' color='brand2' className='signin_btn deposit_btn' onClick={() => depositAmount('deposit')}>
          {depositLoading ? <ButtonSpinner /> : null}Deposit
        </Button>
        <Button type='submit' color='brand2' className='signin_btn withdraw_btn' onClick={() => withdrawAmount('withdraw')}>
          {withdrawLoading ? <ButtonSpinner /> : null}Withdraw
        </Button>
      </StyledPersonalContainer> 
      <StyledPersonalContainer>
        
      </StyledPersonalContainer>      
    </form>
    </StyledReferalView>
  </StyledWrap>
  );
};

export default DepositPlayer;

