import React, { useEffect, useState } from 'react';
import {
  StyledVideoContainer,
  StyledVideoView,
  StyledVideoViewUpdate,
} from './style';
import Iframe from '@/components/iframe';
import VideoJsPlayer from '@/components/player';
import RacingService from '@/services/RacingService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { setRaceStreamData } from '@/redux/slices/racecard';
import AuthService from '@/services/AuthService';
import { setSelectedUserWallet } from '@/redux/slices/wallet';

interface childProps {
  venueId: string;
  status?: string;
}
const RaceVideo = (props: childProps) => {
  const dispatch = useAppDispatch();
  const { raceStreamData } = useAppSelector(state => state.raceinfo);
  const [streamObj, setStreamObj] = useState<IStream>({
    message: '',
    iframeFlag: '',
    player: '',
    streamUrl: '',
    isPaid: false,
  });
  const [player, setPlayer] = useState({
    fill: true,
    fluid: true,
    autoplay: true,
    controls: true,
    preload: 'metadata',
    sources: [
      {
        src: 'https://v3.szjal.cn/20191101/PZzNpqB1/index.m3u8',
        type: 'application/x-mpegURL',
      },
    ],
  });
  const [walletBal, setWalletBal] = useState(0);
  const getStreamDetails = async () => {
    setStreamObj({
      message: '',
      iframeFlag: '',
      player: '',
      streamUrl: '',
      isPaid: false,
    });
    const response = await RacingService.getStreamData(props.venueId);
    if (response.body && response.body.data) {
      const data = response.body.data;
      console.log(data);
      if (data.provider === 'SIS') {
        const result = await RacingService.getSISStreamUrl(data.streamId);
        data.iframeFlag = 'N';
        if (result.body.data.error !== undefined && result.body.data.error) {
          data.message = result.body.data.error;
        } else {
          data.message = '';
          data.iframeFlag = 'Y';
          data.streamUrl = result.body.data.phenixEmbedUrl;
        }
      }
      console.log(data);
      dispatch(setRaceStreamData(data));
    }
  };
  useEffect(() => {
    if (props.venueId) getStreamDetails();
  }, [props.venueId]);

  const getUserWalletInfo = async () => {
    const response: any = await AuthService.getMyWallets();
    if (response.status && response.data.length > 0) {
      setWalletBal(response.data[0]?.balance);
      dispatch(setSelectedUserWallet(response.data[0]?.balance));
    }
  };

  useEffect(() => {
    getUserWalletInfo();
  }, []);

  useEffect(() => {
    if (raceStreamData) {
      console.log('RACE STREAM CHANGE====', raceStreamData);
      if (
        raceStreamData.iframeFlag === 'N' &&
        raceStreamData.player === 'videojs'
      ) {
        const source = {
          sources: [
            { src: raceStreamData.streamUrl, type: 'application/x-mpegURL' },
          ],
        };
        setPlayer({ ...player, ...source });
        dispatch(setRaceStreamData(raceStreamData));
      }
      setStreamObj(raceStreamData);
    }
  }, [raceStreamData]);
  return (
    <>
      <StyledVideoContainer>
        <StyledVideoView>
          {walletBal > 0 && (
            <div className='videoview 1'>
              {streamObj.iframeFlag === 'Y' &&
              (streamObj.message === undefined || streamObj.message === '') ? (
                <Iframe
                  venueCode={streamObj.venueCode}
                  streamUrl={streamObj.streamUrl}
                />
              ) : streamObj.iframeFlag === 'N' &&
                streamObj.player === 'videojs' ? (
                <VideoJsPlayer {...player} />
              ) : streamObj.message !== '' ? (
                <div className='messageview'>
                  {streamObj.message}{' '}
                  <p className='videovenunamerace'>{streamObj.venueCode}</p>{' '}
                </div>
              ) : streamObj.message === '' ? (
                <div className='messageview'>
                  THE LIVE STREAM WILL BE AVAILABLE SOON
                  {/* LIVE STREAM WILL BE AVAILABLE WHEN THE HORSES ARE AT THE POST{' '} */}
                  <p className='videovenunamerace'>{streamObj.venueCode}</p>{' '}
                </div>
              ) : (
                <div className='messageview'>
                  {' '}
                  RACE FINISHED{' '}
                  <p className='videovenunamerace'>
                    {streamObj.venueCode}
                  </p>{' '}
                </div>
              )}
            </div>
          )}
          {walletBal === 0 && (
            <div className='messageview'>
              PLEASE ADD CREDITS TO WALLET TO WATCH LIVE STREAM
              <p className='videovenunamerace'>{streamObj.venueCode}</p>{' '}
            </div>
          )}
        </StyledVideoView>
      </StyledVideoContainer>
    </>
  );
};

export default RaceVideo;
