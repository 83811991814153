import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Button, Grid, TextField } from '@mui/material';

const amounts = [10, 20, 30, 40];

const Pricing = ({ onChangePrice }: { onChangePrice: Function }) => {
  const [selectedValue, setSelectedValue] = React.useState(10);

  const updatePrice = (val: number) => {
    onChangePrice(val);
    setSelectedValue(val);
  };

  const changeValueUpdate = (event: any) => {
    let val = event.target.value;
    if (val === "") val = 0;
    else val = event.target.value.replace(/^0+/, '');;
    setSelectedValue(val);
    onChangePrice(parseInt(val));
  }

  const resetPrice = () => {
    setSelectedValue(0);
    onChangePrice(0);
  };

  return (
    <>
      <Grid className='betamountenter' item sx={{ border: '0px' }}>
        <TextField
          required
          id="outlined-required"
          defaultValue="0"
          fullWidth
          value={selectedValue}
          type="number"
          onChange={changeValueUpdate}
        />
      </Grid>
      <Grid className='pricingbtnview' >
        <Button onClick={resetPrice} variant="contained">Reset</Button>
      </Grid>

      <ToggleButtonGroup
        color="primary"
        value={selectedValue}
        exclusive
        aria-label="Platform"
        className='amountbtngroup'
      >
        {amounts && amounts.map((item: number) => (<ToggleButton onClick={() => updatePrice(item)} key={item} value={item}>{item}</ToggleButton>))}
      </ToggleButtonGroup>
    </>
  );

}
export default Pricing;
