import { MessageSvg } from "@/shared/svg";
import React from "react";
import { Link } from "react-router-dom";
import { Card, Grid } from "@mui/material";
// import { StyledInfoBox, StyledTextArea } from "../../../pages/faqs/style";
import { StyledInfoBox, StyledTextArea } from "../mainstyle"

const Chat: React.FC<any> = ({ message = "" }: { message?: string }) => {
    return (
        
            <StyledInfoBox>
            <Grid container spacing={3}>
                <Grid item >
                    <MessageSvg />
                </Grid>
                <Grid item xs>
                  <StyledTextArea>
                    <p className='chat_with_text'>Chat with Us</p>
                    <Link to=''>
                        <span>Coming soon!</span>
                    </Link>
                    </StyledTextArea>
                </Grid>
            </Grid>
            </StyledInfoBox>
      
    );
};

export default Chat;
