import { useAppDispatch } from '@/hooks/useAppDispatch';
import styled from '@/shared/styled';
import {
  setIsAuthenticatedData,
  setIsKYCPopup,
  setIsKYCStatus,
  setKYCRejectedReason,
  setKYCRejectedRemarks,
  setUserData,
} from '@/redux/slices/login';
import { updateModelAttributes } from '@/services/SocketModelUpdate';
import Sockets from '@/services/SocketService';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorWarningiconSvg, CheckBoxSvg } from '@/shared/svg';
import AuthService, { Auth } from '@/services/AuthService';
import CookieStorageService from '@/services/CookieStorageService';
import { setCookieData } from '@/utils/cookie';

export const StyledAuthMessageView = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
`;

export const StyledAuthMessage = styled.div`
  color: #f31415;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  position: relative;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledAuthsuccessMessage = styled.div`
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  position: relative;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AuthPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const navigate = useNavigate();

  const [message, setMessage] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);

  const BASE_URL = process.env.REACT_APP_AUTHENTICATION_API_URL;

  const getGameName = async () => {
    let params: any = {};
    const header = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    CookieStorageService.deleteCookieData(AuthService.getAuthKey());
    setMessage('Please wait while we are checking your account');

    setTimeout(async function () {
      try {
        const resp = await axios.get(`${BASE_URL}user`, { ...header, params });
        console.log(resp, 'resp');
        if (resp.status === 200) {
          setCookieData(Auth.AUTH_ACCESS_TOKEN, token, 1);

          Sockets.setSocketForUser(resp.data.id, updateModelAttributes);
          dispatch(setUserData(resp.data));
          dispatch(setIsAuthenticatedData(true));
          dispatch(setIsKYCPopup(resp.data.kycPopupClose));
          if (resp.data.kycVerified === undefined) {
            dispatch(setIsKYCStatus('created'));
          } else {
            dispatch(setIsKYCStatus(resp.data.kycVerified));
            if (
              resp.data.kycVerified !== undefined &&
              resp.data.kycVerified === 'DECLINED'
            ) {
              dispatch(setKYCRejectedRemarks(resp.data.kycDeclinedRemarks));
              dispatch(setKYCRejectedReason(resp.data.kycDeclinedReason));
            }
          }
          // location.reload();
          navigate('/');
        }
      } catch (err) {
        // debugger;
        console.log(err, 'err');
        setMessage('Sorry, your token is invalid');
        setIsNotValid(true);
      }
    }, 5000);
  };

  useEffect(() => {
    getGameName();
  }, [token]);

  return (
    <>
      {isNotValid && (
        <StyledAuthMessageView>
          <StyledAuthMessage>
            <ErrorWarningiconSvg /> {message}
          </StyledAuthMessage>
        </StyledAuthMessageView>
      )}

      {!isNotValid && (
        <StyledAuthMessageView>
          <StyledAuthsuccessMessage>
            <CheckBoxSvg /> {message}
          </StyledAuthsuccessMessage>
        </StyledAuthMessageView>
      )}
    </>
  );
};

export default AuthPage;
