import React from "react";
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from "../../../components/Custom/mainstyle"
import AccordionSection from "../../../components/Custom/AccordionSection";
import PageHead from "../components/PageHead";

const listDetails = [
    { title: "How do I deposit funds into my account?", desc: `
    <ul>
    <li><b>Step 1 : </b> Log in to your account using your mobile number and password.</li>
    <li><b>Step 2 : </b> Click the "Deposit" button on the top right corner of the page.</li>
    <li><b>Step 3 : </b> Enter the desired deposit amount.</li>
    <li><b>Step 4 : </b> You will be redirected to the GCash page. Confirm your deposit using your GCash account and complete the transaction successfully.</li>
    <li><b>Step 5 : </b> Upon completion, the amount should automatically be credited into your ArionPlay wallet.</li>
    </ul>` },
    { title: "Can I withdraw even though I'm not KYC-verified?", desc: "No. KYC-verification is needed to withdraw funds. Please complete that process first." },
    { title: "I just deposited and tried to withdraw, but I was restricted from withdrawing from my ArionPlay wallet. Why?", desc: `<h4>As part of our security protocols against fraud and money laundering, the following protocols are in place:</h4>
    <ul>
    <li>1. If Player withdraws today, they will have to wait three (3) banking days excluding weekends starting the day after the deposit has been made.</li>
    <li>Example 1: Player withdraws on Feb 21 (Wed). This means Player will not be able to withdraw on Feb 22 (Thurs), Feb 23 (Fri), and Feb 26 (Mon). Player will only be able to withdraw on Feb 27 (Tues).</li>
    <li>Example 2: Player withdraws on Mar 4 (Mon). Player will not be able to withdraw Mar 5 (Tues), Mar 6 (Wed), Mar 7 (Thurs). Player will be able to withdraw Mar 8 (Fri)</li>
    <li>2. The period of three (3) banking days will be triggered from the date of the latest deposit.</li>
    </ul>` },
    
];

const FaqDepositsWithdrawals: React.FC = () => {
    return (
        <StyledInfoBoxRight>
           <PageHead title="Deposits, Withdrawals" />
          <AccordionSection list={listDetails} />
        </StyledInfoBoxRight>
    );
};

export default FaqDepositsWithdrawals;
