import React from 'react';
import { Link } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
// Components
import BorderCard from './BorderCard';
// SVG
import {
  EGamesViewSvg,
  ArrowRightSvg,
  WEGamesSvg,
  WEGamesViewSvg,
  RealTimeGamViewsSvg,
} from '@/shared/svg';
import { flex } from 'styled-system';

type Props = {
  providerId: string,
};

const GameCardViewAll = ({ providerId }: Props) => {

  let viewAllIcon: null | React.ReactElement = null;
  switch (providerId) {
    case 'we':
      viewAllIcon = <WEGamesViewSvg />;
      break;
    case 'evo':
      viewAllIcon = <EGamesViewSvg />;
      break;
    case 'rtg':
      viewAllIcon = <RealTimeGamViewsSvg />;
      break;
  }

  return (
    <BorderCard>
      <CardActionArea
        component={Link}
        to={`games/${providerId}`}
        style={{
          height: '100%',
          width: '100%',
          color: '#dc6426',
          display: 'flex',
          padding: 8,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {viewAllIcon}
        <Box sx={{ p: '8px', display: 'flex', justifyContent:'center', alignItems:'center', gap:'5px', marginTop:'5px' }}>
          <Typography variant="button" component="span">View all</Typography>
          <ArrowRightSvg />
        </Box>
      </CardActionArea>
    </BorderCard>
  );
};

export default GameCardViewAll;
