import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Accordion(theme: Theme) {

  return {
    MuiAccordion: {
        styleOverrides: {
            root: {
                backgroundImage: 'none',
                backgroundColor: `${theme.palette.background.container}`,
                boxShadow: 'none',
                borderRadius: "10px",
                border:`solid 1px ${theme.palette.grey[700]}`,
                marginBottom: '15px',
                '& .MuiButtonBase-root.Mui-expanded':{
                    color: `${theme.palette.primary.main}`,
                    border:`solid 1px ${theme.palette.primary.main}`,
                    borderBottom: 'none',
                    borderRadius: "10px 10px 0px 0px",
                },
            },
        },
    },
    MuiAccordionSummary: {
        styleOverrides: {
            root: {
                color:`${theme.palette.grey[400]}`,
                '& .MuiAccordionSummary-expandIconWrapper':{
                    transform: 'rotate(90deg)',
                },
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded':{
                    transform: 'rotate(-90deg)',
                },
                '& .Mui-expanded':{
                    color: `${theme.palette.primary.main}`,
                },
            },
        },
    },
    MuiAccordionDetails: {
        styleOverrides: {
            root: {
                paddingTop: '0px',
                border:`solid 1px ${theme.palette.primary.main}`,
                borderTop: 'none',
                borderRadius: "0px 0px 10px 10px",
                '& h4':{
                    fontSize: '13px',
                    color: '#A3ADC2',
                },
                '& p':{
                    color: '#A3ADC2',
                    fontSize: '13px',
                    fontFamily: `google_sansregular, Public Sans, sans-serif`,
                    paddingTop: '10px',
                },
                '& ol':{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    '& li':{
                        color: '#A3ADC2',
                        fontSize: '13px',
                        lineHeight: '20px',
                        fontFamily: `google_sansregular, Public Sans, sans-serif`,
                    }
                },
                '& ul':{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    '& li':{
                        color: '#A3ADC2',
                        fontSize: '13px',
                        lineHeight: '20px',
                        marginBottom: '6px',
                    }
                },
                '& ul.f-regular':{
                    '& li':{
                        fontFamily: `google_sansregular, Public Sans, sans-serif`,
                    }
                }
            },
        },
    }
  };
}
