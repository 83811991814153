import { ArrowRightSvg } from "@/shared/svg";
import React from "react";
import { Card } from '@mui/material';
import { Link } from "react-router-dom";
import {styled } from '@mui/material/styles';
import { themeGet } from '@/shared/styled';
// import { StyledPageTextView } from "../style";
import { StyledPageTextView } from "../../../components/Custom/mainstyle"

const CardDisplay = styled(Card)<any>(({ theme }) => ({
    color: themeGet('colors.homebannertxtcolor').toString(),
    backgroundColor:'inherit !important',
    
    minHeight:'212px',
    // '& a': {
    //   padding:'20px',
    //   '&: p': {
    //     fontsize:'16px',
    //   },
    //   '&:hover h4': {
    //     color: 'red'
    //   },
    //   '&:hover p': {
    //     color: '#ff0'
    //   },
    //   '&.Mui-active': {
    //     boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    //   },
    // },
  }));
  
  const TopicItem = ({ item }: { item: any }) => {
    return (
      <StyledPageTextView>
      <CardDisplay>
        <Link to={item.url} className='active'>
          <div className='iconsvg'>
            {item.icon}
          </div>
          <h4 dangerouslySetInnerHTML={{ __html: item.title }}></h4>
          <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
          <div className='arrow'>
            <ArrowRightSvg />
          </div>
        </Link>
        </CardDisplay>
        </StyledPageTextView>
    );
  }

  export default TopicItem;