import { FormGroup, Button, ButtonSpinner } from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useState } from 'react';
import {Grid} from '@mui/material/';
import { StyledWrap, StyledButtonContainer, StyledSteps } from './style';
import AuthService from '@/services/AuthService';
import { setIsKYCPopup } from '@/redux/slices/login';
import { useAppDispatch } from '@/hooks/useAppDispatch';

interface ChildProps {
  parentModalHandlerFunction: any;
  closePopup(arg0: string): void;
}

const KYCVerified = (props: ChildProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<AccountCForm>();

  const onSubmit: SubmitHandler<AccountCForm> = async data => {
    const res = { kycPopupClose: true };
    const response = await AuthService.updateUserProfile(res);
    dispatch(setIsKYCPopup(true));
    props.closePopup('close');
    setLoading(true);
  };

  return (
    <StyledWrap>
      <form action='#' onSubmit={handleSubmit(onSubmit)} noValidate>
        <StyledSteps>
          <img
            src={process.env.REACT_APP_THEME_LOGO_MOBILE}
            alt=''
          />
          <h4>Welcome to {process.env.REACT_APP_SITE_TITLE}!</h4>
        </StyledSteps>
        <div>
          <FormGroup className='loginform'>
            <div className='termsandcondition'>
              <h5>
                Your KYC has been verified!
                <br />
                We hope you enjoy your gaming experience!
              </h5>
            </div>
          </FormGroup>
        </div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className='nomargin'
        >
          <StyledButtonContainer>

          <Button
            type='submit'
            color='brand2'
            className='signupbtn'
            data-testid='submit'
          >
            {loading ? <ButtonSpinner /> : null}Play now!
          </Button>
          </StyledButtonContainer>
        </Grid>
      </form>
    </StyledWrap>
  );
};

export default KYCVerified;
