// @ts-nocheck
import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';
import './index.styles.css';
// React
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'; //!!!
// Lib
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
// Redux (Remove later)
import { Provider } from 'react-redux';
import { store } from './config/store';
import ThemeProvider from './redux/providers/theme-provider';
// App
import App from './App';
// import * as serviceWorker from './serviceWorkerRegistration';

// ----------------------------------------------------------------------

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider>
            <App />
            <ToastContainer style={{ width: '500px' }} />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  );
}

//serviceWorker.register();
