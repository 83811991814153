import React from "react";
import styled, {themeGet} from '@/shared/styled';
import { ArrowLeftSvg } from "@/shared/svg";
import { Link } from "react-router-dom";

export const H3 = styled.h3`
    border-left: 1px solid #2F3847;
    font-size: 20px;
    color: #C2C9D6;
    padding-left: 20px;
    margin-left: 20px;
    font-family: ${themeGet('fonts.googleSansMedium')};
`;
export const StyledpagHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom:20px;
  
 }
`;

const PageHead: React.FC<any> = ({title}: {title: string}) => {
    return (
        <StyledpagHeader>
                 <Link to='/faqs'><ArrowLeftSvg/></Link> <H3>{title}</H3>
        </StyledpagHeader>
        
    );
};

export default PageHead;
