import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';
// SVG

import { ArionPlayLogoSVG } from '@/shared/svg';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{
      color: '#ffffff',
      width: 240,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...sx
    }}>
      {
        process.env.REACT_APP_THEME === 'fairplay' ?
        <img height="40" src={process.env.REACT_APP_THEME_LOGO} alt='' />:
        <ArionPlayLogoSVG />
      }
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
