import classnames from 'clsx';
import { StyledBody } from './style';
import { IBody } from './types';
import React from 'react';

const ModalBody = ({ className, children, ...restProps }: IBody) => {
  let isMobile = window.screen.width < 1200;
  return (
    <StyledBody 
      className={classnames(className, 'modal-body')
    } {...restProps}>
      {children}
    </StyledBody>
  );
};
export default ModalBody;
