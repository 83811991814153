import { QuestionSvg, ArrowRightSvg, HeadPhoneSvg, MessageSvg, SearchIconSvg } from "@/shared/svg";
import React from "react";
// import { StyledInputHolder, StyledSearchHolder } from "../../pages/faqs/style";
import { StyledInputHolder, StyledSearchHolder} from "../Custom/mainstyle"

const SearchBar: React.FC = () => {
let isMobile = window.screen.width < 1200;

    return (
        <div style={{position:'relative'}}>
            <img
                style={isMobile? {height:'40vw'}:{}}
                src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/faq-banner-img.png`}
                alt=''
            />
            <StyledSearchHolder>
                <h4>How can we help ?</h4>
                <StyledInputHolder>
                    <input
                        type='text'
                        placeholder='Search for a topic or question...'
                    />
                    <button type='button'>
                        <SearchIconSvg />
                    </button>
                </StyledInputHolder>
            </StyledSearchHolder>
        </div>
    );
};

export default SearchBar;
