import styled, { device, themeGet } from '@/shared/styled';

export const StyledContainerFluid = styled.div`
  h3 {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 22px;

    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 20px;
  }
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  ${device.MobToMd} {
    padding-top: 0px;
    padding-bottom: 0px;
    h3 {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .evolutiongames {
    padding: 24px;
  }
  .livegames {
    padding: 24px;
  }
  .toteodds {
    padding: 24px;
  }
  .fixedodds {
    padding: 24px;
  }
  .betruletext {
    color: ${themeGet('colors.copyrighttextcolor')};
  }
  .static_content {
    h2 {
      color: ${themeGet('colors.copyrighttextcolor')};
      font-size: 20px;
      font-family: ${themeGet('fonts.googleSansMedium')};
      margin-bottom: 16px;
    }
    p {
      font-size: 13px;
      margin-bottom: 6px;
      color: ${themeGet('colors.copyrighttextcolor')};
    }
  }
  .betrulestabs {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${themeGet('colors.pagecontainerborder')};
    margin-bottom: 10px;
    ul {
      display: flex;
      padding-left: 10px;
      li {
        list-style: none;
        margin: 14px 0px;
        border-right: 1px solid ${themeGet('colors.pagecontainerborder')};
        a {
          color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
          font-size: 14px;
          font-family: ${themeGet('fonts.googleSansBold')};
          list-style: none;
          padding: 0px 20px;
          &.active {
            color: ${themeGet('colors.btnfontcolor')};
          }
        }
      }
      li:last-child {
        border-right: 0px;
      }
    }
  }
`;

export const StyledContainerPageView = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0px;
  ${device.TabToLg} {
    flex-direction: column-reverse;
  }
`;

export const StyledPageLeft = styled.div`
  display: flex;
  padding-right: 20px;
  width: 366px;
  flex-direction: column;
  ${device.TabToLg} {
    width: 100%;
    padding-right: 0px;
  }
`;

export const StyledPageRight = styled.div`
  display: flex;
  width: 100%;
  .evolutiongames {
    padding: 24px;
  }
  .livegames {
    padding: 24px;
  }
  .toteodds {
    padding: 24px;
  }
  .fixedodds {
    padding: 24px;
  }
  ${device.TabToLg} {
    width: 100%;
  }
`;

export const StyledInfoBoxRight = styled.div`
  background: ${themeGet('colors.pageinfotxtcolor')};
  border: 1px solid ${themeGet('colors.pageinfoborder')};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 24px;
  position: relative;
  ul {
    list-style: none;
  }
  h6 {
    display: flex;
    width: 100%;
    margin: 22px 0px;
    color: #517095;
    letter-spacing: 2.76px;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 12px;
  }
`;

export const StyledInfoBox = styled.div`
  background: ${themeGet('colors.pageinfotxtcolor')};
  border: 1px solid ${themeGet('colors.pageinfoborder')};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  width: 100%;
  span {
    width: 55px;
  }
  svg {
  }
  ${device.TabToLg} {
    padding: 16px;
  }
`;

export const StyledPageTextContainer = styled.div`
  background: ${themeGet('colors.pagecontainerbg')};
  border: 1px solid ${themeGet('colors.pagecontainerborder')};
  border-radius: 10px;
  padding: 24px;
  width: 100%;
  h5 {
    color: #c2c9d6;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 30px;
    ${device.TabToLg} {
      margin-bottom: 20px;
    }
  }
  ${device.TabToLg} {
    padding: 16px 16px 0px 16px;
    margin-bottom: 20px;
  }
  .contactform {
    .row {
      margin-bottom: 20px;
      .no_padding {
        ${device.TabToLg} {
          margin-bottom: 20px;
        }
      }
    }
    label {
      color: #a3adc2;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 5px;
    }
    input {
      background: ${themeGet('colors.pagecontainerinput')};
      //border: 1px solid ${themeGet('colors.pagecontainerinputborder')};
      border-radius: 6px;
      color: #a3adc2;
      height: 42px;
      padding: 0.375rem 0.75rem;
      &:focus {
        border: 1px solid #57626f;
      }
    }
    textarea {
      background: ${themeGet('colors.pagecontainerinput')};
      //border: 1px solid ${themeGet('colors.pagecontainerinputborder')};
      border-radius: 6px;
      color: #a3adc2;
      min-height: 120px;
      resize: none;
      padding: 0.375rem 0.75rem;
      &:focus {
        border: 1px solid #57626f;
      }
    }
  }
  .send-btn {
    background: ${themeGet('colors.mainborder')};
    border-radius: 6px;
    color: ${themeGet('colors.pagefontcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 13px;
    height: 41px;
    width: 147px;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 35px;
    margin-bottom: 10px;
    svg path {
      stroke: ${themeGet('colors.pagefontcolor')};
    }
  }
  .textareamessage {
    div {
      top: 120px;
    }
  }
`;

export const StyledPageBanner = styled.div`
  width: 100%;
  position: relative;
  img {
    border-radius: 10px;
    float: left;
    //margin-bottom: 25px;
    width: 100%;
    ${device.TabToLg} {
      height: 100%;
      object-fit: cover;
    }
  }
  ${device.TabToLg} {
    height: 140px;
  }
`;

export const StyledSearchHolder = styled.div`
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  h4 {
    color: #fff;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 24px;
    line-height: normal;
    text-align: center;
    margin: 45px 0px 12px 0px;
    ${device.TabToLg} {
      margin-top: 30px;
    }
  }
`;

export const StyledInputHolder = styled.div`
  width: 580px;
  height: 44px;
  border-radius: 6px;
  border: 2px solid #333c46;
  background: #060e17;
  margin: 0px auto;
  overflow: hidden;
  position: relative;
  input {
    color: #a2c1b8;
    border: none;
    background: none;
    width: 100%;
    height: 42px;
    padding: 0px 45px 0px 22px;
  }
  button {
    width: 44px;
    height: 42px;
    position: absolute;
    right: 0px;
    top: 0px;
    background: ${themeGet('colors.homebannertxtcolor')};
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: #fff;
      }
    }
  }
  ${device.TabToLg} {
    width: 100%;
  }
`;
export const StyledAccordianHeading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px 13px 14px 15px;
  border-radius: 10px;
  border: 1px solid #2a323d;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
  &.open {
    margin-bottom: 0px;
    border-radius: 10px 10px 0px 0px;
    border-bottom: none;
    border-color: rgba(243, 114, 44, 0.5);
    h5 {
      color: ${themeGet('colors.homebannertxtcolor')};
    }
    svg {
      path {
        fill: ${themeGet('colors.homebannertxtcolor')};
      }
    }
  }
  h5 {
    color: #c2c9d6;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
    ${device.TabToLg} {
      font-size: 12px;
      padding-right: 25px;
    }
  }
  button {
    width: 34px;
    height: 24px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 50px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.04);
    background: linear-gradient(
      129deg,
      rgba(146, 157, 169, 0.06) 0%,
      rgba(107, 120, 135, 0.16) 53.65%,
      rgba(76, 88, 102, 0.3) 100%
    );
  }
  span {
    width: 33px;
    height: 44px;
    position: absolute;
    right: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const StyledPageTextViewBet = styled.div`
  width: 100%;
  display: flex;
  padding: 25px;
  .static_content {
    h2 {
      color: ${themeGet('colors.copyrighttextcolor')};
      font-size: 20px;
      font-family: ${themeGet('fonts.googleSansMedium')};
      margin-bottom: 16px;
    }
    p {
      font-size: 13px;
      margin-bottom: 6px;
      color: ${themeGet('colors.copyrighttextcolor')};
    }
  }
  .evolutiongames {
    padding: 24px;
  }
  ${device.TabToLg} {
    padding: 20px 0px 0px 0px;
  }
  ${device.TabToLg} {
    flex-direction: column;
  }
`;

export const StyledPageTextView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background:none;
  h5 {
    color: #c2c9d6;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
    ${device.TabToLg} {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 12px;
    }
  }
  p {
    color: #a3adc2;
    font-size: 14px;
    line-height: 22px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    ${device.TabToLg} {
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 12px;
    }
  }
  .weare_passio {
    font-family: ${themeGet('fonts.googleSansRegular')};
  } 
  
  h6{
    display: flex;
    width: 100%;
    margin:22px 0px;
    color: #517095;
    letter-spacing: 2.76px;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 12px;
  }
  a{
    background:${themeGet('colors.pagecontainerbg')};
    padding:20px;
    border-radius: 10px;
    border:1px solid ${themeGet('colors.pageinfoborder')};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color:${themeGet('colors.eventnumberdefaulttxtcolor')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    min-height: 211px;
    .iconsvg{
      svg{
        margin-bottom: 20px;
        path{
          stroke:#B5B5B5;
        }
      }
    }
    h4{
      font-size: 16px;
      color:${themeGet('colors.eventnumberdefaulttxtcolor')};
      font-family: ${themeGet('fonts.googleSansMedium')};
    }
    p{
      color: #A3ADC2;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansRegular')};
      margin-top:10px;
      margin-bottom: 6px;
      ${device.MobToMd}{
        br{
          display:none;
        }
      }
    }
    .arrow{
      svg{ 
        width: 24px;
        height: 24px;
        path{
          stroke:#517095;
        }
      }
    }
    &:hover{
      border:1px solid ${themeGet('colors.mainbackground')};
      h4{
        color:${themeGet('colors.mainbackground')};
      }
      .arrow{
        svg{ 
         path{
          stroke:${themeGet('colors.mainbackground')};
         }
        }
      }
    }
  ul{
    display: flex;
    gap:20px;
    margin-bottom:20px;
    ${device.MobToMd}{
      flex-direction: column;
      &:last-child{
        margin-bottom: 0px;
      }
    }
    li{
      display: flex;
      min-width: 253px;
      width: calc(100% / 3);
      min-height: 211px;
      ${device.MobToMd}{
        min-width: initial;
        width: 100%;
        min-height: initial;
      }
      a{
        background:${themeGet('colors.pagecontainerbg')};
        padding:20px;
        border-radius: 10px;
        border:1px solid ${themeGet('colors.pageinfoborder')};
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color:${themeGet('colors.eventnumberdefaulttxtcolor')};
        font-family: ${themeGet('fonts.googleSansMedium')};
        .iconsvg{
          svg{
            margin-bottom: 20px;
            path{
              stroke:#B5B5B5;
            }
          }
        }
        h4{
          font-size: 16px;
          color:${themeGet('colors.eventnumberdefaulttxtcolor')};
          font-family: ${themeGet('fonts.googleSansMedium')};
        }
        p{
          color: #A3ADC2;
          font-size: 14px;
          font-family: ${themeGet('fonts.googleSansRegular')};
          margin-top:10px;
          margin-bottom: 6px;
          ${device.MobToMd}{
            br{
              display:none;
            }
          }
        }
        .arrow{
          svg{ 
            width: 24px;
            height: 24px;
            path{
              stroke:#517095;
            }
          }
        }
      // &.active{
      //   border:1px solid ${themeGet('colors.mainbackground')};
      //   h4{
      //     color:${themeGet('colors.mainbackground')};
      //   }
        
      //   .arrow{
      //     svg{ 
      //      path{
      //       stroke:${themeGet('colors.mainbackground')};
      //      }
      //     }
      //   }
      // }
      &:hover{
        border:1px solid ${themeGet('colors.mainbackground')};
        h4{
          color:${themeGet('colors.mainbackground')};
        }
        .arrow{
          svg{ 
           path{
            stroke:${themeGet('colors.mainbackground')};
           }
          }
        }
      }
      ${device.TabToLg}{
        
      }
    }
  }
   
`;

export const StyledAccordianContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px 13px 23px 15px;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #2b3935;
  margin-bottom: 15px;
  &.open {
    border-top: none;
    border-color: rgba(243, 114, 44, 0.5);
  }
  &.close {
    display: none;
  }
  h6 {
    color: #b7cbc5;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 13px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 12px;
    ${device.TabToLg} {
      font-size: 12px;
    }
  }
  ol {
    margin-left: 16px;
    margin-bottom: 12px;
    li {
      color: #b7cbc5;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      ${device.TabToLg} {
        font-size: 12px;
      }
    }
  }
  p {
    color: #b7cbc5;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 12px;
    ${device.TabToLg} {
      font-size: 12px;
    }
  }
  button {
    display: inline-flex;
    padding: 8px 14px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.04);
    background: linear-gradient(
      129deg,
      rgba(146, 157, 169, 0.06) 0%,
      rgba(107, 120, 135, 0.16) 53.65%,
      rgba(76, 88, 102, 0.3) 100%
    );
    color: #a3adc2;
    font-size: 12px;
    width: 120px;
    height: 34px;
    margin-top: 6px;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
`;

export const StyledTextArea = styled.div`
  width: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  .chat_with_text {
    font-size: 16px !important;
    margin-bottom: 0 !important;
  }
  p {
    color: #c2c9d6;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 16px;
    ${device.TabToLg} {
      font-size: 20px;
      line-height: 26px;
    }
  }
  strong {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-size: 16px;
    line-height: 20px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-weight: 400;
  }
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    font-family: ${themeGet('fonts.googleSansRegular')};
    span {
      padding-right: 5px;
    }

    svg path {
      stroke: ${themeGet('colors.homebannertxtcolor')};
    }
  }
  small {
    color: #b5b5b5;
    width: 100%;
    margin-top: 0.25rem !important;
  }
`;

export const StyledSupportAvailable = styled.div`
  span {
    color: #a3adc2;
    float: left;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const StyledSupportContent = styled.div`
  align-items: center;
  border-radius: 10px 10px 0 0;
  border-top: 1px solid #2f3847;
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0;
  margin-top: 20px;
  ${device.TabToLg} {
    flex-direction: column;
    padding: 12px 0px 0px 0px;
    justify-content: flex-start;
    margin-top: 12px;
    align-items: flex-start;
  }
`;

export const StyledSupportInfo = styled.div`
  align-items: center;
  display: flex;
  font-family: ${themeGet('fonts.googleSansRegular')};
  span {
    align-items: center;
    display: flex;
  }
  a {
    min-height: auto;
    padding: 0px;
    border: 0px;
  }

  small {
    font-size: 16px;
    line-height: 24px;
    color: #c2c9d6;
    margin-left: 12px;
  }

  strong {
    border-left: 1px solid #4c4c4c;
    color: ${themeGet('colors.btnfontcolor')};
    margin-left: 10px;
    padding-left: 10px;
    a {
      color: ${themeGet('colors.mainbackground')};
    }
    ${device.TabToLg} {
      border-left: none;
      margin: 0px;
      padding: 10px 0px 5px 0px;
    }
  }
  ${device.TabToLg} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledPageTextViewpage = styled.div`
  h5 {
    color: #c2c9d6;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
    ${device.TabToLg} {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 12px;
    }
  }
  p {
    color: #a3adc2;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    ${device.TabToLg} {
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 12px;
    }
    a {
      color: #b5b5b5;
      font-size: 13px;
      text-decoration: underline;
      transition: color 0.3s;
    }
  }
  .weare_passio {
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  ul {
    margin-bottom: 16px;
    margin-left: 16px;
    li {
      list-style: disc;
      color: #b5b5b5;
      font-family: ${themeGet('fonts.googleSansRegular')};
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;
      ${device.TabToLg} {
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
`;

export const StyledPageTabContainer = styled.div`
display: flex;
width: 100%;
flex-direction: column;
ul{
  border-bottom:1px solid ${themeGet('colors.pagecontainerborder')};
  padding-left: 10px;
  ${device.TabToLg}{
    padding-left: 10px;
    display: flex;
    padding-right: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  li{
    list-style: none;
    margin: 14px 0px;
    border-right: 1px solid #2f3847;
    float: left;
    a{
      color: #C2C9D6;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansBold')};
      list-style: none;
      padding: 10px 20px;
      background: none;
      border: none;
      text-transform: capitalize;
    }
    &:last-child{
      border-right: 0px;
    }
  }
  .active a::before {
    content: '';
    border-bottom: 2px solid #DD631A;
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: -9px;
  }
  .active a {
    color: ${themeGet('colors.btnfontcolor')};
    position: relative;
  }
}
.nav-tabs{
  border-bottom:1px solid ${themeGet('colors.pagecontainerborder')};
  padding-left: 10px;
  .nav-item{
    list-style: none;
    margin: 14px 0px;
    border-right: 1px solid #2f3847;
    flex: inherit;
    .nav-link{
      color: #C2C9D6;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansBold')};
      list-style: none;
      padding: 0px 20px;
      background: none;
      border: none;
    }
    .nav-link.active{
      color: #dc6426;
      position: relative;
      &:before {
        content: '';
        border-bottom: 2px solid ${themeGet('colors.mainborder')};
        width: 100%;
        position: absolute;
        left: 0px;
        bottom: -13px;
    }
  }
}
`;

export const StyledPageTextViewTerms = styled.div`
  h5 {
    color: #c2c9d6;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
    ${device.TabToLg} {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 12px;
    }
  }
  p {
    color: #a3adc2;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    ${device.TabToLg} {
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 12px;
    }
  }
  .weare_passio {
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  ol {
    padding-left: 25px;
    h4 {
      font-size: 16px;
      color: #a4adc0;
      font-family: ${themeGet('fonts.googleSansRegular')};
    }
    li {
      font-size: 14px;
      margin-bottom: 8px;
      color: #a4adc0;
      font-family: ${themeGet('fonts.googleSansRegular')};
    }
  }
  ul {
    padding-left: 25px;
    li {
      font-size: 14px;
      margin-bottom: 8px;
      color: #a4adc0;
      font-family: ${themeGet('fonts.googleSansRegular')};
    }
  }
  ${device.MobToMd} {
    padding-top: 0px;
    padding-bottom: 0px;
    h3 {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

export const StyledPagebetrule = styled.div`
display: flex;
width: 100%;
flex-direction: column;
.livegames{padding: 24px;}
ul{
  border-bottom:1px solid ${themeGet('colors.pagecontainerborder')};
  padding-left: 10px;
  ${device.TabToLg}{
    padding-left: 10px;
    display: flex;
    padding-right: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  li{
    list-style: none;
    margin: 14px 0px;
    border-right: 1px solid #2f3847;
    float: left;
    a{
      color: #C2C9D6;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansBold')};
      list-style: none;
      padding: 10px 20px;
      background: none;
      border: none;
      text-transform: capitalize;
    }
    &:last-child{
      border-right: 0px;
    }
  }
  .active a::before {
    content: '';
    border-bottom: 2px solid #DD631A;
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: -9px;
  }
  .active a {
    color: ${themeGet('colors.btnfontcolor')};
    position: relative;
  }
}
.nav-tabs{
  border-bottom:1px solid ${themeGet('colors.pagecontainerborder')};
  padding-left: 10px;
  .nav-item{
    list-style: none;
    margin: 14px 0px;
    border-right: 1px solid #2f3847;
    flex: inherit;
    .nav-link{
      color: #C2C9D6;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansBold')};
      list-style: none;
      padding: 0px 20px;
      background: none;
      border: none;
    }
    .nav-link.active{
      color: #dc6426;
      position: relative;
      &:before {
        content: '';
        border-bottom: 2px solid ${themeGet('colors.mainborder')};
        width: 100%;
        position: absolute;
        left: 0px;
        bottom: -13px;
    }
  }
}
`;


export const StyledLocation = styled.div` 
background: ${themeGet('colors.pagecontainerbg')};
  border: 1px solid ${themeGet('colors.pagecontainerborder')};
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  min-height:120px;

`;
