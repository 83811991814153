import { QuestionSvg, ArrowRightSvg, HeadPhoneSvg, MessageSvg, SearchIconSvg } from "@/shared/svg";
import React from "react";


const PageBanner: React.FC = () => {
    return (
        <div style={{position:'relative', marginBottom:'25px',}}>
            <img
                src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/about-banner-img.png`}
                alt=''
            />
        </div>
    );
};

export default PageBanner;
