import {
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ButtonSpinner,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { hasKey } from '@/shared/methods';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {Grid} from '@mui/material/';
import {
  StyledWrap,
  StyledTitle,
  StyledAlertMessage,
  StyledPhoneText,
  StyledPhoneNumber,
  StyledPersonalContainer,
} from './style';
import { AlertWarningiconSvg, MobileSvg, EditMobIconSvg, ErrorWarningiconSvg } from '@/shared/svg';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/hooks/useAppSelector';
import AuthService from '@/services/AuthService';
import { updateSignupData } from '@/redux/slices/register';
import {
  setUserData,
  setIsAuthenticatedData,
  setIsKYCStatus,
} from '@/redux/slices/login';

interface ChildProps {
  changeSteps: any;
}

const SignupotpForm = (props: ChildProps) => {
  const counterTime = 60;
  const dispatch = useDispatch();
  const [isNotValid, setIsNotValid] = useState(false);
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { regData } = useAppSelector(state => state.register);
  const [counter, setCounter] = useState(counterTime);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUser>();

  const onSubmit: SubmitHandler<IUser> = async data => {
    setLoading(true);
    const userObj = {
      ...regData,
      ...data,
    };

    userObj.confirm_pass = undefined;
    userObj.phone = `${userObj.countryCode}${userObj.phone}`;
    if (regData.countryCode === '+91') {
      userObj.countryCode = 'IN';
    } else if (regData.countryCode === '+44') {
      userObj.countryCode = 'GB';
    } else {
      userObj.countryCode = 'PH';
    }
    // userObj.address1 = userObj.currentAddress.address1;
    // userObj.address2 = userObj.currentAddress.address2;
    // userObj.postalCode = userObj.currentAddress.postalCode;
    // userObj.city = userObj.currentAddress.city;
    // delete userObj.currentAddress;
    const userInfo = await AuthService.register(userObj);
    if (userInfo.status) {
      setLoading(false);
      dispatch(updateSignupData(data));
      dispatch(setUserData(userInfo.data));
      dispatch(setIsAuthenticatedData(true));
      dispatch(setIsKYCStatus('created'));
      props.changeSteps('accountcreated');
    } else {
      setLoading(false);
      setIsNotValid(true);
      setMessage(userInfo.data);
    }
  };

  const resendSignupOtp = async () => {
    setLoading(true);
    setCounter(counterTime);
    //const signupObj = regData.;
    const signupObj = { phone: `${regData.countryCode}${regData.phone}` };

    const response = await AuthService.resendOTP(signupObj);
    if (response.status) {
      setLoading(false);
      console.log(token);
      setToken(response.data.token);
      dispatch(updateSignupData(response.data));
    } else {
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data);
    }
  };

  const gotoAccount = () => {
    props.changeSteps('account');
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <StyledWrap style={{width:'342px'}}>
      <StyledTitle>Verification</StyledTitle>
      <form
        className='loginform'
        action='#'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {isNotValid ? (
          <StyledAlertMessage>
            <ErrorWarningiconSvg /> {message}
          </StyledAlertMessage>
        ) : null}

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <StyledPhoneNumber>
              <p>Enter the OTP sent to</p>
              <h5>
                {regData.countryCode} {regData.phone}
                <Link to={''} onClick={() => gotoAccount()}>
                  <EditMobIconSvg />
                </Link>
              </h5>
            </StyledPhoneNumber>
            <FormGroup>
              <Label
                className='labelheight phoneotptext'
                display='block'
                mb='5px'
                htmlFor='email'
              >
                Phone OTP
              </Label>
              <div className='receivetimer'>
                {counter ? (
                  <div className='timerview'>{counter}</div>
                ) : (
                  <p className='resendotp'>
                    Didn’t receive OTP?&nbsp;
                    <Link to='' onClick={() => resendSignupOtp()}>
                      RESEND OTP
                    </Link>
                  </p>
                )}
              </div>
              <InputGroup>
                <InputGroupAddon dir='prepend'>
                  <InputGroupText>
                    <MobileSvg />
                  </InputGroupText>
                </InputGroupAddon>
                <StyledPhoneText>A-</StyledPhoneText>
                <Input
                  className='mobilenumber'
                  type='text'
                  id='phoneOtp'
                  name='phoneOtp'
                  placeholder='Enter Phone OTP'
                  feedbackText={errors?.phoneOtp?.message}
                  state={hasKey(errors, 'phoneOtp') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'phoneOtp')}
                  {...register('phoneOtp', {
                    required: 'OTP is required',
                    pattern: {
                      value: /^[0-9]{5}$/,
                      message: 'invalid OTP format',
                    },
                    maxLength: {
                      value: 6,
                      message: 'Minimum length is 6',
                    },
                  })}
                />
              </InputGroup>
            </FormGroup>
          </Grid>
        </Grid>
        {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <StyledPhoneNumber>
            <p>Enter the OTP sent to </p>
            <h5>
              {regData.email}
              <Link to=''>
                <EditMobIconSvg />
              </Link>
            </h5>
          </StyledPhoneNumber>
          <Grid item xs={12}>
            <FormGroup>
              <Label
                className='labelheight'
                display='block'
                mb='5px'
                htmlFor='email'
              >
                Email OTP
              </Label>
              <InputGroup>
                <InputGroupAddon dir='prepend'>
                  <InputGroupText>
                    <EmailSvg />
                  </InputGroupText>
                </InputGroupAddon>
                <StyledPhoneText>A-</StyledPhoneText>
                <Input
                  className='mobilenumber'
                  type='text'
                  id='emailOtp'
                  name='emailOtp'
                  placeholder='Enter Email OTP'
                  feedbackText={errors?.emailOtp?.message}
                  state={hasKey(errors, 'emailOtp') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'emailOtp')}
                  {...register('emailOtp', {
                    required: 'OTP is required',
                    pattern: {
                      value: /^[0-9]{5}$/,
                      message: 'invalid OTP format',
                    },
                    maxLength: {
                      value: 6,
                      message: 'Minimum length is 6',
                    },
                  })}
                />
              </InputGroup>
              <p className='resendotp'>
                Didn’t receive OTP?<Link to=''>RESEND OTP</Link>
              </p>
            </FormGroup>
          </Grid>
        </Grid> */}
        <StyledPersonalContainer>
          <Button type='submit' color='brand2' className='signin_btn'>
            {loading ? <ButtonSpinner /> : null}Verify Now
          </Button>
        </StyledPersonalContainer>

        {/* <StyledBottomText>
          If you have any questions?{' '}&nbsp;
          <Link className='flex' to={''} onClick={() => openInfoPopup()}>
            Contact Us
          </Link>
        </StyledBottomText> */}
      </form>
    </StyledWrap>
  );
};

export default SignupotpForm;
