import React from 'react';
import {CircularProgress} from '@mui/material/';

interface Props {
  className?: string;
}

const ButtonSpinner: React.FC<Props> = () => {
  return (
    <>
      <CircularProgress color="inherit" thickness={5} style={{padding: "10px"}}/>
    </>
  );
};

export default ButtonSpinner;
