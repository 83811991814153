import React from "react";
import styled, {themeGet} from '@/shared/styled';

export const H3 = styled.h3`
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 20px;
`;

const HeaderTitle: React.FC<any> = ({title}: {title: string}) => {
    return (
        <H3>{title}</H3>
    );
};

export default HeaderTitle;
