import axios from 'axios';
import { getFormDataHeader, getHeaderInfo } from '@/helpers/token';

const BASE_URL = process.env.REACT_APP_AUTHENTICATION_API_URL;

const handleResponse = (response: any) => {
  if (response.status === 401) {
    console.log(response, '===RESPONSE');
    // LocalStorageService.removeItem(AuthService.getAuthKey());
    // history.push('/v1');
    // window.location.reload();
  }
  if (response.data.status !== 'OK') {
    return response.data;
  }
  console.log(response, '===RESPONSE');
  // debugger;
  return response;
};

export const post = async function (url: string, body: any) {
  const header = await getHeaderInfo();
  try {
    const resp = await axios.post(url, body, header);
    return handleResponse(resp);
  } catch (err) {
    return handleResponse(err.response);
  }
};

export const get = async function (url: any, params: any = {}) {
  const header = await getHeaderInfo();
  try {
    const resp = await axios.get(url, { ...header, params });
    return handleResponse(resp);
  } catch (err) {
    throw handleResponse(err.response);
  }
};

export const put = async function (url: string, body: any) {
  const header = await getHeaderInfo();
  try {
    const resp = await axios.put(BASE_URL + url, body, header);

    return handleResponse(resp);
  } catch (err) {
    throw handleResponse(err.response);
  }
};

export const deleteApi = async function (url: any) {
  const header = await getHeaderInfo();
  try {
    const resp = await axios.delete(BASE_URL + url, header);

    return handleResponse(resp);
  } catch (err) {
    throw handleResponse(err.response);
  }
};

export const postImage = async function (url: string, body: any) {
  const header = await getFormDataHeader();
  const formData = new FormData();
  formData.append('file', body);
  try {
    const resp = await axios.put(BASE_URL + url, formData, header);
    return handleResponse(resp);
  } catch (err) {
    throw handleResponse(err.response);
  }
};
