import styled, { themeGet } from '@/shared/styled';

export const StyledSliderContainer = styled.div`
  width: 100vw;
  position: relative;
  height: 37.5vw;

  .arion-logo{
    position: absolute;
    top: 2.5vw;
    left: 2.5vw;
    with: 7.5vw;
    height: 2.5vw;
    z-index: 2;
  }

  .react-multi-carousel-dot{
    button{
      background-color: ${themeGet('colors.copyrighttextcolor')};  
      border: none;
      width: 1.5vw;
      height: 1.5vw;
      margin: 2vw .75vw;
    }
  }
  .react-multi-carousel-dot--active{
    button{
      background-color: ${themeGet('colors.homebannertxtcolor')};  
    }
  }
  .casino{
    animation-name: fadeAnimation;
  }

  @media (max-width: 1200px) {
    height: 145vw;

    .arion-logo{
      top: 4vw;
      left: 4vw;
      height: 8vw;
    }
  }   

`;
export const StyledImageSliderContainer = styled.div`
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  z-index: 1;

  .slide-images{
    object-fit: contain;
    width: 100vw;
  }
    
  .slide-button-1, .slide-button-2{
    position: absolute;
    width: 13vw;
    height: 4vw;
    border-radius: 50px;
    
    h5{
      color: ${themeGet('colors.bodybackground')};
      margin: 0px .5vw 0px 1.5vw;
      font-size: 1.5vw;
    }
    img{
      width: 3vw;
      height: 3vw;
    }

  }

  .slide-button-1{
    background-color: ${themeGet('colors.white')};
    top: 26vw;
    left: 10vw;
  }

  .slide-button-2{
    background-color: ${themeGet('colors.yellow')};
    top: 25vw;
    right: 24vw;
  }
  
  @media (max-width: 1200px) {
    .slide-button-1, .slide-button-2{
      position: absolute;
      width: 40vw;
      height: 12vw;
      border-radius: 50px;
      top: 85vw;
      left: 30vw;
      
      h5{
        color: ${themeGet('colors.bodybackground')};
        margin: 0px 3vw 0px 1.5vw;
        font-size: 4.5vw;
      }
      img{
        width: 7vw;
        height: 7vw;
      }
  
    }
  
    .slide-button-1{
      background-color: ${themeGet('colors.white')};
    }
  
    .slide-button-2{
      background-color: ${themeGet('colors.yellow')};
    }
  }
`;
export const StyledSliderDots = styled.div`
  width: 100vw;
  height: 2.5vw;
  position: absolute;
  bottom: 2vw;
  left: 0px;
  display: flex;

  div{
    margin: auto;
    display: flex;
    gap: 1.5vw;
    div{
      border-radius: 50%;
      height: 1.5vw;
      width: 1.5vw;
      background-color: ${themeGet('colors.copyrighttextcolor')};
    }
    .--active{
      background-color: ${themeGet('colors.mainbackground')};
    }
  }

  
  @media (max-width: 1200px) {
    bottom: 15vw;
    div{
      gap: 5.5vw;
      div{
        height: 5.5vw;
        width: 5.5vw;
      }
    }
  }
`