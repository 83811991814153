import React from 'react';
import { useNavigate } from 'react-router-dom';
// Mui
import Container from '@mui/material/Container';
//

import { useAppDispatch } from '@/hooks/useAppDispatch';

import Page from '@/components/Page';

import HeaderTitle from '@/components/Custom/HeaderTitle';
import WidgetIndex from '@/components/Custom/widgets';
import { Grid } from '@mui/material';
import Privacycontent from './privacycontent';

const leftSideWidgets = [{comp: 'Contact', desc: `This Privacy Policy will help you better understand how we collect, use, and share your personal info.`}, {comp: 'Support'},];
const Privacypolicy = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <Page title="Privacy Policy" favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      <Container maxWidth={'lg'} style={{paddingLeft:'0px', paddingRight:'0px'}}>
      <HeaderTitle title="Privacy Policy" />
        <Grid container spacing={3} style={{}}>
          <Grid item style={{ width: "366px" }}>
            <WidgetIndex componentsList={leftSideWidgets} />
          </Grid>
          <Grid item xs style={{position:'relative'}}>
           <Privacycontent/>
          </Grid>
        </Grid>
      
      </Container>
    </Page>
  );
};

export default Privacypolicy;
