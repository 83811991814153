import React from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// Components
import SimpleSearch from './search/SimpleSearch';
// Models
import { GAME_PROVIDERS } from '../@types/models/GameModel';

type Props = {
  providerId?: string,
  displaySearch?: boolean,
  setSearchFilter?: (search: string) => void,
  availableSearchElements?: null|number,
};

const GameProviderHeader = ({ providerId, displaySearch, setSearchFilter, availableSearchElements }: Props) => {
  const gameProviderLabel = providerId ? GAME_PROVIDERS[providerId] : null;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      spacing={0}
      sx={{ gap: 1 }}
      style={{ paddingBottom: '1rem' }}
    >
      {gameProviderLabel ?
        <Typography
          variant="h5"
          style={{
            color: '#C2C9D6',
            fontFamily: 'google_sansbold ,sans-serif',
            fontSize: 16,
            paddingLeft: 0,
            display: 'flex',
            
          }}
        >
          {gameProviderLabel?.icon &&
            <span style={{ paddingRight: '0.5rem' }}>
              <gameProviderLabel.icon/>
            </span>
          }
          {gameProviderLabel?.name}
        </Typography>:
        <div></div>
      }
      {displaySearch &&
        <div style={{maxWidth: 240 }}>
          <SimpleSearch
            onFilter={(search: string) => setSearchFilter && setSearchFilter(search)}
            size={availableSearchElements}
          />
        </div>
      }
    </Stack>
  );
};

export default GameProviderHeader;
