import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type AccoordionType = {
  title: string;
  desc: string;
}

const AccordionSection: React.FC<any> = ({ list }: { list: any }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {list.map((item: AccoordionType, ind: number) => (
        <Accordion expanded={expanded === `panel${ind}`} onChange={handleChange(`panel${ind}`)} key={ind}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '100%', flexShrink: 0 }}>
                            {item.title}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography dangerouslySetInnerHTML={{ __html: item.desc }}>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}

export default AccordionSection;
