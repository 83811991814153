import { QuestionSvg, ArrowRightSvg, HeadPhoneSvg, MessageSvg, FaqSvg } from "@/shared/svg";
import React from "react";
import { Link } from "react-router-dom";
// import { StyledInfoBox, StyledTextArea } from "../../../pages/faqs/style";
import { StyledInfoBox, StyledTextArea } from "../mainstyle"

import { Card } from "@mui/material";

const LearnMore: React.FC<any> = ({ message = "" }: { message?: string }) => {
    return (
        
            <StyledInfoBox>
                <span>
                    <FaqSvg />
                </span>
                <StyledTextArea>
                    <p className='chat_with_text'>Learn more</p>
                    <Link to='/faqs'>
                        <span>FAQs & Help</span>
                    </Link>
                </StyledTextArea>
            </StyledInfoBox>
       
    );
};

export default LearnMore;
