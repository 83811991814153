/**
 * User Context
 *
 * The React Context for Employer Portal.
 * @link https://reactjs.org/docs/context.html
 */
import { createContext } from 'react';
import UserModel from '../@types/models/UserModel';
import GeoModel from '../@types/models/GeoModel';

export interface UserContextType {
  isAuthenticated: boolean;
  userModel: null | UserModel;
  geoModel: null | GeoModel;
}

const UserContext = createContext<UserContextType>({
  isAuthenticated: false,
  userModel: null,
  geoModel: null,
});
export default UserContext;
