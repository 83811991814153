// Mui
import Container from '@mui/material/Container';
import { Grid } from '@mui/material/';
import Page from '@/components/Page';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useEffect } from 'react';
import AuthService from '@/services/AuthService';
import HeaderTitle from '@/components/Custom/HeaderTitle';
import WidgetIndex from '@/components/Custom/widgets';
import Contactcontent from './contactcontent';

const leftSideWidgets = [{ comp: 'Support' }, { comp: 'Chat' }, { comp: 'Learnmore' },];
const Contactus = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ContactForm>();

  const onSubmit: SubmitHandler<ContactForm> = async data => {
    console.log(data);
  };

  const getUserDetails = async () => {
    const response = await AuthService.getUserData();
    setValue('firstName', response.data.firstName);
    setValue('lastName', response.data.lastName);
    setValue('phone', response.data.phone);
    setValue('email', response.data.email);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Page title="Contact Us" favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      <Container maxWidth={'lg'} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <HeaderTitle title="Contact Us" />
        <Grid container spacing={3} style={{}}>
          <Grid item style={{ width: "366px" }}>
            <WidgetIndex componentsList={leftSideWidgets} />
          </Grid>
          <Grid item xs style={{ position: 'relative' }}>
            <Contactcontent />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Contactus;
