import React from 'react';
import { useNavigate } from 'react-router-dom';
// Mui
import Container from '@mui/material/Container';
//
import { useAppDispatch } from '@/hooks/useAppDispatch';
import Page from '@/components/Page';
import HeaderTitle from '@/components/Custom/HeaderTitle';
import { Grid } from '@mui/material';
import WidgetIndex from '@/components/Custom/widgets';
import Aboutcontent from './aboutcontent';


const leftSideWidgets = [{comp: 'Contact', desc: `Welcome to ${process.env.REACT_APP_SITE_TITLE}, your trusted source for Entertainment and Betting events Worldwide.`}, {comp: 'Support'},];
const Aboutus = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <Page title="About Us" favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      <Container maxWidth={'lg'} style={{paddingLeft:'0px', paddingRight:'0px'}}>
        <HeaderTitle title="About Us" />
        <Grid container spacing={3} style={{}}>
          <Grid item style={{ width: "366px" }}>
            <WidgetIndex componentsList={leftSideWidgets} />
          </Grid>
          <Grid item xs style={{position:'relative'}}>
           <Aboutcontent/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Aboutus;
