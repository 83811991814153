import { FormGroup, Button, ButtonSpinner } from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { changeModule } from '@/redux/slices/module';
import { KycDeniedIconSvg } from '@/shared/svg';
import { StyledWrap, StyledButtonContainer, StyledSteps } from './style';
import { Grid } from '@mui/material';

interface ChildProps {
  parentModalHandlerFunction: any;
  closePopup(arg0: string): void;
}

const KYCReject = (props: ChildProps) => {
  const dispatch = useAppDispatch();
  const [reason, setReason] = useState('');
  const [remarks, setRemarks] = useState('');
  const [loading, setLoading] = useState(false);

  const { isKYCRejectedReason, isKYCRejectedRemarks } = useAppSelector(
    state => state.auth
  );
  const {
    handleSubmit,
    formState: { errors },
  } = useForm<AccountCForm>();

  const onSubmit: SubmitHandler<AccountCForm> = data => {
    console.log(data, errors);
    setLoading(true);
  };

  const showKYCForm = () => {
    props.closePopup('close');
    dispatch(changeModule('kyc'));
  };

  useEffect(() => {
    if (isKYCRejectedReason) setReason(isKYCRejectedReason);
  }, [isKYCRejectedReason]);

  useEffect(() => {
    if (isKYCRejectedRemarks) setRemarks(isKYCRejectedRemarks);
  }, [isKYCRejectedRemarks]);

  return (
    <StyledWrap>
      <form action='#' onSubmit={handleSubmit(onSubmit)} noValidate>
        <StyledSteps>
          {/* <img
            src={process.env.REACT_APP_THEME_LOGO_MOBILE}
            alt=''
          /> */}
          <KycDeniedIconSvg />
          <h4>KYC Verification unsuccessful!</h4>
        </StyledSteps>
        <Grid sx={{ mb:2 }}>
          <FormGroup className='loginform'>
            <div className='termsandcondition'>
              {/*<h5>
                Your KYC verification is denied for the following reasons 
              </h5>*/}
              <div className='reasondenied'>
                The reason your kyc is denied is listed here by the support
                person <br />
                <br />
                <span>{reason}</span>
                <br />
                <br />
                <span>{remarks}</span>
              </div>
            </div>
          </FormGroup>
        </Grid>
        <Grid sx={{ m:0 }}>
          <StyledButtonContainer>
            <Button
              type='submit'
              color='brand2'
              className='loginbtn'
              onClick={() => showKYCForm()}
            >
              {loading ? <ButtonSpinner /> : null}Re-submit KYC
            </Button>
          </StyledButtonContainer>
        </Grid>
      </form>
    </StyledWrap>
  );
};

export default KYCReject;
