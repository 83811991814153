import React from 'react';
// Mui
import Stack from '@mui/material/Stack';
// Components
import { Button } from '@/components';
import { LoginSvg, SignUpSvg } from '@/shared/svg';
import { StyledHeaderButtonGroup } from './style';

interface ChildProps {
  parentModalHandlerFunction: any;
}
export const PreLoginButtons = (props: ChildProps) => {
  return (
    <StyledHeaderButtonGroup>
      <Stack direction="row" flexWrap="nowrap" justifyContent="center" alignItems="center" spacing={1}>
          <Button
            onClick={() => props.parentModalHandlerFunction('login')}
            className='loginbtn'
          >
            {' '}
            <LoginSvg />
            Login{' '}
          </Button>
          <Button
            onClick={() => props.parentModalHandlerFunction('register')}
            className='signupbtn'
          >
            {' '}
            <SignUpSvg />
            Signup{' '}
          </Button>
      </Stack>
    </StyledHeaderButtonGroup>
  );
};
