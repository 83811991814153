import { FormGroup, Button } from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useState } from 'react';
//import { useAppDispatch } from '@/hooks/useAppDispatch';
import { StyledWrap, StyledButtonContainer, StyledSteps } from './style';
import { Grid } from '@mui/material';

// interface ChildProps {
//   parentModalHandlerFunction: any;
//   closePopup(arg0: string): void;
// }

//const PaymentReturn = (props: ChildProps) => {
const PaymentReturn = () => {
  //const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<AccountCForm>();

  const onSubmit: SubmitHandler<AccountCForm> = data => {
    console.log(loading, data, errors);
    setLoading(true);
  };

  return (
    <StyledWrap>
      <form action='#' onSubmit={handleSubmit(onSubmit)} noValidate>
        <StyledSteps>
          <h4>Payment Successful!</h4>
        </StyledSteps>
        <div>
          <FormGroup className='loginform'>
            <div className='termsandcondition'>
              <h5>Your deposit has been verified!</h5>
            </div>
          </FormGroup>
        </div>
        <Grid container className='nomargin' justifyContent='center'>
          <StyledButtonContainer>
            <a href="/">
              <Button type='button' color='brand2' className='signupbtn'>
                Play now!
              </Button>
            </a>
          </StyledButtonContainer>
        </Grid>
      </form>
    </StyledWrap>
  );
};

export default PaymentReturn;
