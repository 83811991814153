import React from "react";
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from "../../../components/Custom/mainstyle"
import AccordionSection from "../../../components/Custom/AccordionSection";
import PageHead from "../components/PageHead";

const listDetails = [
    { title: "Is it safe to deposit and withdraw from ArionPlay?", desc: "Yes, ArionPlay is integrated with GCash through our Bangko Sentral ng Pilipinas (BSP) - Operator of Payment System (OPS) accredited payment aggregator." },
    
];

const FaqTrustSafety: React.FC = () => {
    return (
        <StyledInfoBoxRight>
            <PageHead title="Trust & Safety" />
          <AccordionSection list={listDetails} />
        </StyledInfoBoxRight>
    );
};

export default FaqTrustSafety;
