import styled, { device, themeGet } from '@/shared/styled';

export const StyledMyAccountContainer = styled.div`
  background: ${themeGet('colors.bodybackground')};
  min-height: calc(100vh - 223px);
  padding: 20px 16px 30px;
  padding-top: 60px;
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  ${device.TabToLg}{
    padding-top: 0px;
    padding-left:0px;
    padding-right:0px;
  }
`;

export const StyledMyAccountView = styled.div`
  justify-content: space-between;
  padding: 20px 0px 10px;
  display: flex;
  flex-direction: column;
`;

export const StyledMyAccountHeader = styled.div`

  h4 {
    color: ${themeGet('colors.eventnumberselectedborder')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 22px;
    margin-right: 15px;
    padding-right: 15px;
    display: flex;
    margin-bottom: 15px;
    margin-top: 5px;
    ${device.TabToLg} {
      font-size: 18px;
      margin-right: 0px;
      padding-left: 16px;
      padding-right:16px;
     }
  }
`;

export const StyledMyAccountBoby = styled.div`
  border: 1px solid #2c3340;
  border-radius: 6px;
  display: flex;
  width: 100%;
  ${device.mdToLg} {
  }
  ${device.MobToMd} {
    flex-direction: column;
    border-radius: 0px;
    border:0px;
  }
`;
export const StyledMyAccountLeft = styled.div`
  background-image: url(https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/my-account-bg.png);
  background-position: 0 100%;
  background-repeat: no-repeat;
  border-right: 1px solid #2c3340;
  display: flex;
  flex-direction: column;
  min-height: 581px;
  position: relative;
  width: 350px;
  justify-content: space-between;
  padding: 40px 0px;
  ${device.mdToLg} {
    //height: 180px;
    width: 250px;
    
  }
  ${device.MobToMd} {
    min-height: 180px;
    padding-top:0px;
    background-size: contain;
    width: calc(100% - 32px);
    margin:0px 16px;
    border-radius: 6px;
    border: 1px solid #2c3340;
    margin-bottom: 30px;
  }
`;

export const StyledAccountProfile = styled.div`
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h5 {
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 20px;
    text-align: center;
  }
  p {
    color: #b7b9bb;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 14px;
    text-align: center;
  }
`;

export const StyledAccountButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  gap: 20px;
  .signout_profile {
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 12px;
    height: 31px;
    background: ${themeGet('colors.mainborder')};
    border: 1px solid ${themeGet('colors.mainborder')};
    border-radius: 20px;
    color: ${themeGet('colors.white')};
    width: 79px;
  }
  .deactiv_profile {
    background: none;
    border: 1px solid #616972;
    border-radius: 20px;
    color: #B7B9BB;
    width: auto;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 12px;
    height: 31px;
    padding:0px 15px;
    cursor: pointer;
    
  }
  ${device.TabToLg}{
    display:none;
  }
`;

export const StyledMyAccountRight = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 350px);
  .texttransform{text-transform: capitalize;}
  .receivenotifi{
    color: #B7B9BB;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansBold')};
    ${device.TabToLg}{
        font-size: 13px;
        font-family: ${themeGet('fonts.googleSansRegular')};
        color: #B7B9BB;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    li {
      align-items: center;
      display: flex;
      height: 50px;
      justify-content: space-between;
      padding: 10px 20px;
      width: 100%;
      border-bottom: 1px solid #212733;
      
      ${device.TabToLg}{
        &:first-child{
          border-top: 0px;
        }
        border-top: 4px solid #14181D;
        border-bottom: 1px solid #21252D;
        height:auto;
        align-items: flex-start;
        padding-top:15px;
        padding-bottom:20px;
      }
    }
  }
  ${device.mdToLg} {
    width: calc(100% - 150px);
    .receivenotifi{
      font-size: 13px;
      font-family: ${themeGet('fonts.googleSansRegular')};
      color: #B7B9BB;
    }
  }
  ${device.MobToMd} {
    width: 100%;
  }
`;
export const StyledAccountName = styled.div`
  color: #B7B9BB;
  font-family: ${themeGet('fonts.googleSansBold')};
  font-size: 13px;
  width: 40%;
  text-transform: capitalize;

  ${device.MobToMd}{
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
`;

export const StyledAccountMide = styled.div`
  color: #b7b9bb;
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 13px;
  width: 30%;
  text-align: left;
  //text-transform: capitalize;
  ${device.MobToMd}{
    width: 60%;
  }
  
`;

export const StyledAccountAction = styled.div`

${device.MobToMd}{
  display:none;
}
`;

export const StyledMobileView = styled .div`
display:none;
${device.TabToLg}{
  display:flex;
  display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding-top: 20px;
gap: 20px;
  .buttonmobile{display:flex; gap: 20px;}
}
`;

export const Styledcheckbox = styled.div`
display: flex;
justify-content: flex-end;
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 54px;
  height: 28px;
  border: 1px solid rgba(181, 181, 181, 0.50);
  background: rgba(116, 116, 116, 0.50);
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 3px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  background-image: url( 'https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/switch-close.svg' );
  background-repeat: no-repeat;
  background-position: 0px 0px;

  
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
  background-image: url( 'https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/switch-check.svg' );
  background-repeat: no-repeat;
  background-position: 3px 3px;

  
}

// .react-switch-label:active .react-switch-button {
//   width: 60px;
// }

`;