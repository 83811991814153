import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import { MenuItem } from '@mui/material';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    width: 280,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const RaceHeader = ({
  raceEventList,
  onChangeRace,
}: {
  raceEventList: any;
  onChangeRace: Function;
}) => {
  const [selectedRaceName, setSelectedRaceName] = React.useState('No Events');
  const [raceStatus, setRaceStatus] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeRace = (item: any) => {
    setSelectedRaceName(
      item.event.raceTime +
        ' - ' +
        item.event.name +
        ' - Event ' +
        item.event.raceNumber
    );
    if (item.event.status === 'BST') setRaceStatus('open');
    else setRaceStatus('closed');
    onChangeRace(item);
    handleClose();
  };

  React.useEffect(() => {
    let firstObj: any = null;
    let curObj: any = null;
    raceEventList.forEach((item: any) => {
      if (!firstObj) firstObj = item;
      if (!curObj && item.event.status === 'BST') {
        curObj = item;
      }
    });
    if (curObj) {
      changeRace(curObj);
    } else if (firstObj) {
      changeRace(firstObj);
    }
  }, [raceEventList]);

  return (
    <div className='raceselectmenu'>
      <Button
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        className={`raceheaderbtn ${raceStatus}`}
        // endIcon={<KeyboardArrowDownIcon />}
      >
        <span className='racestatuscolor'>
          <svg width='12' height='12' viewBox='0 0 12 12' fill='none'>
            <circle cx='6' cy='6' r='4' fill='currentColor'></circle>
            <circle cx='6' cy='6' r='5.5' stroke='currentColor'></circle>
          </svg>
        </span>
        <span>{selectedRaceName}</span>
      </Button>
      <StyledMenu
        style={{ height: '450px' }}
        className='racecardmenu'
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {raceEventList.map((item: any) => (
          <div className='racecardmenu' key={item.id + '-' + item.event.id}>
            <MenuItem
              className={
                item.event.status === 'BST' ? 'openforbet' : 'closeforbet'
              }
              onClick={() => changeRace(item)}
              disableRipple
            >
              <div className='racevenulist'>
                <p className='eventtime'>{item.event.raceTime}</p>
                {/* <p className='eventtitle'>{item.name}</p> */}
                <p className='eventname'>{item.event.name}</p>
              </div>
              <div className='opensub'>
                <p className='racenumber'>Event-{item.event.raceNumber}</p>
                {item.event.status === 'BST' && (
                  <div className='openrace'>Open to bet</div>
                )}
                {item.event.status === 'BSP' && (
                  <div className='closerace'>Closed</div>
                )}
                {item.event.status === 'BNS' && (
                  <div className='closerace'>Bets not open</div>
                )}
                {item.event.status === 'DRL' && (
                  <div className='closerace'>Closed</div>
                )}
              </div>
            </MenuItem>
          </div>
        ))}
      </StyledMenu>
    </div>
  );
};

export default RaceHeader;
