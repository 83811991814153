import axios from 'axios';
import { EnqueueSnackbar } from 'notistack';
import GameModel from '../@types/models/GameModel';

/*
 * Get Game Category Events.
 */
export const apiGetGamesByMenuCategory = async (
  menu: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null
): Promise<null | Array<GameModel>> => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_AUTHENTICATION_API_URL}/games/menu/${menu}`
    );
    if (result.status === 200) {
      return result.data as Array<GameModel>;
    } else {
      console.error(`Not a 200 OK, but ${result.status}`);
    }
  } catch (error) {
    console.error(error);
    if (errorEnqueueSnackbar) {
      errorEnqueueSnackbar(
        `${error.message}: ${error.response?.data?.error || 'n/a'}`,
        {
          variant: 'error',
          autoHideDuration: 1000,
        }
      );
    }
  }
  return null;
};
