import { QuestionSvg, ArrowRightSvg, HeadPhoneSvg, MessageSvg } from "@/shared/svg";
import React from "react";
import { Link } from "react-router-dom";
// import { StyledInfoBox, StyledTextArea } from "../../../pages/faqs/style";
import { StyledInfoBox, StyledTextArea } from "../mainstyle"
import { Card } from "@mui/material";

const Contact: React.FC<any> = ({ message = "" }: { message?: string }) => {
    return (
        <StyledInfoBox>
            <span>
                <QuestionSvg />
            </span>
            <StyledTextArea>
                <p>
                    {message === "" ? (<>
                        Everything you need to know about the{' '}
                        {process.env.REACT_APP_SITE_TITLE}. Can&apos;t find the
                        answer you&apos;re looking for?
                    </>) : (<>{message}</>)}
                </p>
                <Link to="/contactus">
                    <span>Contact Us</span>
                    <ArrowRightSvg />
                </Link>
            </StyledTextArea>
        </StyledInfoBox>

    );
};

export default Contact;
