import {
  FormGroup,
  Label,
  Input,
  Button,
  ButtonSpinner,
  InputGroup,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useState } from 'react';
//import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';

import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledPhoneText,
  StyledAlertMessage,
  StyledAlertMessageSucess,
  StyledButtonContainer,
} from './style';
import { AlertWarningiconSvg, KycVerifiedIconSvg } from '@/shared/svg';
import { Grid } from '@mui/material';

interface ChildProps {
  parentModalHandlerFunction: any;
}

const RequestWithdrawForm = (props: ChildProps) => {
  //const dispatch = useAppDispatch();
  const [message, setMessage] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useAppSelector(state => state.auth);
  const { selectedUserWallet } = useAppSelector(state => state.wallet);
  const [messagestatus, setMessageStatus] = useState('');
  const [isamountValidation, setIsamountValidation] = useState(false);
  const minAmount = 100;
  const maxAmount = 100000;

  console.log('Deposit', {
    userInfo,
    selectedUserWallet,
  });
  let selectedUserWalletId: string | null = null;
  if (selectedUserWallet) {
    selectedUserWalletId = selectedUserWallet.id;
  } else if (userInfo) {
    selectedUserWalletId = userInfo.selectedWalletId;
  }

  if (!selectedUserWalletId) {
    // This shall never happen!
    // TODO: Send fucking message to Dawid.
    alert('Apologies, we are maintain deposit function');
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IDepositForm>();

  const onSubmit: SubmitHandler<IDepositForm> = async data => {
    var amount = data.amount;
    if (typeof amount !== 'number' && isNaN(amount)) {
      setIsamountValidation(true);
      setMessage('Amount must be > 0, not 0');
      return;
    }

    if (data.amount < minAmount) {
      setIsamountValidation(true);
      setMessage(
        `The minimum amount required is ${minAmount}. Please add an <br/> amount of ${minAmount} or above to proceed.`
      );
      return;
    }

    if (data.amount > maxAmount) {
      setIsamountValidation(true);
      setMessage(
        `The maximum amount required is ${maxAmount}. Please adjust <br/> amount of ${maxAmount} or above to proceed.`
      );
      return;
    }

    setLoading(true);
    var temp = { amount: data.amount };
    const response = await AuthService.manualWithdraw(temp);
    // console.log(response);
    if (response.data.error === undefined) {
      setMessageStatus('success');
      reset();
      setLoading(false);
      setIsNotValid(true);
      setMessage('Transaction completed successfully');
    } else {
      setMessageStatus('error');
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data.error);
    }
  };

  return (
    <StyledWrap>
      <StyledTitle>Request Withdraw</StyledTitle>
      <StyledDesc>
        Withdraw the amount below from your Wallet account.
      </StyledDesc>
      <form
        className='loginform'
        action='#'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {/* {isNotValid ? (
          messagestatus == 'error' ? (
            <StyledAlertMessage>
              <AlertWarningiconSvg /> {message}
            </StyledAlertMessage>
          ) : (
            <StyledAlertMessageSucess>
              <KycVerifiedIconSvg /> {message}
            </StyledAlertMessageSucess>
          )
        ) : null} */}

        {isNotValid ? (
          messagestatus == 'error' ? (
            <StyledAlertMessage>
              <AlertWarningiconSvg /> {message}
            </StyledAlertMessage>
          ) : (
            <StyledAlertMessageSucess>
              <KycVerifiedIconSvg /> {message}
            </StyledAlertMessageSucess>
          )
        ) : null}

        {isamountValidation ? (
          <StyledAlertMessage
            style={{
              border: '1px solid #DC6803',
              background: 'rgba(255, 255, 255, 0.05)',
              fontWeight: '400',
              fontSize: '13px',
              color: '#FEFEFE',
              gap: '10px',
              lineHeight: '17px',
              padding: '8px',
              fontFamily: 'Google Sans',
            }}
          >
            <AlertWarningiconSvg />
            <span
              dangerouslySetInnerHTML={{ __html: message }}
              style={{ fontFamily: 'Google Sans' }}
            ></span>
          </StyledAlertMessage>
        ) : null}

        <div>
          <Grid container direction='column' md={12} className='no_padding'>
            <Label display='block' mb='5px' htmlFor='amount'>
              Amount
            </Label>
            <FormGroup>
              <StyledPhoneText>&#8369;</StyledPhoneText>
              <InputGroup>
                <Input
                  className='mobilenumber'
                  type='text'
                  id='amount'
                  name='amount'
                  placeholder='Enter Amount'
                  feedbackText={errors?.amount?.message}
                  state={hasKey(errors, 'amount') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'amount')}
                  {...register('amount', {
                    required: 'Amount is required',
                    validate: value =>
                      value > 0 || 'Amount must be greater than zero',
                  })}
                  onChange={e => {
                    const value = e.target.value;

                    // Remove any characters that are not numbers or a decimal point
                    const sanitizedValue = value.replace(/[^0-9.]/g, '');

                    // Prevent multiple decimal points
                    if ((sanitizedValue.match(/\./g) || []).length > 1) {
                      e.target.value = sanitizedValue.slice(0, -1);
                    } else {
                      e.target.value = sanitizedValue;
                    }

                    // Prevent entering a negative sign
                    if (e.target.value.includes('-')) {
                      e.target.value = e.target.value.replace('-', '');
                    }
                  }}
                />
              </InputGroup>
            </FormGroup>
          </Grid>
        </div>
        <StyledButtonContainer>
          <Button type='submit' color='brand2' className='signin_btn'>
            {loading ? <ButtonSpinner /> : null}Continue
          </Button>
        </StyledButtonContainer>
      </form>
    </StyledWrap>
  );
};

export default RequestWithdrawForm;
