import React from "react";
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from "../../../components/Custom/mainstyle"
import AccordionSection from "../../../components/Custom/AccordionSection";
import PageHead from "../components/PageHead";

const listDetails = [
    { title: `What is ${process.env.REACT_APP_SITE_TITLE}`, desc: `${process.env.REACT_APP_SITE_TITLE} is a PAGCOR-licensed online betting platform that offers Sports Betting. ` },
    { title: "How can I contact customer support?", desc: `You can use the contact form on our website or reach out to us directly via email at help@arionplay.com. <br/><br/> We are always here to assist you and address any inquiries you may have.` },
    { title: "I know ArionPlay offers Sports Betting but is there a way to play e-games too?", desc: `Yes! If you want to mix it up and play e-games, visit www.fairplay.ph. You can log in using your ArionPlay credentials. <br/><br/>ArionPlay and FairPlay have a unified wallet system. This means whatever amount you have in your ArionPlay wallet, the same amount will be reflected in your FairPlay wallet.` },
];

const FaqGettingStarted: React.FC = () => {
    return (
        <StyledInfoBoxRight>
            <PageHead title="Getting Started" />
          <AccordionSection list={listDetails} />
        </StyledInfoBoxRight>
    );
};

export default FaqGettingStarted;
