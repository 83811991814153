import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// Lib
import moment from 'moment/moment';
// Mui
import Container from '@mui/material/Container';
//
import { ProfileNameSvg } from '@/shared/svg';
import AuthService from '@/services/AuthService';
import CookieStorageService from '@/services/CookieStorageService';
import { resetUser, setIsAuthenticatedData } from '@/redux/slices/login';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import ChangePasswordPopUp from '@/components/ChangePasswordPopUp';
import Page from '@/components/Page';
//
import Switch from './Switch';
import DatePicker, { Value } from 'react-multi-date-picker';

import {
  StyledMyAccountView,
  StyledMyAccountHeader,
  StyledMyAccountBoby,
  StyledMyAccountLeft,
  StyledMyAccountRight,
  StyledAccountProfile,
  StyledAccountButton,
  StyledAccountName,
  StyledAccountMide,
  StyledAccountAction,
  StyledMobileView,
} from './style';

const MyAccount = () => {
  const history = useNavigate();
  const dispatch = useAppDispatch();
  //const [verificationStatus, setVerificationStatus] = useState('');
  const [userInfo, setUserInfo] = useState<IUser>(null);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [accountDeactivation, setAccountDeactivation] = useState(false);
  const [enableDatepicker, setEnableDatepicker] = useState(false);
  const [displayModule, setDisplayModule] = useState(false);
  const [maxValue, setMaxValue] = useState('');
  const datePickerRef = useRef<any>();
  const [maxDate, setMaxDate] = useState('');
  const [dob, setDob] = useState<Value>('');
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const minimumDate = moment().add(1, 'day').toDate();

  const logoutUser = () => {
    CookieStorageService.deleteCookieData(AuthService.getAuthKey());
    dispatch(setIsAuthenticatedData(false));
    dispatch(resetUser());
    history('/');
    location.reload();
  };
  const getMyaccountData = async () => {
    const response = await AuthService.getUserData();
    if (response.data.emailNotification == undefined)
      response.data.emailNotification = false;

    if (response.data.accountNotification == undefined)
      response.data.accountNotification = false;

    setAccountDeactivation(response.data.accountNotification);
    setEmailNotifications(response.data.emailNotification);
    setUserInfo(response.data);
  };

  const setEmailverification = async (
    val: boolean | ((prevState: boolean) => boolean)
  ) => {
    try {
      const res = { emailNotification: val };
      const response = await AuthService.updateUserProfile(res);
      setEmailNotifications(val);
    } catch (error) {
      console.error('Failed to update email notifications', error);
    }
  };

  const openInfoPopup = async () => {
    setDisplayModule(true);
  };

  const closePopup = () => {
    setDisplayModule(false);
  };

  const handleDeactivationToggle = async () => {
    setEnableDatepicker(!enableDatepicker);
    setAccountDeactivation(!accountDeactivation);
  };

  const handleDobValue = (date: any) => {
    setMaxValue(date);
    setIsDateChanged(true);
    callApiWithDate(date);
  };

  const callApiWithDate = async (date: any) => {
    //alert(date);
    const formattedDate = date.format('YYYY/MM/DD'); // Adjust formatting if needed
    const fDate = `${currentDate.getFullYear()}/${String(
      currentDate.getMonth() + 1
    ).padStart(2, '0')}/${String(currentDate.getDate()).padStart(2, '0')}`;
    if (fDate == formattedDate) return;
    try {
      const res = {
        accountNotification: true,
        status: 'suspend',
        reactivationDate: formattedDate,
      };
      const response = await AuthService.updateUserProfile(res);
      alert(`Your Account Is Deactivated Until ${formattedDate}`);
      setAccountDeactivation(!accountDeactivation);
      logoutUser();
    } catch (error) {
      console.error('Failed to update date of birth', error);
    }
  };

  useEffect(() => {
    getMyaccountData();
  }, []);
  return (
    <Page
      title='My Account'
      favicon={
        process.env.REACT_APP_THEME === 'fairplay'
          ? '/icons/partners/fairplay/favicon.ico'
          : null
      }
    >
      <Container maxWidth={'lg'}>
        <StyledMyAccountView>
          <StyledMyAccountHeader>
            <h4>My Account</h4>
          </StyledMyAccountHeader>
          <StyledMyAccountBoby>
            <StyledMyAccountLeft>
              <StyledAccountProfile>
                <ProfileNameSvg />
                <h5>u-{userInfo?.id.slice(0, 8)}</h5>
                {userInfo?.firstName != '' && userInfo?.lastName != '' && (
                  <h5>
                    {userInfo?.firstName} {userInfo?.middleName}{' '}
                    {userInfo?.lastName}
                  </h5>
                )}
                <p>
                  Member since{' '}
                  {moment(userInfo?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                </p>
              </StyledAccountProfile>
              <StyledAccountButton>
                <button
                  type='button'
                  className='deactiv_profile'
                  onClick={() => openInfoPopup()}
                >
                  Change Password
                </button>
                <button
                  type='button'
                  className='signout_profile'
                  onClick={() => logoutUser()}
                >
                  Sign Out
                </button>
              </StyledAccountButton>
            </StyledMyAccountLeft>

            <StyledMyAccountRight>
              <ul>
                <li>
                  <StyledAccountName>User ID</StyledAccountName>
                  <StyledAccountMide>
                    u-{userInfo?.id.slice(0, 8)}
                  </StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Full Name</StyledAccountName>
                  <StyledAccountMide className='texttransform'>
                    {userInfo?.firstName} {userInfo?.middleName}{' '}
                    {userInfo?.lastName}
                  </StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Mobile number</StyledAccountName>
                  <StyledAccountMide>{userInfo?.phone}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Date of birth</StyledAccountName>
                  <StyledAccountMide>
                    {moment(userInfo?.dob).format('DD/MM/YYYY')}
                  </StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Email address</StyledAccountName>
                  <StyledAccountMide>{userInfo?.email}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Postal Code</StyledAccountName>
                  <StyledAccountMide>{userInfo?.postalCode}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Address1</StyledAccountName>
                  <StyledAccountMide className='texttransform'>
                    {userInfo?.address2}
                  </StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Address2</StyledAccountName>
                  <StyledAccountMide className='texttransform'>
                    {userInfo?.address1}
                  </StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>City</StyledAccountName>
                  <StyledAccountMide className='texttransform'>
                    {userInfo?.city}
                  </StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Source of income</StyledAccountName>
                  <StyledAccountMide className='texttransform'>
                    {userInfo?.sourceOfIncome}
                  </StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Income range</StyledAccountName>
                  <StyledAccountMide>{userInfo?.income}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Occupation</StyledAccountName>
                  <StyledAccountMide className='texttransform'>
                    {userInfo?.occupation}
                  </StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Country Code</StyledAccountName>
                  <StyledAccountMide>{userInfo?.countryCode}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Nationality</StyledAccountName>
                  <StyledAccountMide className='texttransform'>
                    {userInfo?.nationality}
                  </StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <p className='receivenotifi'>
                    Receive email notifications and promotional material from{' '}
                    {process.env.REACT_APP_SITE_TITLE}
                  </p>
                  <StyledAccountMide>
                    <Switch
                      isOn={emailNotifications}
                      onColor='#DD631A'
                      id='email'
                      handleToggle={() =>
                        setEmailverification(!emailNotifications)
                      }
                    />
                  </StyledAccountMide>
                </li>
                <li>
                  <p className='receivenotifi'>
                    Request to deactivate my account{' '}
                    {process.env.REACT_APP_SITE_TITLE}
                  </p>
                  <StyledAccountMide>
                    <Switch
                      isOn={accountDeactivation}
                      onColor='#DD631A'
                      id='account'
                      handleToggle={handleDeactivationToggle}
                    />
                  </StyledAccountMide>
                </li>
                {enableDatepicker && (
                  <li>
                    <p className='receivenotifi'>
                      Kindly choose the reactivation date
                    </p>
                    <DatePicker
                      format={'YYYY/MM/DD'}
                      onChange={handleDobValue}
                      minDate={minimumDate}
                    ></DatePicker>
                  </li>
                )}
              </ul>
              <StyledMobileView>
                <StyledAccountButton className='buttonmobile'>
                  <button
                    type='button'
                    className='deactiv_profile'
                    onClick={() => openInfoPopup()}
                  >
                    Change Password
                  </button>
                  <button
                    type='button'
                    className='signout_profile'
                    onClick={() => logoutUser()}
                  >
                    Sign Out
                  </button>
                </StyledAccountButton>
              </StyledMobileView>
              {displayModule && <ChangePasswordPopUp closePopup={closePopup} />}
            </StyledMyAccountRight>
          </StyledMyAccountBoby>
        </StyledMyAccountView>
      </Container>
    </Page>
  );
};

export default MyAccount;
