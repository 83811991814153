import styled, { device, themeGet } from '@/shared/styled';
import { exact } from 'prop-types';

export const StyledContainerFluid = styled.div`
  background: ${themeGet('colors.bodybackground')};
  //height: calc(100vh - 50px);
  //height: 100vh;
  padding-top: 60px;
  ${device.TabToLg} {
    padding-top: 0px;
  }
  .custom-checkbox label {
    font-size: 13px;
    color: #fcfcfc;
    font-family: ${themeGet('fonts.googleSansMedium')};
    &:before {
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      position: absolute;
      top: 44%;
      transform: translateY(-50%);
      left: -16px;
      display: block;
      width: 15px;
      height: 15px;
      //pointer-events: none;
      content: '';
      //background-color: ${themeGet('colors.btnfontcolor')};
      border: 1px solid ${themeGet('colors.white')};
      border-radius: 0.25rem;
    }
    &:after {
      position: absolute;
      top: 47%;
      transform: translateY(-50%);
      left: -13px;
      display: block;
      width: 9px;
      height: 10px;
      content: '';
      // background: ${themeGet('colors.btnfontcolor')};
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
`;

export const StyledContainerBetFluid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  padding: 15px 16px 0px 16px;
  gap: 20px;
  background: ${themeGet('colors.bodybackground')};
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }

  ${device.TabToLg} {
    flex-direction: column;
    padding: 0px;
    gap: 0px;
  }
`;

export const StyledContainerLeft = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  ${device.TabToLg} {
    padding: 15px 16px;
    gap: 10px;
    .fixedtotebutton {
      //display: none;
    }
  }
`;

export const StyledContainerRight = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  background: ;
  flex-direction: column;
  //border: 1px solid ${themeGet('colors.tableheaderborder')};
  border-radius: 10px 10px 10px 10px;
  height: 100%;
  min-width: 623px;
  ${device.MobToMd} {
    height: auto;
    border: 0px;
    border-radius: 0px;
    min-width: auto;
  }
`;

export const StyledContainerLeftHeader = styled.div`
  width: 100%;
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  position: relative;
  .time_info {
    float: left;
    background: #121212;
    color: #fff;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 12px;
    padding: 0px 12px;
    line-height: 28px;
    margin-right: 10px;
    border: 1px solid #465675;
    border-radius: 20px;
    height: 27px;
    ${device.TabToLg} {
      width: 65px;
    }
  }
  .race_place {
    font-size: 16px;
    float: left;
    font-family: ${themeGet('fonts.googleSansBold')};
    line-height: 27px;
    margin-right: 6px;
    color: #c2c9d6;
    ${device.TabToLg} {
      width: 90px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 5px;
    }
  }
  .race_no {
    float: left;
    font-size: 16px;
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    line-height: 27px;
    padding-left: 6px;
    padding-right: 12px;
    cursor: pointer;
    ${device.TabToLg} {
      width: 75px;
    }
  }
  .racecard_text {
    padding: 0px 10px;
    float: left;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    cursor: pointer;
    background: ${themeGet('colors.runnerbtnbg')};
    border: 1px solid ${themeGet('colors.eventnumberdefaultborder')};
    border-radius: 10px;
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    height: 27px;
    display: flex;
    align-items: center;
  }
  .odds_text {
    padding: 0px 10px;
    float: left;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    cursor: pointer;
    background: ${themeGet('colors.runnerbtnbg')};
    border: 1px solid ${themeGet('colors.eventnumberdefaultborder')};
    border-radius: 10px;
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    height: 27px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
`;

export const StyledRacePlaceInfo = styled.div`
  width: 100%;
  padding: 0px 0px 10px 0px;
  display: flex;
  .mobileviewracenext {
    display: none;
  }
  .mobileracenameinfo {
    display: none;
  }
  ${device.TabToLg} {
    align-items: center;
    .mobileviewracenext {
      display: block;
      display: flex;
      gap: 8px;
      li {
        background: ${themeGet('colors.runnerbtnbg')};
        width: 38px;
        height: 38px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .racecard_text {
      display: none;
    }
    .odds_text {
      display: none;
    }
    .mobileracenameinfo {
      display: block;
      ul {
        list-style: none;
        li {
          color: ${themeGet('colors.GameTextcolor')};
          font-size: 14px;
          font-family: ${themeGet('fonts.googleSansRegular')};
          display: flex;
          flex-direction: column;
          .race_name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
          }
        }
      }
    }
  }
  .racelistpopup {
  }
`;

export const StyledRaceInfoText = styled.div`
  .mobileracenameinfo {
    display: none;
  }
  ${device.TabToLg} {
    .mobileracenameinfo {
      display: block;
      ul {
        list-style: none;
        li {
          color: ${themeGet('colors.GameTextcolor')};
          font-size: 14px;
          font-family: ${themeGet('fonts.googleSansRegular')};
          display: flex;
          flex-direction: column;
          .race_name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 70%;
          }
        }
      }
    }
  }
`;

export const StyledLiveMobileView = styled.div`
  display: none;
  ${device.TabToLg} {
    display: block;
    ul {
      display: flex;
      list-style: none;
      gap: 10px;
      padding-top: 10px;

      li {
        display: flex;
        background: ${themeGet('colors.runnerbtnbg')};
        border: 1px solid ${themeGet('colors.runnerbtnborder')};
        width: calc(100% / 3);
        justify-content: center;
        border-radius: 10px;
        a {
          padding: 8px 10px;
          color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
          font-size: 14px;
          font-family: ${themeGet('fonts.googleSansBold')};
          white-space: nowrap;
          width: 100%;
          text-align: center;
        }
        &.livevideo {
          border: 1px solid ${themeGet('colors.mainbackground')};
          background: none;
          a {
            display: flex;
            align-items: center;
            gap: 8px;
            color: ${themeGet('colors.mainbackground')};
            justify-content: center;
            svg {
              path {
                path: ${themeGet('colors.mainbackground')};
              }
            }
          }
        }
      }
    }
  }
`;

export const StyledButtonFixedToteButton = styled.div`
  // height: 56px;
  // position: absolute;
  // right: 1px;
  // top: 4px;
  // width: 150px;
  // display:none;
  ul {
    background: ${themeGet('colors.tableheaderbg')};
    border: 1px solid #232935;
    border-radius: 10px;
    float: left;
    height: 56px;
    padding: 6px;
    width: 100%;
    display: flex;
    list-style: none;
    li {
      button {
        background: ${themeGet('colors.tableheaderbg')};
        border: 1px solid transparent;
        color: ${themeGet('colors.white')};
        font-size: 12px;
        height: 44px;
        width: 68px;
        font-family: ${themeGet('fonts.googleSansBold')};
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .active {
      button {
        background: ${themeGet('colors.mainborder')};
        display: flex;
        align-items: center;
        font-family: ${themeGet('fonts.googleSansBold')};
        border-radius: 7px;
        color: ${themeGet('colors.white')};
        font-size: 12px;
        font-family: ${themeGet('fonts.googleSansBold')};
      }
    }
  }
  .mobfixedtotebutton {
    display: none;
  }

  ${device.MobToMd} {
    position: inherit;
    width: 100%;

    ul {
      border: 2px solid ${themeGet('colors.mainborder')};
      padding: 2px;
      border-radius: 25px;
      height: auto;
      li {
        display: flex;
        width: 100%;
        button {
          width: 100%;
          height: 28px;
          padding: 0 50px;
          border-radius: 25px;
        }
      }
      .active {
        button {
          border-radius: 25px;
        }
      }
    }
    .mobfixedtotebutton {
      display: block;
    }
  }
`;

export const StyledButtonFixedTote = styled.div`
  height: 56px;
  position: absolute;
  right: 1px;
  top: 4px;
  width: 150px;
  ul {
    background: ${themeGet('colors.tableheaderbg')};
    border: 1px solid #232935;
    border-radius: 10px;
    float: left;
    height: 56px;
    padding: 6px;
    width: 100%;
    display: flex;
    list-style: none;
    li {
      button {
        background: ${themeGet('colors.tableheaderbg')};
        border: 1px solid transparent;
        color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
        font-size: 12px;
        height: 44px;
        width: 68px;
        font-family: ${themeGet('fonts.googleSansMedium')};
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .active {
      button {
        background: ${themeGet('colors.mainborder')};
        border: 1px solid #ff9860;
        display: flex;
        align-items: center;
        border-radius: 7px;
        color: ${themeGet('colors.white')};
        font-size: 12px;
        font-family: ${themeGet('fonts.googleSansBold')};
      }
    }
  }
  .mobfixedtotebutton {
    display: none;
  }

  ${device.TabToLg} {
    position: inherit;
    width: 100%;
    //display: none;
    ul {
      border: 1px solid #232935;
      padding: 2px;
      border-radius: 10px;
      height: 43px;
      background: ${themeGet('colors.pagecontainerinput')};
      margin-top: 16px;
      display: flex;
      align-items: center;
      li {
        display: flex;
        width: 100%;
        button {
          width: 100%;
          height: 28px;
          padding: 0 50px;
          border-radius: 25px;
          font-size: 15px !important;
        }
      }
      .active {
        button {
          border-radius: 7px;
          height: 35px;
        }
      }
    }
    .mobfixedtotebutton {
      display: block;
    }
  }
`;

export const StyledRaceNameDetails = styled.div`
  float: left;
  font-size: 14px;
  line-height: 18px;
  color: #a3adc2;
  margin-bottom: 15px;
  ul {
    list-style: none;
  }
  .race_name {
    border-right: 1px solid #465675;
    padding-right: 10px;
    width: 64%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
  }
  .race_details_info {
    padding: 0px 0px 0px 10px;
  }
  ${device.TabToLg} {
    display: none;
  }
`;

export const StyledContainerLeftBody = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  ${device.TabToLg} {
    .racevideoview {
      display: block !important;
    }
  }
`;

export const StyledRaceNosContainer = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #222222;
  border-bottom: 1px solid #222222;
  padding: 20px 0px;
  margin-bottom: 15px;
  ${device.TabToLg} {
    display: none;
  }
  h4 {
    float: left;
    font-size: 14px;
    line-height: 42px;
    color: #a3adc2;
    padding-right: 15px;
    margin-bottom: 0px;
  }
  ul {
    display: flex;
    list-style: none;
    li {
      margin-right: 10px;
      a {
        width: 42px;
        height: 42px;
        border-radius: 100%;
        line-height: 40px;
        background: ${themeGet('colors.eventnumberdefaultbg')};
        border: 1px solid ${themeGet('colors.eventnumberdefaultborder')};
        display: flex;
        justify-content: center;
        color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
        font-family: ${themeGet('fonts.googleSansMedium')};
        font-size: 16px;
        &:focus {
          outline: none;
        }
      }
      .active {
        background: ${themeGet('colors.eventnumberselectedbg')};
        border: 1px solid ${themeGet('colors.ActionInfoButtonborder')};
        color: ${themeGet('colors.pagefontcolor')};
        outline: none;
      }
      :focus {
        // background: ${themeGet('colors.eventnumberselectedbg')};
        // border: 1px solid ${themeGet('colors.eventnumberselectedborder')};
        // color: ${themeGet('colors.pagefontcolor')};
        outline: none;
      }
    }
  }
  ${device.MobToMd} {
    ul {
      width: calc(100% - 50px);
      overflow-y: hidden;
      overflow-x: scroll;
      list-style: none;
      li a {
        width: 32px;
        height: 32px;
        line-height: 30px;
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
  }
`;

export const StyledVideoContainer = styled.div`
  width: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;

  // ${device.TabToLg}{
  //   display: none !important;
  // }
`;

export const StyledVideoView = styled.div`
  display: flex;
  width: 100%;
  //padding-top:15px;
  //height: calc(100% - 45px);
  flex-direction: column;
  transition: all 0.3s;
  align-items: center;
  height: 100%;
  ${device.TabToLg} {
    // padding-top:15px;
  }
  p {
    font-size: 14px;
    font-style: italic;
    color: #a3adc2;
    padding-bottom: 10px;
  }
  .videoview {
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: 660px;
    height: 100%;
    iframe {
      width: 660px !important;
      // height: 346px;
      height: 380px;
      ${device.MobToSmx} {
        width: 100% !important;
        height: 224px;
      }
      ${device.MobToMmx} {
        width: 100% !important;
        height: 242px;
      }
      ${device.MobToDmx} {
        width: 100% !important;
        height: 242px;
      }

      ${device.MobToMmxx} {
        height: 224px !important;
      }
    }
  }
  .messageview {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${themeGet('fonts.googleSansMedium')};
    // border: 1px solid ${themeGet('colors.mainborder')};
    //color: ${themeGet('colors.pagefontcolor')};
    color: rgb(255, 255, 255);
    font-size: 18px;
    border-radius: 10px;
    text-align: center;
    font-weight: 700;
    padding: 20px;
  }
`;

export const StyledVideoViewUpdate = styled.div`
  height: 45px;
  display: flex;
  width: 100%;
  border-radius: 10px;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
  background: #171717;
  padding: 12px 16px;
  margin-top: 20px;
  p {
    font-family: ${themeGet('fonts.googleSansMedium')};
    color: ${themeGet('colors.homebannertxtcolor')};
    font-size: 14px;
    margin-bottom: 0px;
    //width:100%;
  }
  span {
    font-family: ${themeGet('fonts.googleSansRegular')};
    color: #a3adc2;
    font-size: 14px;
    border-left: 1px solid #465675;
    padding-left: 20px;
    margin-left: 10px;
  }
`;

export const StyledContainerRightHeader = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  color: #fff;
  background: ${themeGet('colors.tableheaderbg')};
  border-radius: 10px 10px 0px 0px;
  padding: 10px 15px;
  border: 1px solid ${themeGet('colors.tableheaderborder')};
  ul {
    float: left;
    width: 100%;

    li {
      float: left;
      padding-right: 20px;
      color: #fff;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansMedium')};

      Span {
        float: left;
      }
      &:last-child {
        float: right;
        width: 200px;
        padding-right: 0px;
        span {
          width: 50%;
          float: left;
          text-align: center;
        }
      }
    }
  }
`;

export const StyledContainerRightBody = styled.div`
  height: 717px;
  //height: calc(100vh - 170px);
  overflow: auto;
  // min-height: 430px;
  width: 100%;
  float: left;
  transition: all 0.3s;
  position: relative;
  border-left: 1px solid #232935;
  border-right: 1px solid #232935;
  ${device.MobToMd} {
    //height: 380px;
    height: auto;
  }
`;

export const StyledContainerToteHead = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  color: #fff;
  background: ${themeGet('colors.tableheaderbg')};
  padding: 8px 15px;
  border: 1px solid ${themeGet('colors.tableheaderborder')};
  flex-direction: column;
  transition: all 0.3s;
  border-radius: 10px 10px 0px 0px;
  margin-top: 4px;
  p {
    font-weight: bold;
    text-align: center;
    color: #f96464;
    font-size: 15px;
    ${device.TabToLg} {
      font-size: 13px;
      font-family: ${themeGet('fonts.googleSansMedium')};
    }
  }
`;

export const StyledToteMultLeg = styled.div`
  width: 100%;
  display: flex;
  transition: all 0.3s;
  ul {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    li {
      border: 1px solid #a3adc2;
      border-radius: 5px;
      cursor: pointer;
      display: inline-block;
      height: 30px;
      margin-right: 5px;
      overflow: hidden;
      width: 48px;
      span {
        display: flex;
        align-content: center;
        justify-content: center;
        width: 100%;
        line-height: 30px;
      }
    }
    li.selected {
      background: ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.pagefontcolor')};
      border: 1px solid ${themeGet('colors.mainborder')};
    }
  }
`;

export const StyledToteSelectAll = styled.div`
  display: flex;
  align-content: center;
  font-size: 13px;
  font-family: ${themeGet('fonts.googleSansMedium')};
  justify-content: space-between;
  width: 100%;
  color: ${themeGet('colors.checkboxborder')};
  transition: all 0.3s;
`;

export const StyledBoxSelectSno = styled.div`
  width: 55px;
`;

export const StyledAllSelectWin = styled.div`
  width: 192px;
  display: flex;
  justify-content: end;
  .fixedbetheader {
    display: flex;
    gap: 15px;
    width: 160px;
    ${device.TabToLg} {
      width: auto;
    }
    span {
      width: 72px;
      display: flex;
      justify-content: center;
      ${device.TabToLg} {
        width: 46px !important;
      }
    }
  }
`;

export const StyledAllSelectPlc = styled.div``;

export const StyledBoxSelect = styled.div`
  display: flex;
  gap: 10px;
`;

export const StledHorseDetails = styled.div`
  width: calc(100% - 247px) !important;
`;

export const StyledAllSelect = styled.div`
  display: flex;
  gap: 10px;
  //width: 225px;
  justify-content: space-between;
  .checkboxselect {
    display: flex;
    gap: 10px;
    .custom-checkbox {
      width: 72px;
      ${device.TabToLg} {
        width: 50px !important;
      }
    }
  }
`;

export const StyledContainerRunner = styled.div`
  &.scratched {
    opacity: 0.4;
    border-bottom: 1px solid #f96464;
  }
  width: 100%;
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px solid ${themeGet('colors.tableheaderborder')};
  &:nth-child(2) div {
    border-color: #2659c2;
    .infonumber {
      background: #2659c2;
    }
  }
  &:nth-child(3) div {
    border-color: #7f0cf2;
    .infonumber {
      background: #7f0cf2;
    }
  }
  &:nth-child(4) div {
    border-color: #00aa4f;
    .infonumber {
      background: #00aa4f;
    }
  }
  &:nth-child(5) div {
    border-color: #35373a;
    .infonumber {
      background: #35373a;
    }
  }

  &:nth-child(6) div {
    border-color: #f7ba05;
    .infonumber {
      background: #f7ba05;
    }
  }

  &:nth-child(7) div {
    border-color: #9e29bf;
    .infonumber {
      background: #9e29bf;
    }
  }

  &:nth-child(8) div {
    border-color: #e80f9d;
    .infonumber {
      background: #e80f9d;
    }
  }

  &:nth-child(9) div {
    border-color: #d96a19;
    .infonumber {
      background: #d96a19;
    }
  }

  &:nth-child(10) div {
    border-color: #8bb518;
    .infonumber {
      background: #8bb518;
    }
  }

  &:nth-child(11) div {
    border-color: #0e5e17;
    .infonumber {
      background: #0e5e17;
    }
  }
  &:nth-child(12) div {
    border-color: #2b5660;
    .infonumber {
      background: #2b5660;
    }
  }
  &:nth-child(13) div {
    border-color: #7b3535;
    .infonumber {
      background: #7b3535;
    }
  }
  &:nth-child(14) div {
    border-color: #392d5b;
    .infonumber {
      background: #392d5b;
    }
  }
  &:nth-child(15) div {
    border-color: #228274;
    .infonumber {
      background: #228274;
    }
  }
  &:nth-child(16) div {
    border-color: #12afbf;
    .infonumber {
      background: #12afbf;
    }
  }
  &:nth-child(17) div {
    border-color: #12bf5e;
    .infonumber {
      background: #12bf5e;
    }
  }
  &:nth-child(18) div {
    border-color: #4e199b;
    .infonumber {
      background: #4e199b;
    }
  }
  &:nth-child(19) div {
    border-color: #7b0d46;
    .infonumber {
      background: #7b0d46;
    }
  }
  &:nth-child(20) div {
    border-color: #ee390f;
    .infonumber {
      background: #ee390f;
    }
  }
  ul {
    width: 100%;
    display: flex;
    li {
      width: 100%;
      display: flex;
    }
    &:nth-child(2) div {
      border-color: #2659c2;
    }
  }
  ${device.MobToMd} {
    padding: 15px 16px;
    position: relative;
    height: auto;
    &.racecardheight {
      height: 145px;
    }
    li {
      gap: 10px;
    }
  }
`;

export const StyledjockyInfo = styled.div`
  width: 40px;
  height: 78px;
  border: 1px solid #c20e0e;
  border-radius: 5px;
  margin-right: 15px;
  overflow: hidden;
  color: ${themeGet('colors.white')};
  text-align: center;
  transition: all 0.3s;
  ${device.TabToLg} {
    width: 58px;
  }

  span {
    background: #c20e0e;
    width: 100%;
    float: left;
    color: #fff;
    text-align: center;
    font-size: 14px;
  }
  img {
    height: 28px;
    float: left;
    margin: 5px 0px 0px 0px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    ${device.TabToLg} {
      width: 27px;
    }
  }
  small {
    width: 100%;
    float: left;
    text-align: center;
    color: #a3adc2;
    font-size: 12px;
    margin-top: 2px;
  }
  ${device.MobToMd} {
    display: flex;
    flex-direction: column;
    margin-right: 0px;
  }
`;

export const StyledRidingInfo = styled.div`
  width: calc(100% - 247px) !important;
  float: left;
  transition: all 0.3s;
  .horse_name {
    width: 100%;
    float: left;
    color: #c2c9d6;
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 5px;
    margin-top: 3px;
  }
  .sire_dam_name {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
    color: #a3adc2;
  }
  ${device.MobToMd} {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
`;

export const StyledActionInfo = styled.div`
  float: right !important;
  width: 192px;
  display: flex;
  align-items: end;
  justify-content: flex-start;
  transition: all 0.3s;
  flex-direction: column;
  gap: 2px;
  .winbutton {
    font-size: 16px;
    float: left;
    width: 72px;
    height: 42px;
    background: ${themeGet('colors.runnerbtnbg')};
    border: 1.5px solid ${themeGet('colors.runnerbtnborder')};
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    border-radius: 10px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    //transition: all 0.3s;
    svg {
      width: 20px;
      height: 20px;
      display: flex;
      box-sizing: content-box;
      overflow: visible;
    }
    svg path {
      fill: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    }
  }
  .active {
    background: ${themeGet('colors.mainbackground')} !important;
    border: 1.5px solid ${themeGet('colors.ActionInfoButtonborder')} !important;
    color: ${themeGet('colors.white')} !important;
    transition: all 0.3s;

    svg {
      path {
        fill: ${themeGet('colors.white')};
      }
    }
  }
  ${device.MobToMd} {
    display: flex;
    flex-direction: column;
    width: auto !important;
    align-items: center;
    .multibet {
      gap: 5px;
      position: absolute;
      bottom: 11px;
      right: 10px;
      .winbutton {
        width: 50px !important;
        height: 35px !important;
        border-radius: 5px !important;
      }
    }
    .winbutton {
      padding: 0px 10px;
      width: 60px !important;
      height: 42px !important;
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
`;
export const StyledActionButton = styled.div`
  display: flex;
  .totebet{
    display: flex;
    gap: 15px;
    ${device.TabToLg}{
      gap: 8px;
    }
  }
  .fixedbet{
    display: flex;
    gap: 15px;
  }

  &.multibet{
    gap: 5px;
    .winbutton{
      font-size: 14px;
      height: 42px;
      //margin-left: 8px;
      position: relative;
      width: 72px;
      //transition: all 0.3s;
      font-size: 16px;
      svg{
        width: 12px;
        height: 12px;
        display: flex;
        box-sizing: content-box;
        overflow: visible;
        position: absolute;
        top: 1px;
        right: 4px;
      }
      &.active{
        background: ${themeGet('colors.mainbackground')};
        border: 1px solid ${themeGet('colors.mainbackground')};
        color: ${themeGet('colors.white')};
        transition: all 0.3s;
        svg{
          path{fill:${themeGet('colors.white')};}
        }
      
    }
  }
  .buttontext{
    font-size: 13px;
    position: absolute;
    bottom: 0px;
    font-family: ${themeGet('fonts.googleSansBold')};
  }
`;
export const StyledScrateched = styled.div`
  span {
    border: 2px solid #f96464;
    border-radius: 3px;
    color: #f96464;
    font-size: 18px;
    height: 40px;
    line-height: 36px;
    margin-top: 4px;
    position: relative;
    text-align: center;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3px;
  }
  p {
    color: #f96464;
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
`;

export const StyledOddInfo = styled.div`
  display: flex;
  color: #c2c9d6;
  float: right;
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
  p {
    gap: 4px;
    display: flex;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
`;
export const StyedHorseSireDamName = styled.div`
  font-size: 12px;
  color: #697a9b;
  line-height: 14px;
  float: left;
  .horse_name {
    color: #c2c9d6;
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 5px;
    margin-top: 3px;
    font-family: ${themeGet('fonts.googleSansBold')};
  }
  .sire_dam_name {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    color: #a3adc2;
  }
  span {
    width: 100%;
    float: left;
  }
`;

export const StyledJockyTrainer = styled.div`
  font-size: 12px;
  color: #697a9b;
  line-height: 14px;
  float: left;
  .jockey_name {
    font-size: 12px;
    color: #697a9b;
    line-height: 14px;
    margin-bottom: 5px;
    float: left;
  }
  .trainer_name {
    width: 100%;
    float: left;
    font-size: 12px;
    color: #697a9b;
    line-height: 14px;
  }
`;
export const StyledContainerRightFotter = styled.div`
  display: flex;
  height: auto;
  position: absolute;
  padding: 12px;
  //transition: all 0.3s;
  transition: bottom 0.4s;
  width: 100%;
  bottom: 0px;
  background: ${themeGet('colors.overlaybg')};
  border-radius: 0px 0px 10px 10px;
  border-top: 3px solid ${themeGet('colors.bplayerscardborder')};
  ${device.TabToLg} {
    position: sticky;
  }
`;

export const StyledContainerFotter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const StyledFotterHead = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledFotterPole = styled.div`
  display: flex;
  color: ${themeGet('colors.white')};
  margin-right: 15px;
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 14px;
`;
export const StyledFotterSelected = styled.div`
  .winplaces {
    display: flex;
  }
  display: flex;
  width: 100%;
  overflow: auto;
  padding-bottom: 8px;
  .ordinal_text {
    color: ${themeGet('colors.pagefontcolor')};
    font-size: 12px;
    display: flex;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 2px 6px;
    sup {
      font-size: 9px;
      color: #fff;
      top: 3px;
      font-family: ${themeGet('fonts.googleSansBold')};
    }
  }

  ul {
    display: flex;
    //width: 100%;
    li {
      display: flex;
      margin: 0 5px 0px 0;
    }
  }
  a {
    font-size: 12px;
    background: ${themeGet('colors.homebannertxtcolor')};
    color: ${themeGet('colors.pagefontcolor')};
    padding: 0px 6px;
    font-family: ${themeGet('fonts.googleSansBold')};
    display: flex;
    align-items: center;
    height: 18px;
  }
`;

export const StyledBetSlipContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const StyledBetTrash = styled.div`
  display: flex;
`;

export const StyledBetSlipButton = styled.div`
  display: flex;
  white-space: nowrap;

  .betslip_select {
    background: ${themeGet('colors.betamountselectdropdownbg')};
    border: 1px solid ${themeGet('colors.betamountselectdropdownborder')};
    border-radius: 6px;
    color: #fff;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 14px;
    height: 35px;
    padding: 5px 10px;
    text-align: left;
    margin-right: 20px;
    width: 120px;
  }
  .btn-check:checked + .betslip_select,
  :not(.btn-check) + .betslip_select:active,
  .betslip_select:first-child:active,
  .betslip_select.active,
  .betslip_select.show {
    background: ${themeGet('colors.betamountselectdropdownbg')};
    border: 1px solid ${themeGet('colors.betamountselectdropdownborder')};
    border-radius: 6px;
    color: #fff;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
  .betslip_button {
    background: ${themeGet('colors.mainborder')};
    border: none;
    border-radius: 6px;
    color: ${themeGet('colors.pagefontcolor')};
    float: left;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 13px;
    height: 35px;
    width: 140px;
    padding: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-check:checked + .betslip_button,
  :not(.btn-check) + .betslip_button:active,
  .betslip_button:first-child:active,
  .betslip_button.active,
  .betslip_button.show {
    background: ${themeGet('colors.mainborder')};
    border: none;
    border-radius: 6px;
    color: ${themeGet('colors.pagefontcolor')};
    float: left;
    font-family: ${themeGet('fonts.googleSansBold')};
  }
`;

export const StyledContainerToteHeader = styled.div`
  ul {
    //border-left: 1px solid #232935;
    border-radius: 10px 10px 0 0;
    //border-right: 1px solid #232935;
    border-top: 1.5px solid #232935;
    display: flex;
    flex-direction: row;
    list-style: none;
    list-style-type: none;
    min-height: 60px;
    overflow-x: auto;
    max-width: 620px;
    padding-bottom: 10px;
    ${device.TabToLg} {
      border-radius: 0px;
    }
  }
`;

export const StyledContainerTotepools = styled.div`

display:flex;

li{
  display: flex;
  flex-direction: column;
  margin: 7px 0;
  cursor:pointer;
  padding: 6px 24px 0px 15px;
  word-wrap: break-word;
  background-clip: initial;
  background:${themeGet('colors.bodybackground')};
  border-right: 1px solid ${themeGet('colors.tableheaderborder')};
  cursor: pointer;
  &:nth-last-child{border-right:0px}
  }
 
  li.selected::after {
    background: ${themeGet('colors.mainborder')};
    content: "";
    height: 3px;
    position: relative;
    top: 7px;
  }


.clickspan{
  padding: 0px 20px 0px 0px;
  display: flex;
  flex-direction: column;
  ${device.TabToLg}{
    padding: 0px 5px 0px 0px;
  }
}
  .pool_name{ 
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 3px;
    max-width: 100%;
    white-space: nowrap;
    color: #c2c9d6;}
   }
  .pool_place{
    color: #a3adc2;
    font-size: 12px;
    line-height: 13px;
    white-space: nowrap;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }

  .selected{
    .pool_name{ 
      color:${themeGet('colors.mainborder')}
    }
    &:after {
      background:${themeGet('colors.mainborder')};
      content: "";
      height: 3px;
      position: relative;
      top: 7px;
  }
  }
  

`;
export const StyledResultHeader = styled.div`
  border-radius: 6px 6px 0 0;
  border-top: none;
  display: flex;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: ${themeGet('colors.tableheaderbg')};
  padding: 10px 15px;
  .runners {
    width: calc(100% - 120px);
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    text-transform: uppercase;
  }
  .win {
    text-align: right;
    width: 60px;
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    text-transform: uppercase;
  }
  .place {
    text-align: right;
    width: 60px;
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    text-transform: uppercase;
  }
`;

export const StyledContainerRightview = styled.div`
  // height: calc(100vh - 180px);
  // overflow: auto;
  &.betvalid {
    margin-bottom: 100px;
    //transition: all 0.3s;
    scroll-behavior: smooth;
    transition: bottom 0.4s;
    ${device.TabToLg} {
      margin-bottom: 0px;
    }
  }
`;
export const StyledHelpBox = styled.div`
  display: flex;
  padding: 15px 0px;
  flex-direction: column;
  ${device.TabToLg} {
    display: none;
  }
`;

export const StyledHelpHead = styled.div`
  display: flex;
  justify-content: space-between;
  color: #fff;
  h4 {
    color: #a3adc2;
    font-size: 16px;
    font-family: ${themeGet('fonts.googleSansBold')};
  }
  a {
    color: ${themeGet('colors.mainbackground')};
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
`;

export const StyledHelpBody = styled.div`
  display: flex;
  ul {
    display: flex;
    width: 100%;
    gap: 10px;
    padding-top: 15px;
    li {
      display: flex;
      width: calc(100% / 3);

      text-align: center;
      a {
        text-align: center;
        display: flex;
        width: 100%;
        height: 100px;
        border-radius: 10px;
        border-top: 1px solid rgba(111, 111, 111, 0.2);
        border-bottom: 1px solid rgba(111, 111, 111, 0.2);
        background: linear-gradient(
            135deg,
            rgba(175, 175, 175, 0.03) 0%,
            rgba(148, 148, 148, 0.03) 23%,
            rgba(131, 155, 251, 0.1) 100%
          ),
          rgba(255, 255, 255, 0.02);
        color: ${themeGet('colors.white')};
        align-items: center;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        backdrop-filter: blur(12px);
        p {
          width: 100%;
          color: #a3adc2;
          font-size: 14px;
          line-height: 17px;
          font-family: ${themeGet('fonts.googleSansMedium')};
        }
      }
    }
  }
`;
