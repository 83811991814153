import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Select,
  InputGroup,
  ButtonSpinner,
  InputGroupAddon,
} from '@/components';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  StyledWrap,
  StyledTitle,
  StyledPhoneText,
  StyledPersonalContainer,
  StyledContryCode,
  StyledMobileCode,
  StyledMobileView,
  StyledInfoCircle,
  StyledAlertMessage,
  StyledReferalView,
} from './style';

import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';
import {
  ErrorWarningiconSvg,
  EyeHideIconSvg,
  EyeIconSvg,
  InfocircleIconSvg,
} from '@/shared/svg';
import { Alert, Grid } from '@mui/material';

const Register = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [agentId, setAgentId] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [message, setMessage] = useState<any>('');
  const [isNotValid, setIsNotValid] = useState(false);
  const { referalcode } = useParams();
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<ReferalUser>();

  const getReferalCode = async () => {
    setAgentId(referalcode);
  };

  const showPassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };

  const onSubmit: SubmitHandler<ReferalUser> = async data => {
    setLoading(true);
    data.phone = `${data.countryCode}${data.phone}`;
    data.agentId = agentId;
    data.userName = `${data.firstName}${data.lastName}`;
    const response = await AuthService.referalSignup(data);
    if (response.status) {
      setLoading(false);
      reset();
    } else {
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data);
    }
  };

  useEffect(() => {
    getReferalCode();
  }, [referalcode]);

  return (
    <StyledWrap>
      <StyledTitle>Referal Sign Up</StyledTitle>
      <StyledReferalView>
        {/* <Alert severity="error">This is an error alert — check it out!</Alert> */}
        <form
          action='#'
          className='loginform regesterform'
          autoComplete='off'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          {isNotValid ? (
            <StyledAlertMessage>
              <ErrorWarningiconSvg /> {message}
            </StyledAlertMessage>
          ) : null}

          <Grid container direction='column' md={12} className='no_padding'>
            <FormGroup>
              <Label display='block' mb='5px' htmlFor='email'>
                Phone Number<span>*</span>
              </Label>
              <StyledMobileView>
                <StyledContryCode>
                  <InputGroup>
                    <Select
                      id='countryCode'
                      name='countryCode'
                      className='select'
                      {...register('countryCode')}
                    >
                      <option value='+63'>PH(+63)</option>
                      <option value='+91'>IN(+91)</option>
                      <option value='+44'>UK(+44)</option>
                    </Select>
                  </InputGroup>
                </StyledContryCode>
                <StyledMobileCode>
                  <StyledPhoneText>(0)</StyledPhoneText>
                  <Input
                    className='mobilenumber'
                    type='tel'
                    id='phone'
                    name='phone'
                    placeholder='Phone number'
                    feedbackText={errors?.phone?.message}
                    state={hasKey(errors, 'phone') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'phone')}
                    {...register('phone', {
                      required: 'Phone is required',
                      pattern: {
                        value: /^(?!(0))[0-9]{10}$/,
                        message: 'invalid phone format',
                      },
                    })}
                  />
                </StyledMobileCode>
              </StyledMobileView>
            </FormGroup>
          </Grid>
          <Grid container direction='column' md={12} className='no_padding'>
            <FormGroup>
              <Label display='block' mb='5px' htmlFor='email'>
                First Name<span>*</span>{' '}
              </Label>
              <InputGroup>
                <Input
                  id='firstName'
                  type='text'
                  name='firstName'
                  placeholder='Enter First Name'
                  feedbackText={errors?.firstName?.message}
                  state={hasKey(errors, 'firstName') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'firstName')}
                  {...register('firstName', {
                    required: 'First Name is required',
                  })}
                />
              </InputGroup>
            </FormGroup>
          </Grid>
          <Grid container direction='column' md={12} className='no_padding'>
            <FormGroup>
              <Label display='block' mb='5px' htmlFor='email'>
                Last Name<span>*</span>
              </Label>
              <InputGroup>
                <Input
                  id='lastName'
                  type='text'
                  name='lastName'
                  placeholder='Enter Last Name'
                  feedbackText={errors?.lastName?.message}
                  state={hasKey(errors, 'lastName') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'lastName')}
                  {...register('lastName', {
                    required: 'Last Name is required',
                  })}
                />
              </InputGroup>
            </FormGroup>
          </Grid>
          <Grid container direction='column' md={12} className='no_padding'>
            <FormGroup>
              <Label display='block' mb='5px' htmlFor='email'>
                Password<span>*</span>{' '}
              </Label>
              <InputGroup>
                <Input
                  id='password'
                  type={passwordType}
                  name='password'
                  placeholder='Enter your password'
                  feedbackText={errors?.password?.message}
                  state={hasKey(errors, 'password') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'password')}
                  {...register('password', {
                    required: 'Password is required',
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                      message:
                        'Use 8 or more characters-atleast 1 uppercase and 1 lowercase letters, 1 number & 1 symbol(@$!%*?&#)',
                    },
                  })}
                />
                <InputGroupAddon className='posswordicon'>
                  <div
                    className='passwodicon'
                    color='light'
                    onClick={() => showPassword()}
                  >
                    {passwordType === 'password' && <EyeHideIconSvg />}
                    {passwordType === 'text' && <EyeIconSvg />}
                  </div>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Grid>
          <Grid container direction='column' md={12} className='no_padding'>
            <FormGroup>
              <Label
                className='agentcode'
                display='block'
                mb='5px'
                htmlFor='email'
              >
                Agent Code: {agentId}
              </Label>
            </FormGroup>
          </Grid>
          <StyledPersonalContainer>
            <Button type='submit' color='brand2' className='signin_btn'>
              {loading ? <ButtonSpinner /> : null}Create Account
            </Button>
          </StyledPersonalContainer>
        </form>
      </StyledReferalView>
    </StyledWrap>
  );
};

export default Register;
