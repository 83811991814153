import React from 'react';
import Carousel from 'react-multi-carousel';
import promoBanner1M from './../../../../public/images/Promo-Banner-1-mobile.jpg';
import promoBanner1 from './../../../../public/images/Promo-Banner-1.jpg';
import promoBanner2M from './../../../../public/images/Promo-Banner-2-mobile.jpg';
import promoBanner2 from './../../../../public/images/Promo-Banner-2.jpg';
import arrowRight from './../../../../public/icons/arionplay/arrow_right_FILL0_wght400_GRAD0_opsz24.svg';
import arionPlayLogo from './../../../../public/icons/arionplay/Arion Play logo.svg';
import { StyledImageSliderContainer, StyledSliderContainer, StyledSliderDots } from './style';
import { Button } from '@mui/material';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const ImageSlider = () => {
  var carousel : Carousel | null;
  var isMobile = window.screen.width < 1200;
 
  return (
      <>
        <StyledSliderContainer>
          
          <StyledImageSliderContainer className='race'>
            <Button 
            onClick={()=>{console.log('aaaaa');}}
            className='slide-button-1'>
                <h5>Play Now</h5>
                <img src={arrowRight} alt="race" />
            </Button>
            <img className='slide-images' src={isMobile? promoBanner1M : promoBanner1} alt="image 1" />
          
            
            <StyledSliderDots>
                <div>
                  <div className='dot-1'></div>
                  <div className='dot-2 --active'></div>
                </div>
            </StyledSliderDots>
          </StyledImageSliderContainer>    
        
          <StyledImageSliderContainer className='casino'>
            <Button 
            onClick={()=>{console.log('xxxxx');}}
            className='slide-button-2'>
                <h5>Play Now</h5>
                <img src={arrowRight} alt="" />
            </Button>
            <img className='slide-images' src={isMobile? promoBanner2M : promoBanner2} alt="image 2" />

            
            <StyledSliderDots>
                <div>
                  <div className='dot-1 --active'></div>
                  <div className='dot-2'></div>
                </div>
            </StyledSliderDots>

          </StyledImageSliderContainer>
          
          
          <img className='arion-logo' src={arionPlayLogo}/>
        </StyledSliderContainer>
      </>
  );
};

export default ImageSlider;
