import React from "react";
// import { StyledInfoBoxRight } from "../style";
import { StyledInfoBoxRight } from "../../../components/Custom/mainstyle"
import AccordionSection from "../../../components/Custom/AccordionSection";

import PageHead from "../components/PageHead";

const listDetails = [
    { title: "How do I register for an account?", desc: `
    <ul>
    <li><b>Step 1 : </b> Locate the "Sign Up" button situated in the top right corner of the screen.</li>
    <li><b>Step 2 : </b> Fill in your GCash-registered mobile number and birthdate.</li>
    <li><b>Step 3 : </b> Establish a strong and secure password for your account.</li>
    <li><b>Step 4 : </b> Click "Create Account"</li>
    </ul>
    ` },
    { title: "Why is KYC verification needed when I create an account?", desc: `KYC verification is required as part of regulatory compliance. It is also put in place to enhance safety and prevent fraud.<br/><br/>Players must have a KYC-verified account in order to withdraw from their ArionPlay wallet.` },
    { title: "What should I do if I forget my account password?", desc: `
    <ul>
    <li><b>Step 1 : </b>Head to the login screen and click the "Forgot Password" button.</li>
    <li><b>Step 2 : </b>Provide the mobile number associated with your www.arionplay.com account.</li>
    <li><b>Step 3 : </b>Set up a new password and confirm."</li>
    </ul>` },
    
];

const FaqsDetails: React.FC = () => {
    return (
        <StyledInfoBoxRight>
            <PageHead title="Account Enquiries" />
          <AccordionSection list={listDetails} />
        </StyledInfoBoxRight>
    );
};

export default FaqsDetails;
