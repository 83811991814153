import React, { useState, useEffect } from 'react';
import { ProfileNameSvg } from '@/shared/svg';
import { Styledcheckbox } from './style';

const Switch = ({
  isOn,
  handleToggle,
  onColor,
  id,
}: {
  isOn: any;
  handleToggle: any;
  onColor: any;
  id: any;
}) => {
  return (
    <>
      <Styledcheckbox>
        <input
          checked={isOn}
          onChange={handleToggle}
          className='react-switch-checkbox'
          id={`react-switch-new ${id}`}
          type='checkbox'
        />
        <label
          style={{ background: isOn && onColor }}
          className='react-switch-label'
          htmlFor={`react-switch-new ${id}`}
        >
          <span className={`react-switch-button`} />
        </label>
      </Styledcheckbox>
    </>
  );
};

export default Switch;
