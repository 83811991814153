import React from 'react';
// Mui
// import {
//   StyledPageRight,
//   StyledPageTextContainer,
//   StyledPageTextView,
// } from './style';
import { StyledPageRight, StyledPageTextContainer, StyledPageTextViewpage, StyledSupportAvailable, StyledSupportContent, StyledSupportInfo, } from "../../components/Custom/mainstyle"
import PageBanner from '@/components/Custom/PageBanner';
//
const Responscontent = () => {  
return (

  <StyledPageRight>
            <StyledPageTextContainer>
            <PageBanner/>
              <StyledPageTextViewpage>
                <h5 className='weare_passio'>We care for you…!!!</h5>
                <p>
                  Playing at {process.env.REACT_APP_SITE_TITLE} has never been more exciting, Log-in and
                  play as the heart pounds and the experience turns profound
                  from the comforts of your home. Nevertheless one needs to be
                  conscious of the risks involved in losses and the consequences
                  of excessive wagering. The excitement is often overwhelming
                  and hence jurisprudence will keep you safe and excited. One of
                  the most important facets of responsible punter is to know -
                  when to STOP. {process.env.REACT_APP_SITE_TITLE} helps you recognise your problem by
                  tracking your activity and giving you red flags, should the
                  need arise, so you can Play safe and Stay safe.Protection of
                  minors
                </p>
                <p>
                  {process.env.REACT_APP_SITE_TITLE} doesn t allow anyone under the age of 21 to open an
                  account or play. To ensure this, we reserve the right to
                  verify your identity to confirm the age.What is Responsible
                  Gaming?
                </p>
                <p>Here are a few tips for playing responsibly:</p>
                <ul>
                  <li>
                    Set a personal budget and stick to it without giving into
                    temptations.
                  </li>
                  <li>
                    Play for the fun of it, not to generate revenue or for
                    sustenance.
                  </li>
                  <li>Wager only when in a clear state of mind.</li>
                  <li>Make sure you take regular breaks.</li>
                  <li>
                    If punting lets your negative emotions take over the fun, it
                    is time to take a break.
                  </li>
                  <li>
                    Ensure your gambling doesn t impact your social life or
                    work.
                  </li>
                </ul>
                <p>
                  Should the need be, please don t hesitate to reach out to us
                  at{' '}
                  <a
                    href='mailto:{process.env.REACT_APP_SUPPORT_EMAIL}'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {process.env.REACT_APP_SUPPORT_EMAIL}
                  </a>{' '}
                  and we can help you stay in control.
                </p>
              </StyledPageTextViewpage>
            </StyledPageTextContainer>
          </StyledPageRight>
          
);

};

export default Responscontent;

