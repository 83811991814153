import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Mui
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import ButtonGroup from '@mui/material/ButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Divider from '@mui/material/Divider';

import { RaceCardBetType } from '../api/RaceCardApi';
import { useAppSelector } from '@/hooks/useAppSelector';
// Icon
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
// Components
import Page from '@/components/Page';
// import BorderCard from '@/components/BorderCard';
// SVG
import { EvolutionGamesSvg, RealTimeGamesSvg, WEGamesSvg } from '@/shared/svg';
import { StyledBetButton } from './style';

// utils
import { MathFloor } from '../utils/formatNumberFloor';

// TODO move this to MUI-Theme
const themeColor =
  process.env.REACT_APP_THEME === 'fairplay' ? '#2F594D' : '#384766';

// This must be fix on a global state when header is added.
const ScreenDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: 0,
  padding: '0px 0px 8px 0px',

  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

  [theme.breakpoints.up('md')]: {
    marginTop: 80,
  },
  marginTop: 0,
}));

const BetButton = styled(ButtonBase)(({ theme }) => ({
  display: 'block',
  position: 'relative',
  width: '100%',
  height: '100%',
  borderRadius: 5,
  padding: '10px 8px 6px 8px',
  [theme.breakpoints.up('md')]: {
    padding: '1rem',
  },
  border: `1px solid ${theme.palette.action.disabledBackground}`,
}));

const OddWrapperDiv = styled('div')(({ theme }) => ({
  borderRadius: 18,
  border: '1.5px solid #FFFFFFCB',
  padding: 2,
  backgroundColor: '#00000021',
  minWidth: '4rem',
}));

const OddsDiv = styled('div')(({ theme }) => ({
  borderRadius: 16,
  border: '1px solid #FFFFFF78',
  padding: '0px 16px',
  background: 'rgba(0, 0, 0, 0.20)',
  textShadow: 'none',
  fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
  fontSize: '1.1rem',
  fontWeight: '500',
  lineHeight: '1.5',
}));

const StyledDivider = styled(Typography)(({ theme }) => ({
  backgroundColor: 'white',
  width: '100%',
}));

const ParticipantsName = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem ',
  fontWeight: '600',
  fontFamily: '"Roboto", sans-serif, Helvetica, Arial',

  '@media (max-width:500px)': {
    fontSize: '10px !important',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100px',
  },
}));

const betCardTransparencyFactor = 'C8';
const redGradient = ['BE1C31', 'EC3F56'];
const blueGradient = ['228817', '42B935'];
const greenGradient = ['031DF8', '3046FF'];

export const BET_ODD = 'ODD';
export const BET_EVEN = 'EVEN';

type Props = {
  type: RaceCardBetType;
  odds: null | undefined | number;
  betAmountTotal: null | undefined | number;
  betAmount: null | undefined | number;
  onClick: (type: RaceCardBetType) => void;
  disabled?: boolean;
  participants?: any;
};

const HorseBetButton = ({
  participants,
  type,
  odds,
  betAmountTotal,
  betAmount,
  onClick,
  disabled,
}: Props) => {
  const { module } = useParams();
  const { userInfo } = useAppSelector(state => state.auth);
  let gradient;
  let label;
  let backgroundImage;
  let backgroundImage1;
  let backgroundColor;
  let backgroundColor1;
  switch (type) {
    case BET_ODD:
      gradient = redGradient;
      label = 'X';
      backgroundImage = 'url("/icons/xo-logo-bg.png")';
      backgroundImage1 = 'url("/icons/X-Icon.svg")';
      backgroundColor = '#957c52';
      backgroundColor1 = `linear-gradient(90deg, #BE1C31 0%, #EC3F56 22.4%, #EC3F56 77.08%, #BE1C31 100%)`;
      break;
    case BET_EVEN:
      gradient = greenGradient;
      label = 'O';
      backgroundImage = 'url("/icons/ox-logo-bg.png")';
      backgroundImage1 = 'url("/icons/O-Icon.svg")';
      backgroundColor = '#726969';
      backgroundColor1 = `linear-gradient(90deg, #031DF8 0%, #3046FF 22.4%, #3046FF 77.08%, #031DF8 100%)`;
      break;
    default:
      gradient = ['#000', '#FFF'];
  }
  //background: `linear-gradient(90deg, #${gradient[0]}${betCardTransparencyFactor} 0%, #${gradient[1]}${betCardTransparencyFactor} 22.4%, #${gradient[1]}${betCardTransparencyFactor} 77.08%, #${gradient[0]}${betCardTransparencyFactor} 100%)`,

  return (
    /* <BetButton    className={`${module} ${label}`} */
    <StyledBetButton>
      <BetButton
        className={`tnt ${label}`}
        //style= {module == 'horseracing' ? {backgroundImage: backgroundImage,backgroundColor: backgroundColor,backgroundSize: '100% 100%'} : {backgroundImage: backgroundImage1,background: `linear-gradient(90deg, #${gradient[0]}${betCardTransparencyFactor} 0%, #${gradient[1]}${betCardTransparencyFactor} 22.4%, #${gradient[1]}${betCardTransparencyFactor} 77.08%, #${gradient[0]}${betCardTransparencyFactor} 100%)` }}
        //style= {module == 'horseracing' ? {backgroundImage: backgroundImage,backgroundColor: backgroundColor,backgroundSize: '100% 100%'} : {backgroundImage: backgroundImage1,background: backgroundColor1,backgroundSize: '100% 100%'}}
        onClick={() => onClick(type)}
        disabled={disabled}
      >
        <Stack
          direction='column'
          spacing={0}
          alignItems='center'
          justifyContent='center'
          style={{ marginBottom: '0.5rem' }}
        >
          <Typography
            variant='h5'
            sx={{
              fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
              fontSize: '1.5rem',
              lineHeight: '1.5rem',
              fontWeight: '500',
            }}
            className='xotext'
          >
            {label}
          </Typography>
          <Typography
            variant='caption'
            sx={{
              fontSize: '0.875rem !important',
              fontWeight: '600',
              fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
            }}
          >
            {module === 'bk' && type === 'ODD' ? '(B)' : ''}
            {module === 'bk' && type === 'EVEN' ? '(K)' : ''}
            {module !== 'bk' && `(${type})`}
          </Typography>
          {module !== 'racing' && (
            <ParticipantsName variant='caption' sx={{}}>
              {participants}
            </ParticipantsName>
          )}
        </Stack>
        <Stack
          direction='column'
          spacing={1}
          alignItems='center'
          className='betamounttext'
        >
          <OddWrapperDiv>
            <OddsDiv>{MathFloor(odds) || '-'}</OddsDiv>
          </OddWrapperDiv>
          <Box sx={{ width: '100%', marginTop: '.7rem !important' }}>
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              justifyContent='space-between'
              width='100%'
            >
              <Typography
                variant='caption'
                component='h4'
                sx={{
                  fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                  fontSize: '.7rem',
                  fontWeight: '500',
                }}
              >
                Bet
              </Typography>
              <Typography
                variant='caption'
                component='h6'
                sx={{
                  fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                  fontSize: '.875rem',
                  fontWeight: '600',
                }}
              >
                {disabled ? 'Disabled' : betAmount || '-'}
              </Typography>
            </Stack>
            <Divider
              sx={{
                borderColor: 'rgba(255, 255, 255, 0.5)',
                backgroundColor: 'inherit',
              }}
            />
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              justifyContent='space-between'
              flexWrap='wrap'
              width='100%'
              margin={0}
            >
              <Typography
                variant='caption'
                component='h4'
                sx={{
                  fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                  fontSize: '.7rem',
                  fontWeight: '500',
                }}
              >
                Est.Payout
              </Typography>
              <Typography
                variant='caption'
                component='h6'
                sx={{
                  fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                  fontSize: '.875rem',
                  fontWeight: '600',
                }}
              >
                {!disabled && betAmount && odds
                  ? Number(Number(betAmount * odds).toFixed(2))
                  : '-'}
              </Typography>
            </Stack>
            {userInfo && userInfo.roles == 'gameoperator' && (
              <Stack
                direction='row'
                spacing={1}
                alignItems='center'
                justifyContent='space-between'
                flexWrap='wrap'
                width='100%'
                margin={0}
              >
                <Typography
                  variant='caption'
                  component='h4'
                  sx={{
                    fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                    fontSize: '.7rem',
                    fontWeight: '500',
                  }}
                >
                  Total Amount
                </Typography>
                <Typography
                  variant='caption'
                  component='h6'
                  sx={{
                    fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                    fontSize: '.875rem',
                    fontWeight: '600',
                  }}
                >
                  {!disabled && betAmount && odds && betAmountTotal
                    ? betAmountTotal.toFixed(2)
                    : '-'}
                </Typography>
              </Stack>
            )}
          </Box>
        </Stack>
      </BetButton>
    </StyledBetButton>
  );
};

export default HorseBetButton;
