import React from 'react';

import { StyledWrap, StyledTitle, StyledDesc } from './style';
import { Button } from '@/components';
import { TickCircleSvg } from '@/shared/svg';

interface ChildProps {
  changeSteps: any;
  closePopup(arg0: string): void;
}

const KYCSuccess = (props: ChildProps) => {
  return (
    <StyledWrap>
      <TickCircleSvg />
      <StyledTitle>KYC Verification in Progress</StyledTitle>

      <StyledDesc>
        Thank you for submitting. KYC verification typically takes 24-48
        hours. We&apos;ll notify you once it&apos;s complete
      </StyledDesc>
      <Button
        type='submit'
        color='brand2'
        className='signin_btn'
        onClick={() => props.closePopup('close')}
      >
        Ok
      </Button>
    </StyledWrap>
  );
};

export default KYCSuccess;
