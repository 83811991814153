import React, { useEffect, useState } from 'react';
import Dropdown from '@/components/ui/dropdown/dropdown';
import DropdownToggle from '@/components/ui/dropdown/dropdown-toggle';
import DropdownMenu from '@/components/ui/dropdown/dropdown-menu';
import { useAppSelector } from '@/hooks/useAppSelector';
import CookieStorageService from '@/services/CookieStorageService';
import { changeModule } from '@/redux/slices/module';
import AuthService from '@/services/AuthService';
import { MyWalletSvg, RefrashIconSvg } from '@/shared/svg';
import { Link } from 'react-router-dom';
import { Button, Radio } from '@/components';
import { DepositSvg } from '@/shared/svg';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  setSelectedUserWallet,
  setUserWalletList,
} from '@/redux/slices/wallet';
import { StyledWalletList } from './style';

const WalletDropDown = () => {
  const { userInfo } = useAppSelector(state => state.auth);
  const { selectedUserWallet, userWalletList } = useAppSelector(
    state => state.wallet
  );
  const [userName, setUserName] = useState('');
  const [walletBal, setWalletBal] = useState(0);
  const [walletId, setWalletId] = useState('');
  const [walletCurrency, setWalletCurrency] = useState('');
  const [walletRecords, setWalletRecords] = useState<Array<IWallet>>([]);
  const dispatch = useAppDispatch();
  //console.log(selectedUserWallet);
  const getUserInfo = async () => {
    const token = await CookieStorageService.getItem(AuthService.getAuthKey());
    if (token != undefined) {
      //const userData = await AuthService.getUserData();
      if (userInfo.phoneVerified) {
        if (
          userInfo.firstName !== undefined
          // && userInfo.lastName !== undefined
        )
          // setUserName(`${userInfo.firstName} ${userInfo.lastName}`);
          setUserName(userInfo.firstName);
        else setUserName(`${userInfo.username}`);
        if (userInfo.selectedWalletId !== '') {
          setWalletId(userInfo.selectedWalletId);
        }
        getUserWalletInfo(userInfo);
      }
    }
  };

  const getUserWalletInfo = async (data: any) => {
    const response = await AuthService.getMyWallets();
    if (response.status && response.data.length > 0) {
      dispatch(setUserWalletList(response.data));
      populateWalletList(response.data);
    }
  };

  const populateWalletList = (data: any) => {
    let filteredData = [];
    if (userInfo.selectedWalletId !== '') {
      filteredData = data.filter(
        (item: any) => item.id === userInfo.selectedWalletId
      );
    } else {
      filteredData = data.filter((item: any) => item.type === 'DEMO');
    }
    setWalletRecords(data);

    if (filteredData.length > 0) {
      setWalletBal(filteredData[0].balance);
      setWalletCurrency(filteredData[0].currency);
      setWalletId(filteredData[0].id);
      dispatch(setSelectedUserWallet(filteredData[0]));
    } else {
      setWalletBal(data[0].balance);
      setWalletCurrency(data[0].currency);
      setWalletId(data[0].id);
      dispatch(setSelectedUserWallet(data[0]));
    }
  };

  const getSelectedWalletInfo = async (data: any) => {
    const response = await AuthService.getMyWallets();
    if (response.status && response.data.length > 0) {
      const filteredData = response.data.filter(
        (item: any) => item.id === data.id
      );
      if (filteredData.length > 0) {
        dispatch(setSelectedUserWallet(filteredData[0]));
        setWalletBal(filteredData[0].balance);
        setWalletId(filteredData[0].id);
        setWalletCurrency(filteredData[0].currency);

        //Wallet Id Update Start
        const res = { selectedWalletId: data.id };
        const response = await AuthService.updateUserProfile(res);
        //Wallet Id Update End
      }
    }
  };

  const showKYCPopup = async () => {
    const userData = await AuthService.getUserData();
    if (userData.status) {
      const { kycVerified } = userData.data;
      if (kycVerified === 'VERIFIED') {
        dispatch(changeModule('withdraw'));
      } else {
        if (kycVerified === 'PENDING') {
          dispatch(changeModule('kycwithdrawprocess'));
        } else {
          dispatch(changeModule('kyc'));
        }
      }
    }
  };

  const changeOption = () => {
    console.log('options');
  };

  useEffect(() => {
    const token = CookieStorageService.getItem(AuthService.getAuthKey());
    if (token && userInfo) {
      getUserInfo();
    }
  }, [userInfo]);

  useEffect(() => {
    if (userWalletList) {
      populateWalletList(userWalletList);
    }
  }, [userWalletList]);

  return (
    <>
      {/* <Link to=''>
        <RefrashIconSvg />
      </Link> */}
      <Dropdown>
        <DropdownToggle className='depost_text'>
          <div className='walletamountview'>
            <p className='username_text'>{userName}</p>
            <p className='username_wallet'>
              <span
                style={{
                  fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                  fontWeight: '400',
                }}
              >
                &#8369;
              </span>
              <span>&nbsp;{walletBal}</span>
              {/* {walletCurrency} */}
            </p>
          </div>
        </DropdownToggle>
        <DropdownMenu>
          <StyledWalletList>
            {walletRecords.map((item: any, index: number) => {
              return (
                <Link
                  to={'#'}
                  key={index}
                  className={`${item.id === walletId ? 'active' : ''}`}
                  onClick={() => getSelectedWalletInfo(item)}
                >
                  <div className='radioview'>
                    <Radio
                      id={item.id}
                      name='wallet'
                      value={item.balance}
                      checked={item.id === walletId}
                      label={''}
                      onChange={() => changeOption()}
                    />
                    {item.name}
                  </div>
                  <p>
                    {' '}
                    {item.balance} ({item.currency})
                  </p>
                </Link>
              );
            })}
          </StyledWalletList>

          {
            <div className='withdrawview'>
              <Button onClick={() => showKYCPopup()} className='loginwithdraw'>
                <DepositSvg />
                Withdraw{' '}
              </Button>
            </div>
          }
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default WalletDropDown;
