import React, { useEffect, useRef, useState } from 'react';
// Mui
import {
  StyledPageRight,
  StyledPageTextContainer,
} from '../../components/Custom/mainstyle';
import {
  AlertWarningiconSvg,
  ErrorWarningiconSvg,
  KycVerifiedIconSvg,
  SendSvg,
} from '@/shared/svg';

import { hasKey } from '@/shared/methods';
import Stack from '@mui/material/Stack';

import {
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Textarea,
  Select,
} from '@/components';
import { Grid } from '@mui/material';
import AuthService from '@/services/AuthService';
import { useForm, SubmitHandler } from 'react-hook-form';
import { StyledAlertMessage, StyledAlertMessageSucess } from '../deposit/style';
import TextImage from './TextImage'; // Import the TextImage component
import { paddingLeft } from 'styled-system';
import ReCAPTCHA from 'react-google-recaptcha';
const generateRandomCaptcha = () => {
  return Math.floor(1000 + Math.random() * 9000).toString(); // Generates a number between 1000 and 9999
};

const Contactcontent = () => {
  const [sourceSubject, setsourceSubject] = useState('');
  const [message, setMessage] = useState<any>('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [isCaptchaDone, setIsCaptchaDone] = useState(false);
  const [captchaMessage, setCaptchaMessage] = useState('');
  // Set the initial captchaText state to a random 4-digit number

  const [userCaptcha, setUserCaptcha] = useState('');
  const recaptchaRef = useRef(null);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ContactForm>();

  const onSubmit: SubmitHandler<ContactForm> = async data => {
    setCaptchaMessage('');
    if (!isCaptchaDone) {
      setCaptchaMessage('Please verify captcha');
      return;
    }
    data.agent = process.env.REACT_APP_SITE_TITLE;
    setLoading(true);
    console.log(data, 'data');
    const contactData = { ...data, userCaptcha };
    const response = await AuthService.contactForm(contactData);

    if (response.status) {
      recaptchaRef.current.reset();
      setCaptchaMessage('');
      setUserCaptcha('');
      setIsCaptchaDone(false);
      setIsNotValid(false);
      setLoading(false);
      setSuccess(true);
      const msg = () => (
        <span>Contact form has been submitted successfully </span>
      );
      setMessage(msg);
      // reset();
      setValue('subject', '');
      setValue('message', '');
    } else {
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data);
      recaptchaRef.current.reset();
      setCaptchaMessage('');
      setUserCaptcha('');
      setIsCaptchaDone(false);
    }
  };

  const handleDocumentTypeValue = (e: any) => {
    setsourceSubject(e.target.value);
  };

  const getUserDetails = async () => {
    const response = await AuthService.getUserData();
    setValue('firstName', response.data.firstName);
    setValue('lastName', response.data.lastName);
    setValue('phone', response.data.phone);
    setValue('email', response.data.email);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  // Function to handle the generated CAPTCHA image
  const handleCaptcha = (token: string) => {
    setIsCaptchaDone(true);
    setUserCaptcha(token);
    setCaptchaMessage('');
  };

  return (
    <StyledPageRight>
      <StyledPageTextContainer>
        <h5>How can we help?</h5>
        <form
          className='contactform loginform'
          action='#'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          {success ? (
            <StyledAlertMessageSucess>
              <KycVerifiedIconSvg /> {message}
            </StyledAlertMessageSucess>
          ) : null}
          {isNotValid ? (
            <StyledAlertMessage>
              <ErrorWarningiconSvg /> {message}
            </StyledAlertMessage>
          ) : null}

          {/* Form Fields */}
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{ paddingBottom: '20px' }}
          >
            <Grid item xs={6} className='no_padding'>
              <Label display='block' mb='5px' htmlFor='firstName'>
                First Name
              </Label>
              <InputGroup>
                <Input
                  className='mobilenumber'
                  type='text'
                  id='firstName'
                  name='firstName'
                  placeholder='First Name'
                  feedbackText={errors?.firstName?.message}
                  state={hasKey(errors, 'firstName') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'firstName')}
                  {...register('firstName', {
                    required: 'First Name is required',
                  })}
                />
              </InputGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='lastName'>
                  Last Name
                </Label>
                <InputGroup>
                  <Input
                    className='mobilenumber'
                    type='text'
                    id='lastName'
                    name='lastName'
                    placeholder='Last Name'
                    feedbackText={errors?.lastName?.message}
                    state={hasKey(errors, 'lastName') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'lastName')}
                    {...register('lastName', {
                      required: 'Last Name is required',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{ paddingBottom: '20px' }}
          >
            <Grid item xs={6} className='no_padding'>
              <Label display='block' mb='5px' htmlFor='email'>
                Phone
              </Label>
              <InputGroup>
                <Input
                  className='mobilenumber'
                  type='text'
                  readonly={false}
                  id='phone'
                  name='phone'
                  placeholder='Phone Number'
                  feedbackText={errors?.phone?.message}
                  state={hasKey(errors, 'phone') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'phone')}
                  {...register('phone', {
                    required: 'Phone is required',
                  })}
                />
              </InputGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Email
                </Label>
                <InputGroup>
                  <Input
                    className='mobilenumber'
                    type='text'
                    readonly={false}
                    id='email'
                    name='email'
                    feedbackText={errors?.email?.message}
                    state={hasKey(errors, 'email') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'email')}
                    {...register('email', {
                      required: 'Email is required',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{ paddingBottom: '20px' }}
          >
            <Grid item xs={12}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Subject
                </Label>
                <InputGroup>
                  <Select
                    id='subject'
                    name='subject'
                    className={`select ${
                      sourceSubject === '' ? 'placeholder' : ''
                    }`}
                    feedbackText={errors?.subject?.message}
                    state={hasKey(errors, 'subject') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'subject')}
                    {...register('subject', {
                      required: 'Subject is required',
                    })}
                    onChange={e => handleDocumentTypeValue(e)}
                  >
                    <option disabled={true} value='' selected>
                      --Select Subject Type--
                    </option>
                    <option value='withdrawls'>Withdrawals </option>
                    <option value='deposit'>Deposit </option>
                    <option value='kyc'>KYC </option>
                    <option value='accountinfo'>Account info </option>
                    <option value='others'>Others </option>
                    <option value='feedback'>Suggestions / Feedback </option>
                  </Select>
                </InputGroup>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Label display='block' mb='5px' htmlFor='email'>
                Message
              </Label>
              <InputGroup className='textareamessage'>
                <Textarea
                  id='message'
                  name='Message'
                  feedbackText={errors?.message?.message}
                  state={hasKey(errors, 'message') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'message')}
                  {...register('message', {
                    required: 'Message is required',
                    minLength: {
                      value: 6,
                      message: 'Minimum length is 6',
                    },
                  })}
                ></Textarea>
              </InputGroup>
            </Grid>
          </Grid>
          {/* Other form fields... */}

          {/* CAPTCHA Section */}
          <Grid
            container
            rowSpacing={2}
            sx={{ marginTop: '30px', marginLeft: '0px', position: 'relative' }}
            style={{ paddingBottom: '20px' }}
          >
            <ReCAPTCHA
              sitekey='6Lc_CWQqAAAAAHbsSmSBA9j-8p09voH1rLhXaxDl'
              ref={recaptchaRef}
              onChange={handleCaptcha}
            />
            <Stack
              sx={{
                position: 'absolute',
                bottom: '-5px',
                color: '#ff7c54',
                fontSize: '11px',
              }}
            >
              {captchaMessage}
            </Stack>
          </Grid>

          <Button type='submit' color='brand2' className='send-btn'>
            <SendSvg />
            Send Message
          </Button>
        </form>
      </StyledPageTextContainer>
    </StyledPageRight>
  );
};

export default Contactcontent;
