import styled, { device, themeGet } from '@/shared/styled';

export const StyledWrap = styled.div`
&.signinview{
  width:420px;
  ${device.MobToSL}{
    width:300px;
  }
}
  width: 100%;
  .no_padding {
    padding-right: 0px;
  }
  .signin_btn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    gap: 4px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    width: 280px;
    margin-top: 20px;
    height: 42px;
    border: none;
  }
  .btn-check:checked + .signin_btn.btn,
  :not(.btn-check) + .btn:active,
  .signin_btn.btn:first-child:active,
  .signin_btn.btn.active,
  .signin_btn.btn.show {
    background: ${themeGet('colors.mainborder')};
  }
  .row {
    margin-bottom: 16px;
  }
  .mobilenumber {
    padding-left: 52px;
  }
  .posswordicon {
    position: absolute;
    height: 42px;
    right: 0px;
    top: 0px;
    width: 30px;
    display: flex;
    justify-content: center;
    z-index: 99;
    button {
      padding: 0px;
      width: 30px;
      &:active {
        box-shadow: none;
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        border: none;
      }
      &:focus {
        box-shadow: none;
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        border: none;
      }
    }
    svg {
      path {
        fill: #6a7a9b;
      }
    }
  }
  .infopassword {
    margin-left: 6px;
    position: relative;
    top: 2px;
  }
  .passwodicon {
    width: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: #ffffff;
    // transition: background-color 5000s ease-in-out 0s;
    // box-shadow: inset 0 0 20px 20px #23232329;
    // background-clip: text !important;
    // background: ${themeGet('colors.forminputbg')} !important;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;
    background-color:rgba(0, 0, 0, 0) !important;
    background-color:rgb(0, 0, 0,) !important;
    box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
  }
  input:-internal-autofill-selected {
    background-clip: padding-box !important;
    background-color: #000 !important;
    border: 1px solid #1c2335;
    border-radius: 6px 6px 6px 6px !important;
    //box-shadow:none !important;
    background: ${themeGet('colors.forminputbg')} ;
    background-clip: padding-box !important;
    // -webkit-background-clip: text !important ;
    // -webkit-text-fill-color: transparent !important;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-internal-autofill-selected:focus{
    background-color: ${themeGet('colors.forminputbg')} !important;
    //box-shadow:none !important;
    background-clip: padding-box !important;
    //background-clip: text !important;
    background: ${themeGet('colors.forminputbg')} !important;
    background-color:rgba(0, 0, 0, 0) !important;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background-clip: padding-box !important;
    // background-clip: text !important;
    // -webkit-text-fill-color: rgb(255, 255, 255);
    // box-shadow: none !important;
    // transition: inherit !important;
    // background-color: ${themeGet('colors.forminputbg')} !important;
    // -webkit-background-clip: text !important ;
    // -webkit-text-fill-color: transparent !important;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;
    background-color:rgba(0, 0, 0, 0) !important;
    background-color:rgb(0, 0, 0,) !important;
    box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
}
.form-control {
  // display: block;
  // width: 100%;
  // padding: $input-padding-y $input-padding-x;
  // font-family: $input-font-family;
  // @include font-size($input-font-size);
  // font-weight: $input-font-weight;
  // line-height: $input-line-height;
  // color: $input-color;
  background-color: ${themeGet('colors.forminputbg')} !important;;
  //background-clip: padding-box;
  box-shadow: none !important;
  //border: 1px solid ${themeGet('colors.largegamecardborder')}!important ;
  appearance: none; // Fix appearance for date inputs in Safari
  // background-color:rgba(0, 0, 0, 0) !important;
  // background-color:rgb(0, 0, 0,) !important;

}
.input-group input:autofill:hover, .input-group input:autofill:focus, .input-group input:autofill:active {
  background-clip: padding-box !important;
  //box-shadow: none !important;
  transition: inherit !important;
  background-color: ${themeGet('colors.forminputbg')} !important;
  background: ${themeGet('colors.forminputbg')} ;
  -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;

}
input:-internal-autofill-selected{}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 1px solid green;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
  transition: background-color 5000s ease-in-out 0s;
  background-color:rgba(0, 0, 0, 0) !important;
  background-color:rgb(0, 0, 0,) !important;
  box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
}
  input:-webkit-autofill,
    input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
  }
  form {
    display: flex;
    width: 100%;
    flex-direction: column;
    // input {
    //   padding-left: 12px;
    // }
    // select {
    //   padding-left: 12px;
    // }
  }
  ${device.MobToMd} {
    form{
      width: 90vw;
    }
    .row {
      margin-bottom: 0px;
      .col-md-12 {
        margin-bottom: 10px;
      }
    }
    .no_padding {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      margin-bottom: 16px;
    }
    .mobilecode {
      width: 125px;
      padding-right: 0px !important;
    }
    .mobilephone {
      width: calc(100% - 125px);
    }
  }
`;

export const StyledTitle = styled.h3`
  color: #c2c9d6;
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 6px;
  text-align: center;
  ${device.MobToMd} {
    width: 90vw;
  }
`;

export const StyledDesc = styled.p`
  font-size: 16px;
  color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  font-family: ${themeGet('fonts.googleSansRegular')};
  margin-bottom: 30px;
  text-align: center;
  ${device.MobToMd} {
    width: 90vw;
  }
`;

export const StyledAlertMessage = styled.div`
  // color: #f31415;
  // background-color: #f8d7da;
  // border-color: #f5c6cb;
  // position: relative;
  // padding: 5px 10px;
  // margin-bottom: 1rem;
  // border: 1px solid transparent;
  // border-radius: 0.25rem;
  // display: flex;
  // align-items: center;
  // gap: 4px;
  border-radius: 8px;
  border: 1px solid #F79009;
  background: rgba(255, 255, 255, 0.05);
  gap: 8px;
  padding: 8px;
  color:${themeGet('colors.eventnumberdefaulttxtcolor')};
  font-size:13px;
  font-family: ${themeGet('fonts.googleSansMedium')};
  margin-bottom: 14px;
  display: flex;
  align-items: center;
`;

export const StyledLabelWrap = styled.div`
  margin-bottom: 5px;
  justify-content: space-between;
  display: flex;
`;

export const StyledDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 15px 0;
  color: #c2c9d6;
  &:after,
  &:before {
    content: '';
    display: block;
    flex: 1;
    height: 2px;
    background-color: ${themeGet('colors.tableheaderborder')};
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;

export const StyledBottomText = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 15px;
  color: #c2c9d6;
  font-family: ${themeGet('fonts.googleSansRegular')};
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 24px;
  margin-top: 30px;
  margin-left: -40px;
  margin-right: -40px;
  ${device.MobToMd}{
    margin-left: -20px;
    margin-right: -20px;
  }
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
  }
`;

export const StyledForgotText = styled.div`
  text-align: center;
  padding: 15px 0px 0px;
  a {
    background: none;
    color: ${themeGet('colors.mainborder')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 15px;
  }
  ${device.MobToMd} {
    margin-top: 10px;
  }
`;
export const StyledPhoneText = styled.div`
  position: absolute;
  left: 31px;
  top: 11px;
  color: #f0f0f0;
  font-size: 13px;
  z-index: 99;
`;

export const StyledPersonalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

/*  ================    ============== */

export const StyledSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledSubHeaderText = styled.div`
  p {
    font-size: 15px;
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    margin-bottom: 0px;
  }
`;

export const StyledSubHeadLogo = styled.div``;

export const StyledSteps = styled.div`
  p {
    color: #7685a0;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 15px;
    margin: 0px 0px 5px 0px !important;
    display: flex;
    align-items: center;
    gap: 4px;
    svg {
      position: relative;
      top: -2px;
      width: 8px;
      path {
        fill: ${themeGet('colors.ErrorLableText')};
      }
    }
  }
  h4 {
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    font-size: 24px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    margin: 30px 0px 25px 0px;
    display: flex;
    align-items: center;
    span {
      color: ${themeGet('colors.mainborder')};
      font-family: ${themeGet('fonts.googleSansBold')};
    }
  }
  ${device.TabToLg} {
    p {
      //margin-bottom: 15px !important;
    }
  }
`;

export const StyledGcashText = styled.div`
  p {
    color:${themeGet('colors.mainborder')};
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 15px;
    margin: 0px 0px 25px 0px !important;
    display: flex;
    align-items: center;
    gap: 4px;
    svg {
      position: relative;
      top: -2px;
      width: 8px;
      path {
        fill: ${themeGet('colors.ErrorLableText')};
      }
    }
  }
  h4 {
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    font-size: 24px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    margin: 30px 0px 25px 0px;
    display: flex;
    align-items: center;
    span {
      color: ${themeGet('colors.mainborder')};
      font-family: ${themeGet('fonts.googleSansBold')};
    }
  }
  ${device.TabToLg} {
    p {
      margin-bottom: 15px !important;
      font-size: 13px;
      line-height: 16px
    }
  }
`;












export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 0px;
  border-top: 1px solid;
  padding-top: 20px;
  margin-top: 5px;
  gap: 20px;

  .loginbtn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 13px;
    border: 0px;
    gap: 4px;
    height: 34px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    &:hover {
      background: ${themeGet('colors.mainborder')};
      font-size: 13px;
      border: 0px;
      height: 34px;
    }
    svg path {
      stroke: ${themeGet('colors.pagefontcolor')} !important;
    }
  }
  .signupbtn {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 34px;
    font-size: 13px;
    // font-family: ${themeGet('fonts.googleSansRegular')};
    &:hover {
      background: none;
      border: 1px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
      height: 34px;
      font-size: 13px;
    }
    &:active {
      background: none;
      border: 1px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
`;


export const StyledCheckedText = styled.div`
  display: flex;
  p {
    color: #a4adc0;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    a {
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
`;

export const StyledContryCode = styled.div`
  width: 120px;
  display: flex;
  margin-right: 10px;

  .input-group-text {
    padding: 0.475rem 0.175rem;
  }
  select {
    padding-left: 12px !important;
  }
`;

export const StyledMobileCode = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  // input {
  //   padding-left: 26px !important;
  // }
`;

export const StyledMobileView = styled.div`
  display: flex;
`;

export const StyledInfoCircle = styled.div`
  display: block;
  position: absolute;
  min-width: 380px;
  word-break: break-all;
  top: -99px;
  left: -75px;
  z-index: 999;
  background: #ff0;
  height: 90px;
  font-size: 13px;
  line-height: 17px;
  padding: 10px 14px;
  border-radius: 10px;
  background: #4a5a76;
  color: ${themeGet('colors.white')};
  font-family: ${themeGet('fonts.googleSansRegular')};
  &::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 8px solid #4a5a76;
    border-right: 8px solid transparent;
    border-bottom: 0;
    border-left: 8px solid transparent;
    color: #fff;
    position: absolute;
    bottom: -8px;
    left: 74px;
  }
`;
