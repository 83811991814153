import React from 'react';
import { useNavigate } from 'react-router-dom';
// Mui
import Container from '@mui/material/Container';
//

import { useAppDispatch } from '@/hooks/useAppDispatch';
import Page from '@/components/Page';
import HeaderTitle from '@/components/Custom/HeaderTitle';
import WidgetIndex from '@/components/Custom/widgets';
import { Grid } from '@mui/material';
import Responscontent from './responscontent';

const leftSideWidgets = [{ comp: 'Contact', desc: `At ${process.env.REACT_APP_SITE_TITLE}, we want our customers to enjoy gaming in a safe and responsible environment.` }, { comp: 'Support' },];
const Responsiblegaming = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <Page title="Responsible Gaming" favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      <Container maxWidth={'lg'} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <HeaderTitle title="Responsible Gaming" />
        <Grid container spacing={3} style={{}}>
          <Grid item style={{ width: "366px" }}>
            <WidgetIndex componentsList={leftSideWidgets} />
          </Grid>
          <Grid item xs style={{ position: 'relative' }}>
            <Responscontent />
          </Grid>
        </Grid>

      </Container>
    </Page>
  );
};

export default Responsiblegaming;
