import React, { useState, useEffect } from 'react';
// Lib
import moment from 'moment/moment';
// Mui
import Container from '@mui/material/Container';
//
import AuthService from '@/services/AuthService';
import Page from '@/components/Page';
import MUIDataTable from 'mui-datatables';
import {StyledDatatable} from '@/pages/pagestyles';
import HeaderTitle from '@/components/Custom/HeaderTitle';

const columns = [{ name: 'action', label: 'Action' }, { name: 'description', label: 'Description' }, { name: 'ip', label: 'IP Address' }, {
  name: 'updatedAt', label: 'Time', options: {
    customBodyRender: (value:string) => moment(value).format(
      'DD/MM/YYYY HH:mm:ss'
    )
  }
}];

const Myactivity = () => {
  const [myactivityData, setMyactivityData] = useState(null);

  const getMyactivityData = async () => {
    const response = await AuthService.getMyactivityData();
    setMyactivityData(response.data);
  };

  useEffect(() => {
    getMyactivityData();
  }, []);
  return (
    <Page title="My Activity" favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      <Container maxWidth={'lg'}>
          <HeaderTitle title="MyActivity" />
          <StyledDatatable className='myactivitytable'>
            {myactivityData && <MUIDataTable
              title={"Displaying your latest account activity."}
              data={myactivityData}
              columns={columns}
              options={{
                filterType: 'checkbox',
                print: false,
                confirmFilters: false,
                filter : false,
                search: false,
                viewColumns: false,
                selectableRows: 'none',
                download: false,
              }}
            />}
          </StyledDatatable>
      </Container>
    </Page>
  );
};

export default Myactivity;
