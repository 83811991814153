import React, { useEffect, useState } from 'react';
import classnames from 'clsx';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  Label,
  Input,
  Button,
  InputGroup,
  ButtonSpinner,
} from '@/components';
import { SendSvg, KycVerifiedIconSvg, AlertWarningiconSvg } from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledContainerPageView,
  StyledPageTextContainer,
  StyledViewMessage,
  StyledSucessMessage,
  StyledErrorMessage,
  StyledClose,
} from './style';
import { CloseIconSvg } from '@/shared/svg';
import { useForm, SubmitHandler } from 'react-hook-form';
import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';
import { Link, redirect } from 'react-router-dom';

const Verifyotp = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<VerifyOtpForm>();
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displayErrorMsg, setDisplayErrorMsg] = useState('');

  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displaySuccessMsg, setDisplaySuccessMsg] = useState('');

  const onSubmit: SubmitHandler<VerifyOtpForm> = async data => {
    setLoading(true);
    const emailVerify = await AuthService.verifyEmailOtp(data);
    if(emailVerify.status){
      setDisplaySuccess(true);
      setDisplaySuccessMsg(emailVerify.data);
      setLoading(false);      
    } else{
      setDisplayError(true);
      setDisplayErrorMsg(emailVerify.data);
      setLoading(false);      
    }
  };

  const closePopup = async () => {
    setDisplayError(false);
    setDisplaySuccess(false);
    setLoading(false);     
    redirect('/'); 
  };

  const verifyEmailOtp = async () => {

    const queryParameters = new URLSearchParams(window.location.search)
    const otp = queryParameters.get("otp")
    console.log(otp,'otp');
    if(otp != undefined && otp != ''){
      var data ={emailOtp:otp}
      const emailVerify = await AuthService.verifyEmailOtp(data);
      if(emailVerify.status){
        setDisplaySuccess(true);
        setDisplaySuccessMsg(emailVerify.data);
        setLoading(false);      
      } else{
        setDisplayError(true);
        setDisplayErrorMsg(emailVerify.data);
        setLoading(false);      
      }  
    }
  }

  useEffect(() => {
    verifyEmailOtp();
  }, []);



  return (
    <>
      <StyledContainerFluid>
        <h3>Email Verify</h3>
        <StyledContainerPageView>
          <StyledPageTextContainer>
            {/* <form
              className='loginform contactform'
              action='#'
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <Row mb='20px'>
                <Col md={4} className='no_padding'>
                  <Label display='block' mb='5px' htmlFor='email'>
                    Enter OTP
                  </Label>
                  <InputGroup>
                    <Input
                      className='mobilenumber'
                      type='text'
                      id='emailOtp'
                      name='emailOtp'
                      placeholder='Enter OTP'
                      feedbackText={errors?.emailOtp?.message}
                      state={hasKey(errors, 'emailOtp') ? 'error' : 'success'}
                      showState={!!hasKey(errors, 'emailOtp')}
                      {...register('emailOtp', {
                        required: 'OTP is required',
                        pattern: {
                          value: /^[0-9]{5}$/,
                          message: 'invalid OTP format',
                        },
                        maxLength: {
                          value: 6,
                          message: 'Minimum length is 6',
                        },
                      })}
                    />
                  </InputGroup>
                </Col>
              </Row>

              <Button
                type='submit'
                color='brand2'
                className='send-btn'
                data-testid='submit'
              >
                <SendSvg />
                {loading ? <ButtonSpinner /> : null}Enter
              </Button>
            </form> */}
            
              {displayError && (<StyledViewMessage>
                <StyledErrorMessage>
                <Link to='/' className='closeicon'><CloseIconSvg /></Link>
                <AlertWarningiconSvg />
                <h4>{displayErrorMsg}</h4>
              </StyledErrorMessage></StyledViewMessage>) }
              
              {displaySuccess && (<StyledViewMessage>
                <StyledSucessMessage>
                <Link to='/' className='closeicon'><CloseIconSvg /></Link>
                <KycVerifiedIconSvg />
                <h4>{displaySuccessMsg}</h4>
              </StyledSucessMessage></StyledViewMessage>)}
          </StyledPageTextContainer>
        </StyledContainerPageView>
      </StyledContainerFluid>
    </>
  );
};

export default Verifyotp;
