import axios from 'axios';
import { EnqueueSnackbar } from 'notistack';
import { getHeaderInfo } from '@/helpers/token';
import UserModel from '../@types/models/UserModel';

/*
 * Get Game Category Events.
 */
export const apiGetCurrentUser = async (
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | UserModel> => {
  const header = await getHeaderInfo();
  try {
    const result = await axios.get(`${process.env.REACT_APP_AUTHENTICATION_API_URL}/user`, { ...header });
    if (result.status === 200) {
      // TODO Just in case - add user to a context always when called?
      return result.data as UserModel;
    } else {
      console.error(`Not a 200 OK, but ${result.status}`);
    }
  } catch (error) {
    console.error(error);
    if (errorEnqueueSnackbar) {
      errorEnqueueSnackbar(`${error.message}: ${error.response?.data?.error || 'n/a'}`, {
        variant: 'error',
        autoHideDuration: 1000,
      });
    }
  }
  return null;
};
