export const fairplay = {
  topheaderbackground: '#08231C',
  SideMenuBorder:'#31405A',
  headerbackground: '#0C0C0C',
  bodybackground: '#0C0C0C',
  mainbackground: '#F3722C',
  mainborder: '#FDE246',
  white: '#ffffff',
  pagefontcolor: '#08241C',
  overlaybg: '#224F3F',
  btnfontcolor: '#DAAE0B',
  largegamecardbackground:
    'linear-gradient(180deg, rgba(36, 80, 67, 0.69) 0%, rgba(17, 40, 34, 0.69) 100%)',
  largegamecardborder: '#2F594D',
  livevideobtnbg: 'linear-gradient(180deg, #0D392C 0%, #051D16 100%)',
  homebannertxtcolor: '#FDE246',
  forminputbg: '#0f2b21',
  filterbuttonbg: '#265B4E',
  pagecontainerbg: '#17211E',
  pagecontainerborder: '#25332F',
  pagecontainerinput: '#23302C',
  pagecontainerinputborder: '#33433E',
  pageinfotxtcolor: '#1E2926',
  pageinfoborder: '#33433F',
  walletdropdownbg: '#08231C',
  tableheaderbg: '#1A2421',
  tableheaderborder: '#293834',
  profilepagebuttonbg: '#439276',
  profilepagebuttonborder: '#224F3F',
  closebtncolor: '#558d79',
  forminputfocusborder: '#acc6bd',
  eventnumberselectedbg: '#CEB93C',
  eventnumberselectedborder: '#FDE246',
  eventnumberdefaultbg: '#22433A',
  eventnumberdefaultborder: '#3A675B',
  eventnumberdefaulttxtcolor: '#C2C9D6',
  runnerbtnbg: '#22433A',
  runnerbtnborder: '#3A675B',
  playerscardbg: '#224F3F',
  bplayerscardborder: '#356F5A',
  betamountselectdropdownbg: '#397761',
  betamountselectdropdownborder: '#50997F',
  checkboxborder: '#FCFCFC',
  copyrighttextcolor: '#b5b5b5',
  InputLableText: '#A2C1B8',
  ErrorLableText: '#ff7c54',
  ActionInfoButtonborder: '#FF975F',
  GameTextcolor: '#aec1bb',
  OverLaySubGames:'rgba(2, 28, 20, 0.9);',
};

export const arionplay = {
  topheaderbackground: '#1A2436',
  SideMenuBorder:'#31405A',
  headerbackground: '#121212',
  bodybackground: '#121212',
  mainbackground: '#F3722C',
  mainborder: '#DD631A',
  white: '#ffffff',
  yellow: '#ffe500',
  pagefontcolor: '#ffffff',
  overlaybg: '#26334d',
  btnfontcolor: '#dc6426',
  largegamecardbackground:
    'linear-gradient(180deg, #26334D 0%, rgba(25, 33, 50, 0.60) 100%);',
  largegamecardborder: '#384766',
  livevideobtnbg: 'linear-gradient(180deg, #182740 0%, #02173A 100%)',
  homebannertxtcolor: '#F3722C',
  forminputbg: '#1c2335',
  filterbuttonbg: '#265B4E',
  pagecontainerbg: '#13181e',
  pagecontainerborder: '#2f3847',
  pagecontainerinput: '#1b1f25',
  pagecontainerinputborder: '#2a2d32',
  pageinfotxtcolor: '#1b1f25',
  pageinfoborder: '#2F3847',
  walletdropdownbg: '#08231C',
  tableheaderbg: '#1b1f25',
  tableheaderborder: '#1c2335',
  profilepagebuttonbg: '#439276',
  profilepagebuttonborder: '#224F3F',
  closebtncolor: '#4a6285',
  forminputfocusborder: '#b8c8d9',
  eventnumberselectedbg: '#f3722c',
  eventnumberselectedborder: '#dd631a',
  eventnumberdefaultbg: '#121212',
  eventnumberdefaultborder: '#2f3847',
  eventnumberdefaulttxtcolor: '#C2C9D6',
  runnerbtnbg: '#202b40',
  runnerbtnborder: '#3a4a68',
  playerscardbg: '#224F3F',
  bplayerscardborder: '#425375',
  betamountselectdropdownbg: '#4a5f88',
  betamountselectdropdownborder: '#6279a6',
  checkboxborder: '#FCFCFC',
  copyrighttextcolor: '#b5b5b5',
  InputLableText: '#A4ADC0',
  ErrorLableText: '#ff7c54',
  ActionInfoButtonborder: '#FF975F',
  GameTextcolor: '#A3ADC2',
  OverLaySubGames:'rgba(21, 35, 63, 0.9)',
};
